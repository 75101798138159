import { Injectable } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';

import { WebsocketApiService } from '../../../../../services/websockets/websocket-api.service';
import { WS } from '../../../../../services/websockets/websocket.events';
import { Attribute, DataTree } from './design-doc-interfaces';
import { ItemContext, FolderType, FileType } from '../../../../../common/interfaces';
import { DataModel } from 'src/app/models/data.model';
import { REGEX_START_WITH_NUMBER } from '../../../../../common/constants';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: Attribute | ItemContext,
    public level = 1,
    public expandable = false,
    public isLoading = false
  ) {}
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable()
export class DynamicDatabase {
  rootLevelNodes: (FolderType | Attribute | FileType)[] = [];
  arrayChildren1: (FolderType | Attribute | FileType)[] = [];

  destroySubj: Subject<void> = new Subject();

  constructor(private wsService: WebsocketApiService) {}

  setRootData(startAttributeId: string) {
    this.arrayChildren1 = [];
    const messageId = String(+new Date());
    this.wsService.send(WS.SEND.getAttributes, { rootAttributeID: startAttributeId }, messageId);
    return this.wsService.on<any>(messageId, null).pipe(
      tap((val) => {
        this.rootLevelNodes = val.attributes || this.sortFilesByDateAndName(val);
      })
    );
  }

  sortFilesByDateAndName(initialData: DataTree): FileType[] {
    const initialArray = [];
    const sortedArray = [];

    if (initialData.hasOwnProperty('files') && initialData.files.length) {
      initialData.files.forEach((file: FileType) => {
        file.objType = 'file';
        initialArray.push(file);
      });

      initialArray.sort((previousFile, nextFile) =>
        (nextFile as FileType).timestamp.created - (previousFile as FileType).timestamp.created);

      const oneDateArrays = [[initialArray[0]]];

      initialArray.reduce((previousFile, currentFile) => {
        const previousFileDate = new Date((previousFile as FileType).timestamp.created / 1000000).getDate();
        const currentFileDate = new Date((currentFile as FileType).timestamp.created / 1000000).getDate();

        previousFileDate === currentFileDate ?
          oneDateArrays[oneDateArrays.length - 1].push(currentFile) : oneDateArrays.push([currentFile]);

        return currentFile;
      });

      oneDateArrays.forEach(oneDateFiles => {
        const startWithNumber = [];
        const startWithoutNumber = [];

        oneDateFiles.forEach((file) => {
          REGEX_START_WITH_NUMBER.test(file.name) ? startWithNumber.push(file) : startWithoutNumber.push(file);
        });

        startWithNumber.sort((a, b) => {
          const titleA = a.name.match(REGEX_START_WITH_NUMBER);
          const titleB = b.name.match(/^\d+/i);

          return +titleA[0] - +titleB[0];
        });

        startWithoutNumber.sort((a, b) => {
          const titleA = a.name.toLowerCase();
          const titleB = b.name.toLowerCase();

          if (titleA < titleB) { return -1; }
          if (titleA > titleB) { return 1; }

          return 0;
        });

        sortedArray.push(...startWithNumber, ...startWithoutNumber);
      });
    }

    return sortedArray || [];
  }

  /** Initial data from database */
  initialData(): DynamicFlatNode[] {
    return this.rootLevelNodes.map((value: Attribute) => new DynamicFlatNode(value, 0, this.isExpandable(value)));
  }

  getChildren(node: Attribute | ItemContext): Observable<DataTree> {
    const messageId = String(+new Date());
    // TODO: проверить
    node.hasOwnProperty('objType') && (node as ItemContext).objType === 'directory'
      ? this.wsService.send(WS.SEND.getDirectory, { id: node.id }, messageId)
      : this.wsService.send(WS.SEND.getAttributes, { rootAttributeID: node.id }, messageId);

    return this.wsService
      .on<any>(messageId, null);
  }

  public filter(filterText: string): DynamicFlatNode[] {
    let filteredTreeData;
    if (!!filterText) {
      const dataTree = this.initialData();
      filteredTreeData = dataTree.filter(
        (d: DynamicFlatNode) => d.item.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) > -1
      );
      Object.assign([], filteredTreeData).forEach((ftd) => {
        let str = ftd.item.name as string;
        while (str.lastIndexOf('.') > -1) {
          const index = str.lastIndexOf('.');
          str = str.substring(0, index);
          if (filteredTreeData.findIndex((t) => t.item.name === str) === -1) {
            const obj = dataTree.find((d: DynamicFlatNode) => d.item.name === str);
            if (!!obj) {
              filteredTreeData.push(obj);
            }
          }
        }
      });
    } else {
      filteredTreeData = this.initialData();
    }

    return filteredTreeData;
  }

  isExpandable(node: any): boolean {
    return node.hasChild;
  }
}
/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
@Injectable()
export class DynamicDataSource {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  destroySubj: Subject<void> = new Subject();

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private treeControl: FlatTreeControl<DynamicFlatNode>, private database: DynamicDatabase) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe((change) => {
      if ((change as SelectionChange<DynamicFlatNode>).added || (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach((node) => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach((node) => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node, expand: boolean) {
    let children = [];

    this.database.getChildren(node.item)
      .pipe(takeUntil(this.destroySubj))
      .subscribe((result: DataTree) => {
        result = new DataModel(result);
        if (!!result.attributes && !node.hasOwnProperty('objType') && (node as ItemContext).objType !== 'directory') {
          result.attributes.forEach((res: Attribute) => {
            children.push(res);
          });
        }

        if (result.hasOwnProperty('files') && result.files.length) {
          children = this.database.sortFilesByDateAndName(result);
        }

        if (result.hasOwnProperty('directories')) {
          result.directories.forEach((res) => {
            res.objType = 'directory';
            children.push(res);
          });
        }
      });

    // const children = expand ? this.database.getChildren(node.item) : [];
    const index = this.data.indexOf(node);

    if (!children || index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    node.isLoading = true;

    setTimeout(() => {
      if (expand) {
        const nodes = children.map(
          (name: Attribute) => new DynamicFlatNode(name, node.level + 1, this.database.isExpandable(name))
        );
        this.data.splice(index + 1, 0, ...nodes);
      } else {
        let count = 0;
        for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
        this.data.splice(index + 1, count);
      }

      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }, 500);
  }

  loadNode(node: Attribute | ItemContext, parent: DynamicFlatNode | string, loadType?: string) {
    if (typeof parent === 'string') {
      this.data.push(new DynamicFlatNode(node, 0, false));

      this.dataChange.next(this.data);
    } else {
      const index = this.data.indexOf(parent);

      if (index < 0) {
        // If no children, or cannot find the node, no op
        return;
      }

      parent.item.hasChild = true;
      parent.expandable = true;

      if (this.treeControl.isExpanded(parent) && !loadType) {
        this.data.splice(index + 1, 0, new DynamicFlatNode(node, parent.level + 1, false));
        this.dataChange.next(this.data);
      } else {
        this.treeControl.expand(parent);
      }
    }
  }

  deletedNode(node: DynamicFlatNode, parent: DynamicFlatNode | '') {
    const index = this.data.indexOf(node);

    if (index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    let count = 0;
    for (let i = index + 1; i < this.data.length && this.data[i].level > node.level; i++, count++) {}
    this.data.splice(index, count + 1);

    if (!!parent) {
      const indexPar = this.data.indexOf(parent);
      let countPar = 0;
      for (let i = indexPar + 1; i < this.data.length && this.data[i].level > parent.level; i++, countPar++) {}
      if (!countPar) {
        this.treeControl.expand(parent);
        parent.item.hasChild = false;
        parent.expandable = false;
      }
    }

    this.dataChange.next(this.data);
  }
}
