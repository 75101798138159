/**
 * ON - сообщения, которые принимаются от сервера
 * SEND - сообщения, которые отправляются с клиента
 * COMMON - сообщения, которые используются для отправки и принятия сообщений
 */

export const WS = {
  ON: {
    createArchive: '!atA',
    deleteFromArchive: '!rfA',
    addToClipboard: '!atC',
    deleteFromClipboard: '!rfC',
    deleteDirectory: '!rfD',
    addToTrash: '!atT',
    recoveryObject: '!rfT',
    addToDirectory: '!atD',
    addToShared: '!atS',
    removeFromShared: '!rfS',
    addTag: '!aT',
    updateFromShared: '!ufS',
    updateFromMyDocuments: '!ufD',
    updateFromTrash: '!ufT',
    updateFromClipboard: '!ufC',
    updateFromArchives: '!ufA',
    addToFavorites: '!atF',
    updateFromFavorites: '!ufF',
    removeFromFavorites: '!rfF',
    addToAttribute: '!atAttr',
    addAttribute: '!aAttr',
    updateFromAttributes: '!ufAttr',
    updateAttributes: '!uAttr',
    removeFromAttributes: '!rfAttr',
    removeAttributes: '!rAttr'
  },
  SEND: {
    subscription: 'sub',
    getDirectory: 'gD',
    getArchives: 'gfA',
    removeArchive: 'removeArchive',
    getClipboard: 'gfC',
    createArchiveClipboard: 'cAfC',
    removeAllClipboard: 'rafC',
    trash: 'gfT',
    recovery: 'rfT',
    createClipboard: 'atC',
    removeClipboard: 'rfC',
    remove: 'rfD',
    share: 'share',
    user: 'user',
    createDirectory: 'aD',
    getMarks: 'gMrk',
    getTitles: 'gTtl',
    getShared: 'gfS',
    exit: 'exit',
    getTags: 'gUTs',
    addTags: 'aTs',
    setTag: 'sT',
    unsetTag: 'unT',
    setFavorite: 'sF',
    unsetFavorite: 'unF',
    getAllFavorites: 'gF',
    getAttributeTree: 'gAt',
    getAttributes: 'gA',
    getDocumentTypes: 'gdT',
    setAttributeRequest: 'sA',
    copyToMD: 'copy',
    search: 'search',
    getAllTypesOfAttributes: 'gATps',
    getTypeAttributeList: 'gAfTps',
    resetPassword: 'rP',
    changeOrganization: 'cO',
    setVersion: 'sV',
    createArchive: 'cAr',
    deleteFilesDirectories: 'rFIDS',
    removeVersion: 'rV',
    addHistory: 'aH',
    getHistory: 'gH',
    removeAllFromHistory: 'rAfH'
  },
  COMMON: {
    users: 'users',
    getTree: 'gTfMD',
    removeTag: 'rT',
    updateTag: 'uT',
  },
};
