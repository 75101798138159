<div class="container">
  <div class="for_users">
    <span>Открыть доступ для пользователей:</span>
  </div>
  <!-- <lib-wb-select
    [multiple]="true"
    [options]="userList"
    optionKey="id"
    optionLabel="fullName"
    [(selectedOptions)]="usersSelect">
  </lib-wb-select> -->
  <app-share-form (shareList)="getShareList($event)"></app-share-form>
  <div class="messenger">
    <div class="email">
      <span class="text">Отправить уведомление на email</span>
      <mat-slide-toggle class="margin-right" [checked]="checkedEmail" (change)="messenger($event, 'email')">
      </mat-slide-toggle>
      <!-- [disabled]="true" -->
    </div>
    <div class="viber">
      <span class="text">Отправить уведомление на viber</span>
      <mat-slide-toggle class="margin-right" [disabled]="true" [checked]="checkedViber" (change)="messenger($event, 'viber')">
      </mat-slide-toggle>
    </div>
  </div>
  <div class="button">
    <app-button (click)="close()" backgroundColor="white">Закрыть</app-button>
    <app-button
      className="btn-share"
      (click)="shareDoc()"
      [disabled]="
        !checkedEmail ||
        !shareArray ||
        (!shareArray?.forView.length && !shareArray?.forCheck.length && !shareArray?.forApproval.length && !shareArray?.emails.length) ||
        !language
      "
    >
      Поделиться
    </app-button>
  </div>
</div>
