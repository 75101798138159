import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-name-exist-document',
  templateUrl: './name-exist-document.component.html',
  styleUrls: ['./name-exist-document.component.scss']
})
export class NameExistDocumentComponent {

  constructor(private dialogRef: MatDialogRef<NameExistDocumentComponent>) { }

  accept() { }

  close() { }

}
