<div class="layout">
    <mat-icon class="icon"
              [svgIcon]="'appSvg:tile-view'"
              [ngClass]="{ active: activeContainer === 'tile' }"
              (click)="activeContainerStyle('tile')">
    </mat-icon>
    <mat-icon class="icon table-view"
              id="table-view"
              [svgIcon]="'appSvg:table-view'"
              [ngClass]="{ active: activeContainer === 'table' }"
              (click)="activeContainerStyle('table')">
    </mat-icon>
    <mat-icon class="icon"
              [svgIcon]="'appSvg:double-view'"
              [ngClass]="{ active: myDocFlag }"
              (click)="openMenu('MyDoc')"
              *ngIf="sidebarButton">
    </mat-icon>
</div>
