import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SortingCriteria } from '../../../common/interfaces';

@Component({
  selector: 'app-sort-menu',
  templateUrl: './sort-menu.component.html',
  styleUrls: ['./sort-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SortMenuComponent {

  @Input() sortingCriteria: SortingCriteria[];

  @Output() changeSortingCriteria: EventEmitter<string> = new EventEmitter<string>();

  sortBy(event: Event, value: string): void {
    event.preventDefault();
    event.stopPropagation();

    this.changeSortingCriteria.emit(value);
  }

}
