import { Component, Input } from '@angular/core';
import { Attribute } from '../../../../components/design-documentation/components/tree-design-doc/helpers/design-doc-interfaces';

@Component({
  selector: 'app-attributes-tooltip',
  templateUrl: './attributes-tooltip.component.html',
  styleUrls: ['./attributes-tooltip.component.scss'],
})
export class AttributesTooltipComponent {
  @Input() attributes: Attribute[] = [];
}
