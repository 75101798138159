import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { WebsocketApiService } from '../../../services/websockets/websocket-api.service';
import { WS } from '../../../services/websockets/websocket.events';
import { ContentData } from '../../../common/interfaces';

@Injectable({
  providedIn: 'root'
})
export class RecentFilesService {
  constructor(private wsService: WebsocketApiService) {}

  addToRecentFiles(data: {type: string, id: string}): void {
    this.wsService.send(WS.SEND.addHistory, data, String(+new Date()));
  }

  getRecentData(): Observable<ContentData> {
    const messageID = String(+new Date());

    this.wsService.send(WS.SEND.getHistory, '', messageID);

    return this.wsService.on(messageID, null);
  }

  removeAllRecentData(): void {
    this.wsService.send(WS.SEND.removeAllFromHistory, '',  String(+new Date()));
  }

}
