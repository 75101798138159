import {ChangeDetectorRef, Injector, Type} from '@angular/core';

export abstract class CommonHelper {
  cdRef: ChangeDetectorRef;

  protected constructor(injector: Injector) {
    this.cdRef = injector
      .get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
  }

  formatSize(size: number): string {
    if (!size) {
      return '0 kB';
    }

    const i = Math.floor(Math.log(size) / Math.log(1024));

    return +(size / Math
      .pow(1024, i))
      .toFixed(2) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }
}
