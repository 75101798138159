<div class="user_menu_container">
  <mat-icon id="close" [svgIcon]="'appSvg:close'" alt="" (click)="closeUserMenu()"></mat-icon>
  <div class="avatar">
    <p>{{ initials }}</p>
  </div>
  <div class="cart">
    <p class="name">{{ userData?.firstname }}</p>
    <p class="sure_name">{{ userData?.lastname }}</p>
    <p class="nikname">{{ userData?.email }}</p>
    <a class="lang_ru" [ngClass]="{ lang: true }">Русский</a>
    <a class="lang_en" [ngClass]="{ lang: false }">English</a>
    <button class="instruction user-menu-btn" (click)="openOnboarding()">
      <mat-icon id="menu-btn-icon">help_outline</mat-icon>
      Инструкция
    </button>
    <button class="exit user-menu-btn" (click)="logout()">
      <mat-icon id="menu-btn-icon" [svgIcon]="'appSvg:exit'" alt=""></mat-icon>
      Выход
    </button>
  </div>
</div>
