<div class="container">
  <div role="tabpanel" class="noselect_all">
    <div id="WQC" class="label" [routerLink]="['/documents/root']">
      <mat-icon id="logo" [svgIcon]="'appSvg:logo-white'"></mat-icon>
    </div>
    <ul class="wrapper_heading overflow" #scrollContainer>
      <li class="heading" *ngFor="let heading of headings" [routerLinkActive]="['active']">
        <a [routerLink]="heading.route" (click)="openTab(heading.route)">
          {{ heading.title }}
        </a>
      </li>
    </ul>

    <div class="scroll" [ngClass]="{ shadow: slideRightButton }">
      <button
        [disabled]="!slideLeftButton || !isScrollViewCheck"
        class="scroll-button"
        (mousedown)="mousedown('left')"
        (mouseup)="mouseup()"
      >
        <mat-icon class="icon icon-left" [svgIcon]="'appSvg:scroll'"></mat-icon>
      </button>
      <button
        [disabled]="!slideRightButton || !isScrollViewCheck"
        class="scroll-button"
        (mousedown)="mousedown('right')"
        (mouseup)="mouseup()"
      >
        <mat-icon id="file" class="icon icon-right" [svgIcon]="'appSvg:scroll'"></mat-icon>
      </button>
    </div>

    <div class="search" style="position: relative">
      <button (click)="openSearch()" class="search-btn-header">
        <mat-icon [svgIcon]="'appSvg:search'"></mat-icon>
        <span>{{ btnName }}</span>
      </button>
      <!-- <mat-icon [svgIcon]="'appSvg:search'"></mat-icon>
      <input class="search-input" type="text" placeholder="Поиск по файлам" /> -->
      <app-search *ngIf="isSearchView"
                  [searchString]="btnName"
                  (getSearchString)="getSearchString($event)"
                  class="search-area">
      </app-search>
    </div>
    <div *ngIf="isSearchView" class="close-search-area" (click)="closeSearch()"></div>
    <div class="notification-wrapper" (click)="openNotification()">
      <div class="notification">
        <div class="count">12</div>
        <mat-icon [svgIcon]="'appSvg:notification'"></mat-icon>
      </div>
    </div>
    <div class="user-menu">
      <div class="initials" (click)="openUserMenu()">
        {{ initials }}
      </div>
    </div>
    <!-- <div class="user-menu">
      <button class="object check">
        <img src="../../../../assets/imgs/agpz-check.png" />
      </button>
      <button class="object">
        <img src="../../../../assets/imgs/nipigaz.png" />
      </button>
      <div class="initials" (click)="openUserMenu()">
        {{ initials }}
      </div>
    </div> -->
  </div>
<!--  <app-doc-menu class="directory" *ngIf="initDocMenu"></app-doc-menu>-->
</div>
