import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'uniqueFilter'
})
export class UniquePipe implements PipeTransform {

  transform(value: any[], ...args: any[]): any {
    if (value) {
      const types = value.map(x => x.type.name);
      const uniqTypes = _.uniq(types);
      return uniqTypes.map(x => value.find(y => y.type.name === x));
    }
  }

}
