<div class="search-select" [ngClass]="selectHeight">
  <mat-form-field #formField class="form-field" [floatLabel]="floatLabel">
    <mat-select [formControl]="fc" placeholder="{{ placeholder }}" (focus)="onTouched()" [multiple]="isMultiple"
      #singleSelect (selectionChange)="selectionChange($event.value)">
      <mat-option class="option-search">
        <mat-icon>search</mat-icon>
        <ngx-mat-select-search [formControl]="optionFilterCtrl" class="select-search"> </ngx-mat-select-search>
      </mat-option>

      <mat-option *ngIf="isCheckboxSelectAll" disabled="disabled" class="filter-option checkbox-height">
        <mat-checkbox [disableRipple]="true" [checked]="checkboxChecked" (change)="selectAll($event.checked)">
          Выбрать всё
        </mat-checkbox> 
      </mat-option>

        <div [ngClass]="{'scroll-wrap-with-checkbox': isCheckboxSelectAll, 'scroll-wrap': !isCheckboxSelectAll}" class="overflow">
          <mat-option (click)="onSelectOption($event, option)" *ngFor="let option of filteredOptions | async"
            [value]="option.id">
            {{ !!fieldToDisplayName ? option[fieldToDisplayName] : option }}
          </mat-option>
        </div>
    </mat-select>
  </mat-form-field>
  <div *ngIf="!isGenderLabel" class="search-select-label" (click)="onOpen()"></div>
  <!-- <app-button *ngIf="isGenderLabel" [className]="'search-select-gender-label'" [radius]="'round'" [hover]="'blue'"
    [backgroundColor]="'gray'" [size]="'medium'" [border]="false" [type]="'button'" (clickEvent)="onOpen()">
    <span class="icon"></span>
  </app-button> -->
</div>
