import { Component, Injectable, OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';
import { CollectionViewer, SelectionChange } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Router } from '@angular/router';
import { BehaviorSubject, merge, Observable, Subject } from 'rxjs';
import { map, tap, takeUntil } from 'rxjs/operators';

import { WebsocketApiService } from '../../../services/websockets/websocket-api.service';
import { WS } from '../../../services/websockets/websocket.events';
import { ConnectionService } from '../../../services/connection.service';
import { ChooseFolder } from '../../../models/tree.model';

/** Flat node with expandable and level information */
export class DynamicFlatNode {
  constructor(
    public item: DirectoriesTree,
    public level = 1,
    public expandable = false,
    public isLoading = false
  ) {}
}

export interface DataTree {
  rootDirectoryID: string;
  shortDirectories: DirectoriesTree[];
}

export interface DirectoriesTree {
  id: string;
  name: string;
  hasChild: boolean;
}

/**
 * Database for dynamic data. When expanding a node in the tree, the data source will need to fetch
 * the descendants data from the database.
 */
@Injectable()
export class DynamicDatabase {
  rootLevelNodes: DirectoriesTree[] = [];
  arrayChildren1: [{}] = [{}];
  arrayChildren2: [{}] = [{}];

  destroySubj: Subject<void> = new Subject();

  constructor(private wsService: WebsocketApiService) {}

  setRootData() {
    this.arrayChildren2 = [{}];
    this.arrayChildren1 = [{}];
    const messageId = '3';
    this.wsService
      .send(WS.SEND.subscription, { action: '+', type: 'dt' }, messageId);
    this.wsService.send(WS.COMMON.getTree, { id: '' }, messageId);
    return this.wsService.on<any>(messageId, null).pipe(
      tap((val: DataTree) => {
        this.rootLevelNodes = val.shortDirectories || [];
      })
    );
  }

  /** Initial data from database */
  initialData(): DynamicFlatNode[] {
    return this.rootLevelNodes
      .map((value: DirectoriesTree) =>
        new DynamicFlatNode(value, 0, this.isExpandable(value)));
  }

  getChildren(node: DirectoriesTree): [{}] | undefined {
    this.arrayChildren2 = [{}];
    this.arrayChildren1 = [{}];
    const messageId = String(+new Date());
    this.wsService
      .send(WS.SEND.subscription, { action: '+', type: 'dt' }, messageId);
    this.wsService.send(WS.COMMON.getTree, { id: node.id }, messageId);
    this.wsService
      .on<any>(messageId, null)
      .pipe(takeUntil(this.destroySubj))
      .subscribe((result: DataTree) => {
        if (result.shortDirectories) {
          result.shortDirectories.forEach((res: DirectoriesTree) => {
            this.arrayChildren1.push(res);
          });
        }
      });

    this.arrayChildren1.shift();
    return this.arrayChildren1;
  }

  isExpandable(node: any): boolean {
    return node.hasChild;
  }
}
/**
 * File database, it can build a tree structured Json object from string.
 * Each node in Json object represents a file or a directory. For a file, it has filename and type.
 * For a directory, it has filename and children (a list of files or directories).
 * The input will be a json object string, and the output is a list of `FileNode` with nested
 * structure.
 */
@Injectable()
export class DynamicDataSource {
  dataChange = new BehaviorSubject<DynamicFlatNode[]>([]);

  get data(): DynamicFlatNode[] {
    return this.dataChange.value;
  }
  set data(value: DynamicFlatNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(
    private treeControl: FlatTreeControl<DynamicFlatNode>,
    private database: DynamicDatabase
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<DynamicFlatNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if ((change as SelectionChange<DynamicFlatNode>).added ||
        (change as SelectionChange<DynamicFlatNode>).removed) {
        this.handleTreeControl(change as SelectionChange<DynamicFlatNode>);
      }
    });

    return merge(collectionViewer.viewChange, this.dataChange)
      .pipe(map(() => this.data));
  }

  /** Handle expand/collapse behaviors */
  handleTreeControl(change: SelectionChange<DynamicFlatNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed
        .slice()
        .reverse()
        .forEach(node => this.toggleNode(node, false));
    }
  }

  /**
   * Toggle the node, remove from display list
   */
  toggleNode(node: DynamicFlatNode, expand: boolean) {
    const children = this.database.getChildren(node.item);
    const index = this.data.indexOf(node);

    if (!children || index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    node.isLoading = true;

    setTimeout(() => {
      if (expand) {
        const nodes = children
          .map((name: DirectoriesTree) =>
            new DynamicFlatNode(
              name, node.level + 1, this.database.isExpandable(name))
          );
        this.data.splice(index + 1, 0, ...nodes);
      } else {
        let count = 0;
        for (
          let i = index + 1;
          i < this.data.length && this.data[i].level > node.level;
          i++, count++
        ) {}
        this.data.splice(index + 1, count);
      }

      // notify the change
      this.dataChange.next(this.data);
      node.isLoading = false;
    }, 500);
  }

  loadNode(node: DirectoriesTree, parent: DynamicFlatNode | '') {
    if (typeof parent === 'string') {
      this.data.push(new DynamicFlatNode(node, 0, false));

      this.dataChange.next(this.data);
    } else {
      const index = this.data.indexOf(parent);

      if (index < 0) {
        // If no children, or cannot find the node, no op
        return;
      }

      parent.item.hasChild = true;
      parent.expandable = true;

      if (this.treeControl.isExpanded(parent)) {
        this.data.splice(
          index + 1,
          0,
          new DynamicFlatNode(
            node,
            parent.level + 1,
            false
          )
        );
        this.dataChange.next(this.data);
      } else {
        this.treeControl.expand(parent);
      }
    }
  }

  delitedNode(node: DynamicFlatNode, parent: DynamicFlatNode | '') {
    const index = this.data.indexOf(node);

    if (index < 0) {
      // If no children, or cannot find the node, no op
      return;
    }

    let count = 0;
    for (
      let i = index + 1;
      i < this.data.length && this.data[i].level > node.level;
      i++, count++
    ) {}
    this.data.splice(index, count + 1);

    if (!!parent) {
      const indexPar = this.data.indexOf(parent);
      let countPar = 0;
      for (
        let i = indexPar + 1;
        i < this.data.length && this.data[i].level > parent.level;
        i++, countPar++
      ) {}
      if (!countPar) {
        this.treeControl.expand(parent);

        parent.item.hasChild = false;
        parent.expandable = false;
      }
    }

    this.dataChange.next(this.data);
  }
}

class NodeUpdateData {
  data: DirectoriesTree;
  type: 'add' | 'delete';
  folderId: string;
}

/**
 * @title Tree with dynamic data
 */
  // tslint:disable:component-selector
@Component({
  selector: 'ngx-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  providers: [DynamicDatabase],
})
export class TreeComponent implements OnInit, OnDestroy {
  sidebar = true;

  treeControl: FlatTreeControl<DynamicFlatNode>;

  dataSource: DynamicDataSource;

  destroySubj: Subject<void> = new Subject();

  @Input() openingFolder = true;
  @Input() myDocArea = true;
  @Output() folderName: EventEmitter<ChooseFolder> = new EventEmitter<ChooseFolder>();

  constructor(
    public database: DynamicDatabase,
    public router: Router,
    public connectionService: ConnectionService
  ) {
    this.treeControl = new FlatTreeControl<DynamicFlatNode>(
      this.getLevel, this.isExpandable
    );
    this.dataSource = new DynamicDataSource(this.treeControl, database);


    this.connectionService.updateTree$
      .pipe(takeUntil(this.destroySubj))
      .subscribe((val: NodeUpdateData) => {
        let parent: DynamicFlatNode | '';

        if (!val.folderId) {
          parent = '';
        } else {
          parent = this.dataSource.data
            .find(nodeData => nodeData.item.id === val.folderId);
        }

        if (val.type === 'add') {
          this.dataSource.loadNode(val.data, parent);
        } else if (val.type === 'delete') {
          const node = this.dataSource.data
            .find(nodeData => nodeData.item.id === val.data.id);

          this.dataSource.delitedNode(node, parent);
        }
    });
  }

  ngOnInit(): void {
    if (this.myDocArea) {
      this.database
        .setRootData()
        .pipe(takeUntil(this.destroySubj))
        .subscribe((_) => {
          this.dataSource.data = this.database.initialData();
        });
    } else {
      this.database.rootLevelNodes = [
          { id: '', name: 'Мои документы', hasChild: true }
        ];
      this.dataSource.data = this.database.initialData();
    }
  }

  ngOnDestroy(): void {
    this.destroySubj.next();
  }

  /**
   * Открытие папки из меню слева
   * @param folder - директория
   */
  openFolder(folder) {
    this.router.navigate(['documents', folder.id]);
    this.connectionService.setFolder(folder);
  }

  /**
   * Выбор папки при перемещении файла
   */
  chooseFolder(node: DynamicFlatNode) {
    const index = this.dataSource.data.indexOf(node);
    let path = node.item.name;
    const dataSourceForFolder = this.dataSource.data.slice(0, index);

    let level = node.level - 1;
    for (let i = dataSourceForFolder.length - 1; i >= 0; i--) {
      if (dataSourceForFolder[i].level === level) {
        path = `${dataSourceForFolder[i].item.name}/${path}`;
        level--;
      }
    }
    const wayToFolder: ChooseFolder = { path, id: node.item.id };
    this.folderName.emit(wayToFolder);
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  // tslint:disable-next-line:variable-name
  hasChild = (_: number, _nodeData: DynamicFlatNode) => _nodeData.expandable;
}
