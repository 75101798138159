import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UploadStepOneData } from './upload-step-one/upload-step-one.component';
import { UploadStepTwoData } from './upload-step-two/upload-step-two.component';
import { ItemData } from 'src/app/common/interfaces';

@Component({
  selector: 'app-upload-design-doc',
  templateUrl: './upload-design-doc.component.html',
  styleUrls: ['./upload-design-doc.component.scss'],
})
export class UploadDesignDocComponent implements OnInit {
  fileType: string;
  itemID: string;
  itemName = '';
  document: ItemData;
  fileUpload: File = null;
  description = '';
  isStepOne = true;
  step = 'Шаг 1';

  formName = 'Загрузка файла';

  stepOneData: UploadStepOneData;
  stepTwoData: UploadStepTwoData;

  constructor(
    private dialogRef: MatDialogRef<UploadDesignDocComponent>,
    @Inject(MAT_DIALOG_DATA) public documentData
  ) {}

  ngOnInit() {
    if (!!this.documentData) {
      this.formName = this.documentData.formName;
      this.itemName = this.documentData.item.data.name;
      this.document = this.documentData.item;
      this.itemID = this.documentData.item.data;
      this.fileType = this.documentData.item.objType;
    }
  }

  changeStepTwo(event: UploadStepTwoData) {
    this.step = 'Шаг 1';
    this.stepTwoData = event;
    this.isStepOne = true;
  }

  changeStepOne(event: UploadStepOneData) {
    this.step = 'Шаг 2';
    this.stepOneData = event;
    this.isStepOne = false;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
