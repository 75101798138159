<!-- кнопка, по которой кликается после инита компонента, чтобы открылось меню -->
<button mat-button
        [matMenuTriggerFor]="contextPanel"
        [hidden]="true"
        #openContextPanel
        (mouseenter)="openMatMenu()"
        (click)="$event.stopPropagation()">
</button>

<mat-menu class="context-menu"
          #contextPanel="matMenu"
          (closed)="closeMatMenu()">
  <button class="context-item"
          mat-menu-item
          (click)="viewItem(item)"
          [disabled]="!item?.data?.urlView && item.data.objType !== 'directory'">
    <div class="view">
      <mat-icon svgIcon="appSvg:open"></mat-icon>
      <span>Посмотреть</span>
    </div>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.download"
          mat-menu-item
          [disabled]="!item?.data?.urlDownload">
    <a class="link" [href]="item?.data?.urlDownload">
      <mat-icon svgIcon="appSvg:download"></mat-icon>
      <span>Скачать</span>
    </a>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.print"
          mat-menu-item
          [disabled]="item.objType !== 'file'">
    <a class="link" [href]="item?.data?.urlView" target="_blank">
      <mat-icon svgIcon="appSvg:print"></mat-icon>
      <span>Печать</span>
    </a>
  </button>

  <!-- TODO: убрать [disabled]="true" -->
  <button class="context-item"
          *ngIf="contextPanelData.copyTo"
          mat-menu-item
          (click)="copyItemTo()"
          [disabled]="true">
    <mat-icon svgIcon="appSvg:copy"></mat-icon>
    <span>Копировать в</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.change"
          mat-menu-item
          [matMenuTriggerFor]="change"
          [disabled]="item.objType !== 'file'">
    <mat-icon svgIcon="appSvg:change"></mat-icon>
    <span>Изменить</span>
  </button>

  <!--    TODO: убрать  || item.type === 'archive', когда можно будет делиться архивом    -->
  <button class="context-item"
          *ngIf="contextPanelData.share"
          mat-menu-item
          (click)="shareFile()"
          [disabled]="item.objType === 'directory' ||
          item.objType === 'archive'">
    <mat-icon svgIcon="appSvg:share"></mat-icon>
    <span>Поделиться</span>
  </button>

  <button class="context-item"
          *ngIf="tags && contextPanelData.tag"
          mat-menu-item
          [matMenuTriggerFor]="tag"
          [disabled]="!tags.length">
    <mat-icon svgIcon="appSvg:tag"></mat-icon>
    <span>TAGS</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.addTo"
          mat-menu-item
          [matMenuTriggerFor]="addTo"
          [disabled]="item.objType === 'archive'">
    <mat-icon svgIcon="appSvg:buffer"></mat-icon>
    <span>Добавить</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.unpackTo"
          mat-menu-item
          [matMenuTriggerFor]="unpackTo"
          [disabled]="item.objType === 'file'">
    <mat-icon svgIcon="appSvg:unpack"></mat-icon>
    <span>Распаковать в</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.removeFromBuffer"
          mat-menu-item
          (click)="deleteFromBuffer()" >
    <mat-icon svgIcon="appSvg:buffer"></mat-icon>
    <span>Удалить из буфера</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.removeFromArchive"
          mat-menu-item
          (click)="deleteFromArchive()">
    <mat-icon svgIcon="appSvg:buffer"></mat-icon>
    <span>Удалить из архива</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.info"
          mat-menu-item
          (click)="infoItem()">
    <mat-icon svgIcon="appSvg:info"></mat-icon>
    <span>Свойства</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.remove"
          (click)="deleteItem()"
          mat-menu-item>
    <mat-icon svgIcon="appSvg:trash"></mat-icon>
    <span>Удалить</span>
  </button>

  <button class="context-item"
          *ngIf="contextPanelData.toMyDoc"
          mat-menu-item
          (click)="copyItemTo()">
    <mat-icon svgIcon="appSvg:copy_to_mydoc"></mat-icon>
    <span>В мои документы</span>
  </button>

  <button class="context-item success"
          *ngIf="contextPanelData.success"
          mat-menu-item
          [disabled]="true">
    <mat-icon svgIcon="appSvg:success"></mat-icon>
    <span>Подписать</span>
  </button>
</mat-menu>

<mat-menu #change="matMenu">
  <button class="context-item" mat-menu-item>
    <mat-icon svgIcon="appSvg:copy"></mat-icon>
    <span>Копировать</span>
  </button>
  <button class="context-item" mat-menu-item>
    <mat-icon svgIcon="appSvg:paste"></mat-icon>
    <span>Вставить</span>
  </button>
  <button class="context-item" mat-menu-item>
    <mat-icon svgIcon="appSvg:rename"></mat-icon>
    <span>Переименовать</span>
  </button>
  <button class="context-item" mat-menu-item>
    <mat-icon svgIcon="appSvg:replace"></mat-icon>
    <span>Переместить</span>
  </button>
</mat-menu>

<mat-menu class="tags-menu" #tag="matMenu">
  <button class="context-item tag-item"
          *ngFor="let tag of tags"
          mat-menu-item
          (click)="setTag(tag, item?.data)">
    <span class="dot" [ngStyle]="{ background: tag.color }"></span>
    <span class="tag-item-text"
          [ngClass]="{ selected: selectedTags(tag, item) }">
      {{ tag.name }}
    </span>
  </button>
</mat-menu>

<mat-menu #addTo="matMenu" class="add-menu">
  <button class="context-item" mat-menu-item (click)="bufferFile()">
    <mat-icon svgIcon="appSvg:buffer"></mat-icon>
    <span>В буфер</span>
  </button>
  <button class="context-item context-item--centering"
          mat-menu-item
          (click)="setFavorite()"
          [ngClass]="{'remove-fav': isFavorite}">
    <mat-icon  id="icon-star" svgIcon="appSvg:star"></mat-icon>
    <span>{{ !isFavorite ? 'В избранное' : 'Убрать из избранного' }} </span>
  </button>
<!--  TODO: обновить стили для context-panel  -->
<!--  <button mat-menu-item class="context-item project-docs" (click)="addToProjectDocuments()">-->
<!--    <div><mat-icon [svgIcon]="'appSvg:project_docs'"></mat-icon></div>-->
<!--    <div class="project-docs-text">В проектные документы</div>-->
<!--  </button>-->
  <button class="context-item" mat-menu-item (click)="addToProjectDocuments()">
    <mat-icon svgIcon="appSvg:project_docs"></mat-icon>
    <span>В исполнительные документы</span>
  </button>
</mat-menu>

<mat-menu class="unpack-menu" #unpackTo="matMenu">
  <button class="context-item" mat-menu-item (click)="unpackToDirectory()">
    <mat-icon svgIcon="appSvg:folder"></mat-icon>
    <span>Мои документы</span>
  </button>
  <!--  TODO: обновить стили для context-panel  -->
  <!--  <button mat-menu-item class="context-item project-docs" (click)="addToProjectDocuments()">-->
  <!--    <div><mat-icon [svgIcon]="'appSvg:project_docs'"></mat-icon></div>-->
  <!--    <div class="project-docs-text">В проектные документы</div>-->
  <!--  </button>-->
  <button class="context-item"
          mat-menu-item
          (click)="unpackToProjectDocuments()">
    <mat-icon svgIcon="appSvg:project_docs"></mat-icon>
    <span>Исполнительные документы</span>
  </button>
</mat-menu>
