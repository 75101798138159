export class DataOnboarding {
  nameOnboarding: string;
  dataOnboardingSlide: DataOnboardingSlide[];
}

export class DataOnboardingSlide {
  slideName: string;
  imgURL: string;
  slideText: string;
}
