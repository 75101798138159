import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebsocketApiService } from 'src/app/services/websockets/websocket-api.service';
import { WS } from 'src/app/services/websockets/websocket.events';
import { takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { AttrPath, ShareDesignDoc } from '../tree-design-doc/tree-design-doc.component';
import { MatDialog } from '@angular/material/dialog';
import { ShareDocumentComponent } from 'src/app/shared/components/context-panel/context-modals/share-document/share-document.component';
import { Attribute } from '../tree-design-doc/helpers/design-doc-interfaces';
import { DownloadArchiveComponent } from '../../../../shared/components/modals';
import { InfoMenuService } from '../../services/info-menu.service';
import { ConnectionService } from '../../../../services/connection.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
})
export class ProjectsComponent implements OnInit{
  rootId = '';
  secondAttributeId = '';
  firstAttributeId = '';
  tabs: Attribute[];
  secondColumnTabs: Attribute[];
  tabName: string;
  secondColumnName: string;
  isTree = false;
  destroySubj: Subject<boolean> = new Subject();

  attributes: string[] = [];
  fileId = '';
  fileArray: ShareDesignDoc[] = [];
  checkArray: ShareDesignDoc[] = [];
  hideAttributeList = false;
  commonTabId = '5ece5454a54d750f472a732f';
  isCommonTab = true;

  @ViewChild('tabAttrList', { static: true }) tabAttrList: ElementRef;

  constructor(
    private activatedRoute: ActivatedRoute,
    private wsService: WebsocketApiService,
    private matDialog: MatDialog,
    private infoMenuService: InfoMenuService,
    private connectionService: ConnectionService
  ) { }

  ngOnInit() {
    this.initSubscriber();
  }

  initSubscriber() {
    this.activatedRoute.params.subscribe((param: AttrPath) => {
      this.rootId = param.path;
      this.secondAttributeId = '';
      if (!!param.attrPath) {
        const attributes = decodeURI(param.attrPath).split('/');
        // this.rootId = attributes[0];

        if (!!param.fileId && !!attributes.length) {
          this.attributes = attributes;
          this.fileId = param.fileId;
          // TODO: add change url without update page
          // location.replace( '/designdoc/' + this.rootId);
        }
      } else {
        this.fileId = '';
        this.attributes = [];
        if (!!this.tabAttrList) {
          this.tabAttrList.nativeElement.scrollTo(0, 0);
        }
      }

      this.isCommonTab = this.rootId === this.commonTabId;
      this.getDesignTree();
      this.isTree = false;
    });

    this.connectionService.secondColumnTabs
      .subscribe(() => {
        if (this.secondColumnTabs) {
          this.secondColumnTabs = undefined;
          this.hideAttributeList = false;
          this.tabName = '';
        }
      });
  }

  get infoMenu(): boolean {
    return this.infoMenuService.getInfoMenuValue().infoMenu;
  }

  getStartAttribute(tab): void {
    this.firstAttributeId = tab.id;
    this.secondColumnName = tab.name;
    const messageId = String(+new Date());
    this.wsService
      .send(WS.SEND.getAttributes,
        { rootAttributeID: tab.id },
        messageId);
    this.wsService
      .on<any>(messageId, null)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(data => {
        Object.keys(data).length == 0
          ? this.secondColumnTabs = data
          : this.secondColumnTabs = data.attributes;
      });
  }

  check(): boolean {
    return !(Object.keys(this.secondColumnTabs).length == 0);
  }

  getDesignTree(): void {
    const messageId = String(+new Date());
    this.wsService
      .send(WS.SEND.getAttributes,
        { rootAttributeID: this.rootId },
        messageId);
    this.wsService
      .on<any>(messageId, null)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (data) => {
          this.tabs = data.attributes;
          if (this.fileId && !!this.attributes.length) {
            const rootUrl = this.attributes[2];
            setTimeout(() => {
              const foundElement = document.getElementById(rootUrl);
              if (!!foundElement) {
                foundElement.scrollIntoView();
              }
            });
            this.checkedAttribute(
              this.tabs.find((val) => val.id === rootUrl)
            );
          }
          this.isTree = true;
        },
        (err) => throwError(err)
      );
  }

  checkedAttribute(tab: Attribute): void {
    this.secondAttributeId = tab.id;
    this.tabName = tab.name;
  }

  checkActive(id: string, attributeId: string): boolean {
    return attributeId === id;
  }

  getFileList(event: ShareDesignDoc[]): void {
    this.fileArray = event;
  }

  shareDoc(): void {
    const modal = this.matDialog.open(ShareDocumentComponent, {
      width: '415px',
      height: '504px',
      panelClass: 'share_for_user',
      data: this.fileArray,
    });

    modal.afterClosed().subscribe((val) => {
      if (!!val) {
        this.checkArray = [];
      }
    });
  }

  download(): void {
    this.wsService.send(
      WS.SEND.createArchive,
      {type: '', attributesIDs: [this.secondAttributeId]},
      String(+new Date())
    );
  }

  createArchive(archiveType: string): void {
    const messageId = String(+new Date());
    this.wsService.send(
      WS.SEND.createArchive,
      {type: archiveType, attributesIDs: [this.secondAttributeId]},
      messageId
    );

    this.downloadArchive(messageId);
  }

  downloadArchive(messageId: string): void {
    this.matDialog.open(DownloadArchiveComponent, {
      height: '159px',
      width: '292px',
      panelClass: 'download_archive',
      data: messageId,
      disableClose: true
    });
  }

  hideAttributeLists(): void {
    this.hideAttributeList = !this.hideAttributeList;
  }
}
