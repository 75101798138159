<div class="container">
  <div class="title">
    <div class="titleName">
      <span class="textName">{{ title }}</span>
    </div>
    <button class="closeButton" (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>

  <form [formGroup]="form">
    <div class="form-box overflow">
      <div class="tag-info" formArrayName="formArray" *ngFor="let control of formArrayList.controls; let i = index">
        <ng-container [formGroupName]="i">
          <div class="input-box">
            <div class="tag-color">
              <div class="title-color">Цвет:</div>
              <button class="select-color" (click)="selectColor(i)">
                <div class="category">
                  <span class="dot" [ngStyle]="{ 'background-color': formArrayList.value[i].color }"></span>
                  <mat-icon class="selected-arrow">arrow_drop_down</mat-icon>
                </div>
              </button>
            </div>
            <div class="" [ngClass]="formArrayList.controls.length == 1 ? 'first-tag-name' : 'tag-name'">
              <mat-form-field class="form-field">
                <mat-label>Имя tag</mat-label>
                <input matInput formControlName="name" />
                <mat-error *ngIf="formArrayList.controls[i].get('name').hasError('required')">
                  Введите название tag
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <button class="delete-row" (click)="removeItem(i)" type="submit" *ngIf="formArrayList.controls.length !== 1">
            <mat-icon aria-hidden="false">close</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>

    <div class="button">
      <div class="add-row-button" *ngIf="!tagData">
        <div class="add-group" (click)="addItem()">
          <mat-icon class="add-circle" [svgIcon]="'appSvg:add_circle_outline'"></mat-icon>
          <span>Добавить tag</span>
        </div>
      </div>
      <div class="add-row-button" *ngIf="tagData">
        <div class="add-group" (click)="closeModal()">
          <span class="cancel">Отмена</span>
        </div>
      </div>
      <app-button class="save-tegs" type="submit" (click)="saveData()">Сохранить</app-button>
    </div>
  </form>
</div>
