import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Attribute } from '../components/design-documentation/components/tree-design-doc/helpers/design-doc-interfaces';
import { DesignDocumentsService } from '../components/design-documentation/services/design-documents.service';

@Injectable({ providedIn: 'root' })
export class AttributeResolver implements Resolve<any> {
  public attribute: Attribute | {};

  constructor(private designDocService: DesignDocumentsService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Attribute | {} {
    // this.designDocService.attribute$.subscribe(data => {
    //   this.attribute = data;
    // });
    // console.log(route.data['attribute'], 'resolver');
    this.attribute = route.data.attribute;
    return this.attribute;
  }
}
