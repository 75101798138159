import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { ItemContext, ItemData, ItemType, Version } from '../common/interfaces';
import { AreaType } from '../shared';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  // для передачи данных в контекстное меню
  private itemData = new BehaviorSubject<any>({});
  itemData$ = this.itemData.asObservable();

  // для отображения doc-menu и header
  // private isLoggedIn = new BehaviorSubject<boolean>(false);
  // isLoggedIn$ = this.isLoggedIn.asObservable();

  // для передачи в хлебные крошки
  private folderIds = new BehaviorSubject<string>('');
  folderIds$ = this.folderIds.asObservable();

  private folder = new BehaviorSubject<any>({});
  folder$ = this.folder.asObservable();

  // для обновления дерева
  private updateTree = new Subject<any>();
  updateTree$ = this.updateTree.asObservable();

  // для получения id в компоненте doc-menu
  private isRoot = new BehaviorSubject<boolean>(false);
  isRoot$ = this.isRoot.asObservable();

  private fileLink = new BehaviorSubject<string>('');
  fileLink$ = this.fileLink.asObservable();

  private favorite = new Subject<any>();
  favorite$ = this.favorite.asObservable();

  public version = new Subject<any>();

  public secondColumnTabs = new Subject<any>();

  setItemData(
    data: ItemContext | Version,
    objType: ItemType,
    area: AreaType
  ): void {
    const cfg: ItemData = { data, objType, area };
    this.itemData.next(cfg);
  }

  // checkUserLogin(login: boolean): void {
  //   this.isLoggedIn.next(login);
  // }

  setFolderIds(id: string): void {
    this.folderIds.next(id);
  }

  setUpdatingTree(data: any): void {
    this.updateTree.next(data);
  }

  setFolder(folder: string): void {
    this.folder.next(folder);
  }

  isRootFolder(folder: boolean): void {
    this.isRoot.next(folder);
  }

  setFileLink(link: string): void {
    this.fileLink.next(link);
  }

  setFavorite(data: any, favorite: boolean): void {
    const itemData = { data, favorite};
    this.favorite.next(itemData);
  }

  setVersion(version: any): void {
    this.version.next(version);
  }

  setSecondColumnTabs(): void {
    this.secondColumnTabs.next();
  }
}
