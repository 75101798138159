import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements AfterViewInit {

  private readonly TITLE_LENGTH_WITH_TOOLTIP = 20;

  public tooltip: [{name: string}] = null;

  @Input() public node;
  @Input() public folderView;

  @ViewChild('title') title: ElementRef;

  /** TODO: подумать другое решение появления тултипа при большой длине заголовка файла */
  ngAfterViewInit() {
    if (+this.title.nativeElement.offsetWidth > +this.folderView.offsetWidth) {
      this.title.nativeElement.textContent = `${this.title.nativeElement.textContent.slice(0, this.TITLE_LENGTH_WITH_TOOLTIP)}...`;

      this.tooltip = [{name: this.node.item.name}];
    }
  }

}
