import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ColorEvent } from 'ngx-color';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss']
})
export class ColorPickerComponent implements OnInit, OnDestroy {
  presetColors = [
    '#F44336',
    '#E91E63',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#2196F3',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#FFEB3B',
    '#FF9800',
    '#FF5722',
    '#795548',
    '#9E9E9E',
    '#E0E0E0',
    '#607D8B',
    '#000000',
    '#FFFFFF'
  ];

  color: string;

  constructor(private dialogRef: MatDialogRef<ColorPickerComponent>) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  handleChange($event: ColorEvent) {
    this.color = $event.color.hex;
  }

  closeModal() {
    this.dialogRef.close();
  }

  saveColor() {
    this.dialogRef.close(this.color);
  }
}
