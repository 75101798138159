import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-loading-design-tree',
  templateUrl: './skeleton-loading-design-tree.component.html',
  styleUrls: ['./skeleton-loading-design-tree.component.scss'],
})
export class SkeletonLoadingDesignTreeComponent implements OnInit {
  quantityRow = new Array(30);

  constructor() {}

  ngOnInit() {}
}
