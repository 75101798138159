import { Injectable } from '@angular/core';
import { ToastrService, GlobalConfig } from '../shared/components/lib/public_api';

@Injectable({ providedIn: 'root' })
export class ToastService {
  options: GlobalConfig;
  private lastInserted: number[] = [];

  constructor(public toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }

  openToast(message: string, title?: string) {
    const inserted = this.toastr.show(message, title, { ...this.options }, this.options.iconClasses['info']);
    if (inserted) {
      this.lastInserted.push(inserted.toastId);
    }
  }
}
