import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataOnboarding, DataOnboardingSlide } from './onboarding-interfaces';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
  nameOnboarding = '';
  dataOnboarding: DataOnboardingSlide[] = [];

  @ViewChild('myCarousel') myCarousel;

  constructor(
    private dialogRef: MatDialogRef<OnboardingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataOnboarding
  ) {}

  ngOnInit(): void {
    this.nameOnboarding = this.data.nameOnboarding;
    this.dataOnboarding = this.data.dataOnboardingSlide;
  }

  close() {
    this.dialogRef.close();
  }
}
