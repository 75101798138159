import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ItemContext } from '../../../../../common/interfaces';
import { CommonHelper } from '../../../../../helpers/commonHelper';
import { timeConversion } from '../../../../../utils/time-conversion';

@Component({
  selector: 'app-info-document',
  templateUrl: './info-document.component.html',
  styleUrls: ['./info-document.component.scss']
})
export class InfoDocumentComponent extends CommonHelper implements OnInit {

  item: ItemContext;
  ownerName: string;
  attributes: {
    titles: string;
    marks: string;
  };
  timeConversion = timeConversion;

  get showSize(): string {
    return this.formatSize(this.item.size);
  }

  constructor(
    private dialogRef: MatDialogRef<InfoDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.data = this.data.hasOwnProperty('data')
      ? {...this.data.data, type: this.data.objType, area: this.data.area }
      : this.data;
    this.ownerName = this.data.hasOwnProperty('owner')
      ? `${this.data.owner.firstname} ${this.data.owner.lastname}`
      : '';
    this.attributes = this.getAttributes(this.data);
    this.item = this.data;
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  getAttributes(data: ItemContext): any {
    if (data.hasOwnProperty('attributes') && data.attributes.length) {
      return {
        titles: data.attributes.filter(x => x.type === 'Титул').map(x => x.name).join(', '),
        marks: data.attributes.filter(x => x.type === 'Марка').map(x => x.name).join(', ')
      };
    } else {
      return {title: '', marks: ''};
    }
  }
}
