import {ItemContext, ItemType, Version} from '../../../common/interfaces';

export type AreaType = 'mydoc' | 'archives';

export interface ContextPanelData {
  open: boolean;
  download: boolean;
  print: boolean;
  change: boolean;
  share: boolean;
  tag: boolean;
  addTo: boolean;
  unpackTo: boolean;
  removeFromBuffer: boolean;
  removeFromArchive: boolean;
  copyTo: boolean;
  info: boolean;
  remove: boolean;
  success: boolean;
  toMyDoc: boolean;
}

export interface ContextPanel {
  event: MouseEvent;
  item: ItemContext | Version;
  type: ItemType;
  componentData: ContextPanelKeys[];
  area?: AreaType;
}

export class ContextPanelModel implements ContextPanel {
  constructor(
    public event: MouseEvent,
    public item: ItemContext | Version,
    public type: ItemType,
    public componentData: ContextPanelKeys[],
    public area?: AreaType
  ) {
  }
}

export type ContextPanelKeys =
  | 'open'
  | 'download'
  | 'print'
  | 'change'
  | 'share'
  | 'tag'
  | 'addTo'
  | 'unpackTo'
  | 'removeFromBuffer'
  | 'removeFromArchive'
  | 'copyTo'
  | 'info'
  | 'remove'
  | 'success'
  | 'toMyDoc';

export class ContextPanelDataModel implements ContextPanelData {
  open = false;
  download = false;
  print = false;
  change = false;
  share = false;
  tag = false;
  addTo = false;
  unpackTo = false;
  removeFromBuffer = false;
  removeFromArchive = false;
  copyTo = false;
  info = false;
  remove = false;
  success = false;
  toMyDoc = false;

  constructor(data: ContextPanelKeys[]) {
    data.forEach(key => (this[key] = true));
  }
}
