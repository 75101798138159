import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UploadDesignDocComponent } from '../upload-design-doc.component';
import { WebsocketApiService } from '../../../../../services/websockets/websocket-api.service';
import { WS } from '../../../../../services/websockets/websocket.events';
import { DocType, IDocType, ItemType } from '../../../../../common/interfaces';

export class UploadStepOneData {
  fileUpload: File;
  revisionNumber: string;
  docType: string;
  description: string;
}

@Component({
  selector: 'app-upload-step-one',
  templateUrl: './upload-step-one.component.html',
  styleUrls: ['./upload-step-one.component.scss'],
})
export class UploadStepOneComponent implements OnInit {
  docName = '';
  description = '';
  typeList: DocType[];
  form: FormGroup;
  checkFileType: RegExp = /(\b.zip|.rar\b)/;

  @Input() data: UploadStepOneData;
  @Input() itemName: string;
  @Input() fileType: ItemType;

  @Output() step: EventEmitter<UploadStepOneData> = new EventEmitter<UploadStepOneData>();

  constructor(
    private dialogRef: MatDialogRef<UploadDesignDocComponent>,
    private fb: FormBuilder,
    private wsService: WebsocketApiService
  ) {}

  ngOnInit() {
    const messageId = String(+new Date());
    this.wsService
      .send(WS.SEND.getDocumentTypes, {}, messageId);
    this.wsService
      .on<IDocType>(messageId, null).subscribe(({ documentTypes })  => {
      this.typeList = documentTypes;
    })
    this.form = this.fb.group({
      fileUpload: [!!this.data ? this.data.fileUpload : !!this.itemName ? this.itemName : '', Validators.required],
      revisionNumber: [
        { value: !!this.data ? this.data.revisionNumber : '', disabled: !(!!this.data && !!this.data.revisionNumber) },
      ],
      docType: [!!this.data ? this.data.docType : '', Validators.required],
      description: [!!this.data ? this.data.description : ''],
      fileType: [this.fileType || ''],
      filesCount: ['']
    });

    if (!!this.data && this.data.fileUpload) {
      this.docName = this.data.fileUpload.name;
    } else if (!!this.itemName) {
      this.docName = this.itemName;
    }
  }

  /**
   * Загрузка файла
   * @param event - данные о файле
   */
  openFile(event) {
    const filesUpload = (event.target as HTMLInputElement).files;
    this.form.get('filesCount').setValue(filesUpload.length)
    Array.from(filesUpload).forEach(file => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        this.docName += file.name + ', ';
        this.fileType = this.checkFileType.test(this.docName) ? 'archive' : 'file';
        this.form.get('fileType')
          .setValue(this.checkFileType.test(this.docName) ? 'archive' : 'file');
      };
    });
    this.form.get('fileUpload').setValue(filesUpload);
  }

  openNextStep() {
    this.step.emit(this.form.value);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
