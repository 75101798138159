import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { LoginService } from 'src/app/components/auth/services/login.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';

@Injectable()
export class AuthGuard implements CanActivate, OnDestroy {

  private isLogin: boolean;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private loginService: LoginService,
    private localStorageService: LocalStorageService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    this.loginService.isLoggedIn$
      .pipe(takeUntil(this.destroy$))
      .subscribe((loginStatus: boolean) => this.isLogin = loginStatus);

    if (!this.isLogin) {

      // let isLogin = JSON.parse(localStorage.getItem('isLoggedIn'));

      // if (route.queryParams.id) { isLogin = true; }
      // if (this.isLogin && !!localStorage.getItem('token')) {
      //   return true;
      // } else {
      //   localStorage.removeItem('isLoggedIn');
      //   localStorage.removeItem('token');
      //   localStorage.removeItem('userId');
      //   localStorage.removeItem('fileId');
      //   this.router.navigate(['/auth']);
      //   return false;
      // }

      this.localStorageService.removeFromLocalStorage('isLoggedIn', 'token', 'userId', 'fileId');

      // localStorage.removeItem('isLoggedIn');
      // localStorage.removeItem('token');
      // localStorage.removeItem('userId');
      // localStorage.removeItem('fileId');

      this.router.navigate(['/auth']);
    }

    return this.isLogin;
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
