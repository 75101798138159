import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class TabMenuService {
  private tabMenu = true;

  constructor() {}

  tabMenuToggle() {
    this.tabMenu = !this.tabMenu;
  }

  getTabMenuValue() {
    return this.tabMenu;
  }
}
