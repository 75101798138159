import { Injectable } from '@angular/core';
import {
  Resolve,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';

@Injectable({ providedIn: 'root' })

export class SharedFileResolver implements Resolve<any> {
  public  file: any = {id: ''};
  getId: RegExp = /[^/a-zA-Z=|.]([a-z0-9_-]{17,24}[\/]?)$/;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const url = new URL(window.location.href);
    this.file = { id: url.pathname.match(this.getId) ? url.pathname.match(this.getId)[0] : '' };
    return this.file;
  }
}
