<div class="file_item" (click)="openContextMenu($event, fileData, fileData.objType)">
  <mat-spinner *ngIf="fileData?.type === 'loading'"
               [diameter]="40" class="spinner">
  </mat-spinner>
  <div class="attributes">
    <mat-icon class="icon-star"
              *ngIf="fileData?.isFavorite"
              svgIcon="appSvg:star_fill">
    </mat-icon>
    <app-tags-tooltip class="tags-mark"
                      [tags]="fileData?.tags">
    </app-tags-tooltip>
  </div>
  <mat-icon class="file"
            *ngIf="fileData.objType"
            [svgIcon]="'appSvg:' + newIcon">
  </mat-icon>
  <mat-icon class="file"
            *ngIf="fileData.type === 'loading'"
            svgIcon="appSvg:img-disable">
  </mat-icon>
  <span class="file_title"
        matTooltipPosition="above"
        [matTooltip]="fileData?.name"
        [matTooltipDisabled]="!enableTooltip"
        #text>
    {{ fileData?.name }}
  </span>
</div>
