<!-- Спинер и скилетон при загрузке проекта -->
<div class="loading" *ngIf="!isTree">
  <mat-spinner class="spinner"></mat-spinner>
  <app-skeleton-loading-design-tree></app-skeleton-loading-design-tree>
</div>

<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" *ngIf="isTree">
  <mat-tree-node *matTreeNodeDef="let node"
                  matTreeNodePadding
                  matTreeNodePaddingIndent="20"
                  [ngClass]="{ 'color-line': node.level != 0 }"
                  [id]="node.item.id">
    <div class="tree-row"
         [attr.aria-label]="'toggle ' + node.filename"
         matTreeNodeToggle
         *ngIf="!node.item.fileType"
    >
      <div class="folder-view" #folderView>
        <button mat-icon-button>
          <mat-icon class="mat-icon-rtl-mirror triangle_left">
            {{ !treeControl.isExpanded(node) ? 'arrow_right' : 'arrow_drop_down' }}
          </mat-icon>
        </button>

        <!-- отображение папок -->
        <div class="node-item" *ngIf="!node.item.fileType">
          <mat-icon class="node-item-icon" svgIcon="appSvg:full_folder">
          </mat-icon>
<!--          <div class="text-wrapper node-" title="{{ node.item.name }}">-->
<!--            <span class="text">{{ node.item.name }}</span>-->
<!--          </div>-->

          <div class="text-wrapper node-item-name">
            <app-title [node]="node" [folderView]="folderView"></app-title>
          </div>
        </div>
        <mat-spinner [diameter]="18" *ngIf="node.isLoading"></mat-spinner>
      </div>

      <div>
        <div>
          <span></span>
        </div>

        <!-- кнопка скачать и информация для папок -->
        <!-- TODO: вставить текст и исправить условия после добавления функционала -->
        <div class="folder-file-info">
          <div class="decryption"></div>
          <div class="folders-number">
            {{ contains(node.item.counts, 'directories', foldersMock) }}
          </div>
          <div class="files-number text">
            {{ contains(node.item.counts, 'files', filesMock) }}
          </div>
          <div class="actions">
            <button *ngIf="!node.item.hasOwnProperty('objType'); else attribute"
                    [matMenuTriggerFor]="menu"
                    class="button-menu"
                    matTooltip="Скачать архив">
              <mat-icon class="buttons-icon" svgIcon="appSvg:download">
              </mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #attribute class="more-vert">
      <button mat-mini-fab [matMenuTriggerFor]="menu" class="more-vert-button">
        <mat-icon>more_vert</mat-icon>
      </button>
    </ng-template>

    <!-- отображение файлов -->
    <div class="tree-row-file"
         *ngIf="node.item.fileType"
         [ngClass]="{ active_file: checkActive(node?.item?.id) }"
         (click)="checkedFile($event, node?.item.id, node?.item.name)">
      <div class="folder-view" #folderView>
        <button mat-icon-button></button>
        <div class="node-item">
          <mat-icon class="node-item-icon"
                    [svgIcon]="'appSvg:' + node.item?.type?.short + '_rus'">
          </mat-icon>
          <div class="text-wrapper node-item-name">
            <app-title class="text-title"
                       [node]="node"
                       [folderView]="folderView">
            </app-title>
          </div>
        </div>
      </div>

      <div class="folder-file-info">
        <div class="type-icon">
          <mat-icon [svgIcon]="'appSvg:' + node.item.fileType + '-small'">
          </mat-icon>
        </div>

        <div class="time">
          {{ timeConversion(node.item?.timestamp?.created)
          | date: 'dd.MM.yyyy' }}
        </div>

        <div class="status">
          <mat-icon class="status-icon" svgIcon="appSvg:in_progress"></mat-icon>
        </div>

        <div class="more-vert"
             *ngIf="node?.item?.fileType"
             (click)="$event.stopPropagation()">
          <button class="more-vert-button"
                  mat-mini-fab
                  [matMenuTriggerFor]="fileMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>
      </div>
    </div>

    <mat-menu #fileMenu="matMenu">
      <a class="link" (click)="viewItem(node?.item)" target="_blank">
        <button mat-menu-item>
          <mat-icon class="buttons-icon" svgIcon="appSvg:open"></mat-icon>
          <span>Посмотреть</span>
        </button>
      </a>
      <a class="link" [href]="node?.item?.urlDownload">
        <button mat-menu-item>
          <mat-icon class="buttons-icon" svgIcon="appSvg:download"></mat-icon>
          <span>Скачать</span>
        </button>
      </a>
      <button mat-menu-item (click)="copyToMyDoc(node.item)">
        <mat-icon class="buttons-icon" svgIcon="appSvg:copy_to_mydoc">
        </mat-icon>
        <span>Копировать в 'Мои документы'</span>
      </button>
      <button mat-menu-item (click)="shareDoc(node.item)">
        <mat-icon class="buttons-icon" svgIcon="appSvg:share"></mat-icon>
        <span>Поделиться</span>
      </button>
      <button mat-menu-item (click)="infoItem(node.item)">
        <mat-icon class="buttons-icon" svgIcon="appSvg:info"></mat-icon>
        <span>Свойства</span>
      </button>
      <button (click)="delete(node)" mat-menu-item>
        <mat-icon class="buttons-icon" svgIcon="appSvg:trash"></mat-icon>
        <span>Удалить</span>
      </button>
    </mat-menu>

    <mat-menu #menu="matMenu" yPosition="below">
        <button *ngIf="node.item.hasOwnProperty('objType')"
                (click)="delete(node)"
                mat-menu-item>
          <mat-icon class="buttons-icon" svgIcon="appSvg:trash"></mat-icon>
          <span>Удалить</span>
        </button>
        <button class="load_file_btn load_btn"
                mat-menu-item
                (click)="createArchive(node, 'zip')">
          <span class="text">Скачать ZIP-архив</span>
        </button>
        <button class="load_btn"
                mat-menu-item
                [disabled]="true"
                (click)="createArchive(node, 'rar')">
          <span class="text">Скачать RAR-архив</span>
        </button>
    </mat-menu>
  </mat-tree-node>
</mat-tree>
