import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WebsocketApiService } from './websocket-api.service';
import { config } from './websocket.config';
import { WebSocketConfig } from './websocket.interfaces';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    WebsocketApiService
  ]
})
export class WebsocketModule {
  public static config(wsConfig: WebSocketConfig): ModuleWithProviders<WebsocketModule> {
    return {
      ngModule: WebsocketModule,
      providers: [{ provide: config, useValue: wsConfig }]
    };
  }
}
