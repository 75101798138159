<div class="container">
  <div class="title">
    <div class="titleName">
      <span class="textName">Копировать в Мои документы</span>
    </div>
    <button class="closeButton" (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>

  <div class="form-box">
    <div class="choose-place">Выбрать место</div>
    <div class="folder">Папка:</div>
    <div class="choose-folder">
      <button [matMenuTriggerFor]="menu" class="open-tree">
        <div class="select-folder">
          <div class="navigate">{{ folderName }}</div>
          <div>
            <mat-icon class="arrow_drop_down">arrow_drop_down</mat-icon>
          </div>
        </div>
      </button>
      <mat-menu #menu="matMenu" class="tree-menu">
        <ngx-tree class="tree" [openingFolder]="false" [myDocArea]="false" (folderName)="getFolderName($event)"></ngx-tree>
      </mat-menu>
    </div>
  </div>

  <div class="button">
    <div>
      <app-button class="left-button" backgroundColor="white" (click)="closeModal()">Закрыть</app-button>
      <app-button [disabled]="!folderName" class="right-button" type="submit" (click)="saveData()">Сохранить</app-button>
    </div>
  </div>
</div>
