import { AfterViewChecked, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FileType, ItemContextArrays } from '../../../common/interfaces';
import { ContextPanelService } from '../../../services/context-panel.service';
import { DocContentService } from '../../../services/doc-content.service';
import { ConnectionService } from '../../../services/connection.service';
import { ContextPanelKeys } from '../context-panel';
import { timeConversion } from '../../../utils/time-conversion';

@Component({
  selector: 'app-doc-content',
  templateUrl: './doc-content.component.html',
  styleUrls: ['./doc-content.component.scss'],
})
export class DocContentComponent implements OnInit, OnDestroy, AfterViewChecked {

  private onDestroy$: Subject<void> = new Subject();

  iconType: string;
  previousDirId: string;
  messageId: string;
  searchingFileId = '';
  contextPanelData: ContextPanelKeys[] = [
    'open',
    'download',
    'print',
    'change',
    'share',
    'tag',
    'addTo',
    'info',
    'remove',
    'success'
  ];
  timeConversion = timeConversion;

  @Input() sortByDateFlag: boolean;
  @Input() sortByTitleFlag: boolean;
  @Input() dataList: ItemContextArrays[];

  constructor(
    private contextPanelService: ContextPanelService,
    private connectionService: ConnectionService,
    private router: Router,
    public docContentService: DocContentService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.changeFileName();
    this.route.params
      .subscribe(param => this.searchingFileId = param.fileId);
  }

  ngAfterViewChecked(): void {
    if (!!this.searchingFileId && !!this.dataList) {
      const foundElement = document.getElementById(this.searchingFileId);
      if (!!foundElement) {
        foundElement.scrollIntoView();
      }

      setTimeout(() => {
        this.searchingFileId = '';
      }, 2500);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  openFolder(folder): void {
    this.router.navigate(['documents', folder.id]);
    this.connectionService.setFolder(folder);
  }

  changeFileName(): void {
    this.connectionService.version
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ name, id }) => {
        this.dataList[0]
          .forEach((item: FileType) => item.id === id ? item.name = name : null);
      });
  }
}
