import { Pipe, PipeTransform } from '@angular/core';
import { ItemContextArrays } from '../common/interfaces';

@Pipe({
  name: 'sortFilesByTime',
  pure: false
})
export class SortFilesByTimePipe implements PipeTransform {

  transform(files: ItemContextArrays, criteria: boolean): ItemContextArrays {
    return files.sort((a, b) =>
      criteria ? b.timestamp.created - a.timestamp.created : a.timestamp.created - b.timestamp.created);
  }

}
