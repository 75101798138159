import { Injectable } from '@angular/core';
import { DynamicFlatNode } from './helpers/treeHepler';
import { ShareDesignDoc } from './tree-design-doc.component';

@Injectable({ providedIn: 'root' })
export class TreeDesignService {
  constructor() {}

  getCheckedInterval(data: DynamicFlatNode[], idStart: string, idEnd: string): ShareDesignDoc[] {
    const checkInterval: ShareDesignDoc[] = [];
    let isInterval = false;

    data.forEach((node) => {
      const { id, name } = node.item;

      if (id === idStart || id === idEnd) {
        isInterval = !isInterval;
        if (!isInterval) {
          checkInterval.push({ id, name });
        }
      }

      if (!!isInterval && node.item.hasOwnProperty('objType')) {
        checkInterval.push({ id, name });
      }
    });

    return checkInterval;

    // for (let i = 0; i < data.length; i++) {
    //   const id = data[i].item.id;
    //   const name = data[i].item.name;
    //   if (id === idStart || id === idEnd) {
    //     isInterval = !isInterval;
    //     if (!isInterval) {
    //       checkInterval.push({ id, name });
    //       return checkInterval;
    //     }
    //   }
    //
    //   if (!!isInterval && data[i].item.hasOwnProperty('objType')) {
    //     checkInterval.push({ id, name });
    //   }
    // }

  }
}
