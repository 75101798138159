<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <mat-tree-node *matTreeNodeDef="let node"
                 matTreeNodePadding
                 matTreeNodePaddingIndent="20">
    <ng-container *ngIf="!node.item.hasChild">
      <button mat-icon-button disabled></button>
      <div class="folder" *ngIf="openingFolder" (click)="openFolder(node.item)">
        <mat-icon class="full_folder"
                  svgIcon="appSvg:full_folder">
        </mat-icon>
        <div class="text-wrapper folder-name">
          <span class="text">{{ node.item.name }}</span>
        </div>
      </div>
      <div class="folder" *ngIf="!openingFolder" (click)="chooseFolder(node)">
        <div class="text-wrapper folder-name">
          <span class="text">{{ node.item.name }}</span>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="node.item.hasChild">
      <button *ngIf="treeControl.isExpandable(node)"
              mat-icon-button
              matTreeNodeToggle
              [attr.aria-label]="'toggle ' + node.filename">
        <mat-icon class="mat-icon-rtl-mirror triangle_left">
          {{ !treeControl.isExpanded(node) ? 'arrow_right' : 'arrow_drop_down' }}
        </mat-icon>
      </button>
      <div class="folder" *ngIf="openingFolder" (click)="openFolder(node.item)">
        <mat-icon class="full_folder"
                  svgIcon="appSvg:full_folder">
        </mat-icon>
        <div class="text-wrapper folder-name">
          <span class="text">{{ node.item.name }}</span>
        </div>
      </div>
      <div class="folder" *ngIf="!openingFolder" (click)="chooseFolder(node)">
        <div class="text-wrapper folder-name">
          <span class="text">{{ node.item.name }}</span>
        </div>
      </div>
    </ng-container>
  </mat-tree-node>
</mat-tree>
