<div class="container">
  <div class="title">
    <div class="titleName">
      <span class="textName">Выбор цвета tag</span>
    </div>
    <button class="closeButton" (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>
  <color-sketch [presetColors]="presetColors"
                [width]="302"
                [disableAlpha]="false"
                (onChangeComplete)="handleChange($event)">
  </color-sketch>

  <div class="button">
    <app-button (click)="saveColor()">Сохранить</app-button>
  </div>
</div>
