<div class="container">
  <div class="title">
    <div class="titleName">
      <mat-icon [svgIcon]="'appSvg:info'"></mat-icon>
      <span class="textName">Свойства</span>
    </div>
    <button class="closeButton" (click)="closeModal()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="info-block">
<!--    <div class="info-item">-->
<!--      <div class="info-item-title">№ титула:</div>-->
<!--      <div class="info-item-description">{{ attributes.titles }}</div>-->
<!--    </div>-->
<!--    <div class="info-item">-->
<!--      <div class="info-item-title">Марка:</div>-->
<!--      <div class="info-item-description">{{ attributes.marks }}</div>-->
<!--    </div>-->
    <div class="info-item">
      <div class="info-item-title">Название:</div>
      <div class="info-item-description">{{ item.name }}</div>
    </div>
    <div class="info-item">
      <div class="info-item-title">Владелец:</div>
      <div class="info-item-description">{{ ownerName }}</div>
    </div>
<!--    <div class="info-item">-->
<!--      <div class="info-item-title">Доступно:</div>-->
<!--      <div class="info-item-description"></div>-->
<!--    </div>-->
    <div class="info-item">
      <div class="info-item-title">Загружено:</div>
      <div class="info-item-description">
        {{ timeConversion(item.timestamp.created)
        | date: 'h:mm dd.MM.yyyy' }}
      </div>
    </div>
    <div class="info-item">
      <div class="info-item-title">Комментарии:</div>
      <div class="info-item-description"></div>
    </div>
    <div class="info-item">
      <div class="info-item-title">Статус:</div>
      <div class="info-item-description"></div>
    </div>
    <div class="info-item">
      <div class="info-item-title">Ревизия:</div>
      <div class="info-item-description"></div>
    </div>
    <div class="info-item">
      <div class="info-item-title">Размер:</div>
      <div class="info-item-description">{{ showSize }}</div>
    </div>
  </div>
</div>
