<div class="content">
  <form [formGroup]="form" class="form">
    <div class="select-file">Добавление атрибутов</div>
    <div class="form-box overflow">
      <div formArrayName="attributes"
           *ngFor="let control of formAttributesList.controls; let i = index"
           class="input-box">
        <div class="loader">
          <mat-spinner style="margin: 0 auto;" [diameter]="23" *ngIf="!startAttributes[i] && isMatch"></mat-spinner>
        </div>
        <ng-container [formGroupName]="i" *ngIf="startAttributes[i] || (!startAttributes[i] && !isMatch)">
          <div>
            <div class="title">
              <div class="section-name">Выберите тип атрибута</div>
              <div class="section-name">Выберите значение атрибута</div>
            </div>
            <div class="title-select">
              <mat-form-field [ngClass]="formAttributesList.controls.length == 1 ? 'first-select' : 'select'">
                <mat-select formControlName="type"
                            (selectionChange)="selectedType(control)"
                            class="not-multiple-menu">
                  <mat-option *ngFor="let attribute of uniqueTypesList[i]"
                              [value]="attribute.type.id">
                    {{ attribute.type.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <app-search-select style="width: 164px;"
                                 formControlName="value"
                                 [isMultiple]="true"
                                 [options]="startAttributes[i] | uniqueAttributes: getAttributeValue(i).type"
                                 (selectChange)="checkChild($event, startAttributes[i])">
              </app-search-select>
            </div>
          </div>
          <button class="delete-row"
                  (click)="removeItem(i)"
                  type="submit"
                  *ngIf="formAttributesList.controls.length !== 1">
            <mat-icon aria-hidden="false">close</mat-icon>
          </button>
        </ng-container>
      </div>

      <div class="add-row-button" *ngIf="hasChild && !isRevision">
        <button class="add-group" (click)="addItem()" [disabled]="form.invalid">
          <mat-icon [ngClass]="{ 'disabled-icon': form.invalid }"
                    class="add-circle"
                    svgIcon="appSvg:add_circle_outline">
          </mat-icon>
          <span>Добавить атрибут</span>
        </button>
      </div>
    </div>

    <div class="revision">
      <mat-checkbox (change)="changeCheckbox($event.checked)"
                    [disabled]="fileType !== 'file' || data.filesCount > 1"
                    [checked]="!!data?.revisionNumber">
        Добавить ревизию
      </mat-checkbox>
      <div class="revision-select" *ngIf="isRevision">
        <mat-form-field class="select">
          <mat-select formControlName="revisionNumber">
            <mat-option *ngFor="let revision of revisionList" [value]="revision.id">
              {{ revision.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <section class="disable-share">
    <mat-checkbox [(ngModel)]="isShareView" [checked]="isShareView">Поделиться документом</mat-checkbox>
  </section>

  <app-share-form [incomingData]="data?.shared"
                  (shareList)="getShareList($event)"
                  *ngIf="isShareView"
                  class="share-form">
  </app-share-form>
  <div class="button">
    <app-button backgroundColor="white" (click)="!documentData ? openBeforeStep() : closeModal()">
      {{ !documentData ? 'Назад' : 'Закрыть' }}
    </app-button>
    <app-button class="save" type="submit" (click)="save()" *ngIf="!isShareView" [disabled]="form.valid && form.get('attributes').value.length < 2">
      Сохранить
    </app-button>
    <app-button class="save"
                type="submit"
                (click)="save()"
                className="huge"
                *ngIf="isShareView"
                [disabled]="!shareArray
                            || (!shareArray?.forView.length &&
                                !shareArray?.forCheck.length &&
                                !shareArray?.forApproval.length)
                            || form.valid && form.get('attributes').value.length < 2">
      Сохранить и поделиться
    </app-button>
  </div>
</div>
