import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConnectionService } from './services/connection.service';
import { LoginService } from './components/auth/services/login.service';
import { REGEX_GET_ID, REGEX_IS_AVAILABLE } from './common/constants';

@Component({
  selector: 'app-wb-driver',
  templateUrl: './wb-driver.component.html',
  styleUrls: ['./wb-driver.component.scss']
})
export class WbDriverComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();

  private isAvailable: RegExp = REGEX_IS_AVAILABLE;
  private getId: RegExp = REGEX_GET_ID;

  isLoggedIn = false;

  constructor(
    private connectionService: ConnectionService,
    private loginService: LoginService,
    private cdRef: ChangeDetectorRef,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.iconRegistry.addSvgIconSetInNamespace(
      'appSvg',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg_icons.svg')
    );

    this.iconRegistry.addSvgIcon(
      'arrow_drop_down',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_drop_down.svg')
    );

    this.iconRegistry.addSvgIcon(
      'close',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/close.svg')
    );

    this.loginService.isLoggedIn$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(login => {
        this.isLoggedIn = login;
        this.cdRef.markForCheck();
      });

    const url = new URL(window.location.href);

    if (this.isAvailable.test(url.pathname)) {
      const fileId = url.pathname.match(this.getId);
      localStorage.setItem('fileId', fileId ? fileId[0] : '');
      if (!!localStorage.getItem('fileId')) {
        this.router.navigate(['/available']);
      }
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
