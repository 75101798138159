import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  ViewChild,
} from '@angular/core';

import { ItemContext, ItemType, } from '../../../common/interfaces';
import { ContextPanelKeys, ContextPanelModel, } from '../context-panel';
import { ContextPanelService, } from '../../../services/context-panel.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileComponent implements AfterViewInit {
  enableTooltip = true;

  @Input() fileData: ItemContext;
  @Input() private contextPanelData: ContextPanelKeys[];

  @ViewChild('text', {static: true}) textSpan;

  constructor(
    private contextPanelService: ContextPanelService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    const text = this.textSpan.nativeElement;
    this.enableTooltip = text.offsetWidth < text.scrollWidth;
    this.cdRef.detectChanges();
  }

  get newIcon(): string {

    if (this.fileData.objType === 'file') {
      return this.fileData.fileType;
    } else if (this.fileData.objType === 'archive') {
      return this.fileData.fileType || this.fileData.fileType;
    }

    return 'full_folder';
  }

  openContextMenu(event: MouseEvent, item: ItemContext, type: ItemType): void {
    event.preventDefault();
    event.stopPropagation();

    const index = this.contextPanelData
      .findIndex(x => x === 'addTo' || x === 'unpackTo');

    this.contextPanelData[index] = type === 'archive' ? 'unpackTo' : 'addTo';

    const cfg = new ContextPanelModel(
      event, item, type, this.contextPanelData, 'mydoc'
    );

    this.contextPanelService.openContextMenu(cfg);
  }
}
