import { ChangeDetectorRef, Injector, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Overlay } from '@angular/cdk/overlay';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, Subject, BehaviorSubject, throwError } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ConnectionService } from '../services/connection.service';
import { WebsocketApiService } from '../services/websockets/websocket-api.service';
import { WS } from '../services/websockets/websocket.events';
import { FolderType, FileType, ContentData, DocumentData, } from '../common/interfaces';
import { ITags } from './tagsHelper';
import { DataModel } from '../models/data.model';

export abstract class DocumentsHelper implements OnDestroy {

  dataSubject$: BehaviorSubject<ContentData | boolean> = new BehaviorSubject<
    ContentData | boolean>({});
  loadFiles$: Subject<DocumentData> = new Subject<DocumentData>();
  createFolder$: Subject<DocumentData> = new Subject<DocumentData>();
  deleteItems$: Subject<DocumentData> = new Subject<DocumentData>();
  destroySubj$: Subject<boolean> = new Subject();

  overlay: Overlay;
  subscription: Subscription;
  cdRef: ChangeDetectorRef;

  connectionService: ConnectionService;
  wsService: WebsocketApiService;
  route: ActivatedRoute;
  router: Router;
  matDialog: MatDialog;

  folderId: string;
  directories: FolderType[] = [];
  files: FileType[] = [];

  myDocFlag = false;
  active = false;
  // infoFlag = false;

  protected constructor(private injector: Injector) {
    this.overlay = injector.get(Overlay);
    this.connectionService = injector.get(ConnectionService);
    this.wsService = injector.get(WebsocketApiService);
    this.route = injector.get(ActivatedRoute);
    this.router = injector.get(Router);
    this.cdRef = injector.get(ChangeDetectorRef);
    this.matDialog = injector.get(MatDialog);
  }

  ngOnDestroy(): void {
    this.destroySubj$.next();
    this.destroySubj$.complete();
  }

  /**
   * Получение данных
   * @param folderId - id папки в которой находимся
   * @param currentArea - myDoc
   * @param messageId - timestamp
   */
  getFoldersAndFiles(
    folderId: string,
    currentArea: string,
    messageId?: string
  ): void {
    this.wsService.send(WS.SEND.subscription, {
      action: '+',
      type: 'directory',
      id: folderId,
      area: currentArea,
    }, '2');
    this.wsService
      .send(WS.SEND.getDirectory, { id: folderId }, '2');
  }

  subscribeFoldersAndFiles(): void {
    this.wsService
      .on<ContentData>('2', null)
      .pipe(takeUntil(this.destroySubj$))
      .subscribe(
        (data) => {
          data = new DataModel(data);
          this.directories = !!data.directories ? [...data.directories] : [];
          this.files = !!data.files ? [...data.files] : [];

          this.dataSubject$.next(data);
        },
        (err) => throwError(err)
      );
  }

  /**
   * Получение директории после загрузки нового файла
   */
  addToDirectory(): void {
    this.wsService
      .on<ContentData>(null, WS.ON.addToDirectory)
      .pipe(takeUntil(this.destroySubj$))
      .subscribe(
        (data) => {
          let dataValue;

          data = new DataModel(data);

          if (data.files.length) {
            dataValue = data.files[0];

            this.files = [...this.files, data.files[0]];

            this.loadFiles$.next(
              {
                data: { id: dataValue.id, name: dataValue.name },
                type: 'add',
                isFile: true,
              });
          } else {
            dataValue = data.directories[0];

            this.directories = [...this.directories, data.directories[0]];

            this.createFolder$.next(
              {
                data: { id: dataValue.id, name: dataValue.name },
                type: 'add',
                isFile: false,
              });
          }
        },
        (err) => throwError(err)
      );
  }

  /**
   * Обновление страницы после удаления директории
   */
  deleteDirectory(): void {
    this.wsService
      .on<any>(null, WS.ON.deleteDirectory)
      .pipe(takeUntil(this.destroySubj$))
      .subscribe(
        (data) => {
          const dataValue = { ...data };

          data.type === 'file'
            ? this.files = this.files
              .filter((item) => item.id !== data.id)
            : this.directories = this.directories
              .filter((item) => item.id !== data.id);

          this.connectionService.setUpdatingTree({
            data: { id: dataValue.id, name: '' },
            type: 'delete',
          });

          this.deleteItems$.next({
            data: { id: dataValue.id, name: '' },
            type: 'delete',
          });
        },
        (err) => throwError(err)
      );
  }

  /**
   * Открыть закрыть меню с до данными о файлах
   * @param str - тип меню
   */
  openMenu(str: string): void {
    this.active = !this.active;
    this.myDocFlag = !this.myDocFlag;
  }

  /**
   * Получение тэгов документа или директории
   */
  setTagsArray(docs: (FileType | FolderType)[], data: (FileType | FolderType)[], tags: ITags[]): (FileType | FolderType)[] | void[] {
    if (data.length && docs.length) {
      return docs.map((item) => {
        if (data && item) {
          data.forEach(
            (x) => (item.isFavorite = data.find((doc) =>
              doc.id === item.id) ? !!x.isFavorite : item.isFavorite)
          );
          item.tags = data
            .find((x) => x.id === item.id) ? tags : item.tags;
        }
      });
    }
  }

  updatesForDocuments(): void {
    let dt;
    this.wsService
      .on<any>(null, WS.ON.updateFromMyDocuments)
      .pipe(
        takeUntil(this.destroySubj$),
        map((data) => dt = new DataModel(data))
        )
      .subscribe(
        (data: DataModel) => {
          if (data.files.length) {
            this.connectionService.setVersion(data.files[0]);
            data.files = this.setTagsArray(
              this.files,
              data.files,
              data.tags || []) as FileType[];
          } else {
            data.files = this.files;
          }

          data.directories = data.directories.length
              ? this.setTagsArray(
                this.directories,
                data.directories,
              data.tags || []) as FolderType[]
              : this.directories;

          this.dataSubject$.next(true);
          this.cdRef.markForCheck();
        },
        (err) => throwError(err)
      );
  }

}
