<form class="search" [formGroup]="searchForm" (ngSubmit)="filterChanged(searchForm.value.search)">
  <div class="blue-block">
    <div class="white-block">
      <div class="search-line">
        <mat-icon class="search-icon">search</mat-icon>
        <input type="search"
               #searchInput
               placeholder="Введите название документа"
               class="input-search"
               formControlName="search"/>
        <button type="submit" class="search-btn"><mat-icon>arrow_right_alt</mat-icon></button>
      </div>
      <div class="result-area overflow">
        <div *ngFor="let item of searchResult; let i = index"
             class="result-row"
             (click)="openResult(item)">
          <div class="result-name">{{ item.name }}</div>
          <div *ngIf="item?.hasOwnProperty('pathName')" class="path-name">
            {{ 'ИД/' + item?.pathName }}
          </div>
          <div *ngIf="item?.hasOwnProperty('attributes') && item?.area === 'ISD'">
            <ng-container *ngFor="let attribute of item?.attributes; let i = index">
              <div class="path-name">{{ attribute?.pathName }}</div>
            </ng-container>
          </div>
          <!-- *ngIf="item?.Area != 'ISD'" -->
          <div class="path-name">
            {{ setPath(item) }}
          </div>
        </div>
        <div class="loading not-found" *ngIf="!!isLoading">
          <mat-spinner class="spinner" [diameter]="25"></mat-spinner>
        </div>
        <div *ngIf="!isLoading && !!searchResult && !searchResult.length" class="not-found">
          По вашему запросу ничего не найдено
        </div>
      </div>
    </div>
    <div class="search-button">
      <button class="advanced-search-btn">
        <span>Расширенный поиск</span>
        <mat-icon>arrow_right_alt</mat-icon>
      </button>
    </div>
  </div>
</form>
