import { Injectable } from '@angular/core';

import { LocalstorageSetItemModel } from '../models/localstorage.model';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  public addToLocalStorage(...args: LocalstorageSetItemModel[]): void {
    args.forEach((item: LocalstorageSetItemModel) => localStorage.setItem(item.key, item.value));
  }

  public removeFromLocalStorage(...args: string[]): void {
    args.forEach((key: string) => localStorage.removeItem(key));
  }

}
