import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {WebsocketApiService} from 'src/app/services/websockets/websocket-api.service';
import {WS} from 'src/app/services/websockets/websocket.events';
import {ContentData, ItemContext} from 'src/app/common/interfaces';
import {takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {DataModel} from 'src/app/models/data.model';
import {Router} from '@angular/router';
import {SearchService} from './search.service';
import { Attribute } from '../../../../components/design-documentation/components/tree-design-doc/helpers/design-doc-interfaces';

enum Path {
  mydoc = 'Мои документы',
  available = 'Доступные',
  trash = 'Корзина',
  clipboard = 'Буфер обмена',
  archive = 'Архивы'
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, AfterViewInit {
  constructor(
    private fb: FormBuilder,
    private wsService: WebsocketApiService,
    private router: Router,
    private searchService: SearchService
  ) {}

  public searchForm: FormGroup;
  searchResult: (ItemContext | Attribute)[];
  observableResult = of([]);

  streamId = String(+new Date());
  destroySubj: Subject<boolean> = new Subject();
  isLoading = false;

  @Output() getSearchString: EventEmitter<string> = new EventEmitter<string>();
  @Input() searchString: string;

  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  ngOnInit(): void {
    this.initSearchForm();
    this.subscribeSearchResult();
    this.searchForm.get('search').valueChanges.subscribe((changeString) => this.getSearchString.emit(changeString));
  }

  ngAfterViewInit(): void {
    this.searchInput.nativeElement.focus();
  }

  initSearchForm(): void {
    this.searchForm = this.fb.group({
      search: [this.searchString && this.searchString !== 'Поиск по файлам' ? this.searchString : ''],
    });
  }

  setPath(item) {
    return Path[item.area];
  }

  filterChanged(searchValue) {
    if (searchValue) {
      this.isLoading = true;
      this.searchResult = [];
      // const tab = this.router.url.split('/').slice(1)[0];
      // const area =
      //   tab === 'documents'
      //     ? 'mydoc'
      //     : tab === 'designdoc'
      //     ? 'ISD'
      //     : tab === 'available'
      //     ? 'shared'
      //     : tab === 'trash'
      //     ? 'trash'
      //     : '';
      const docFilters = [];
      const attrFilters = [];
      this.wsService.send(
        WS.SEND.search,
        {
          name: searchValue,
          filters: { area: '', attributesIDs: attrFilters, documentTypeIDs: docFilters },
        },
        '',
        'open',
        this.streamId
      );
      this.getSearchString.emit(searchValue);
    }
  }

  subscribeSearchResult() {
    this.wsService
      .on<ContentData>(null, 'search', this.streamId)
      .pipe(takeUntil(this.destroySubj))
      .subscribe((data: ContentData) => {
        if (!!data && data.hasOwnProperty('type')) {
          if (data.type === 'file') {
            // if (data.files[0].hasOwnProperty('Area')) {
              this.searchResult.push(new DataModel(data).files[0]);
            // }
          } else if (data.type === 'directory') {
            // if(data.directories[0].hasOwnProperty('Area')) {
            this.searchResult.push(new DataModel(data).directories[0]);
            // }
          } else if (data.type === 'document') {
            this.searchResult.push((data as any).documentTypes[0]);
          } else if (data.type === 'archive') {
            this.searchResult.push((data as any).archives[0]);
          } else {
            this.searchResult.push(new DataModel(data).attributes[0]);
          }
          this.observableResult = of(this.searchResult);
        }
        this.isLoading = false;
      });
  }

  openResult(item: any) {
    this.searchService.openInNewTab(item);
  }
}
