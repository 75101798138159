<div *ngIf="data"
     class="tooltip {{boxShadow}}"
     [style.minWidth]="'108px'"
     [style.minHeight]="'62px'"
     role="tooltip"
     #tooltip>
  <div class="tooltip-arrow"></div>
  <div id="tooltip-inner"
       class="tooltip-inner"
       [style.background]="background"
       [style.color]="color">
    <ng-container *ngFor="let item of data">
      <div class="tag-name">
        <span *ngIf="item.hasOwnProperty('color')" class="dot" [ngStyle]="{background: item.color}"></span>
        {{item.name}}
      </div>
    </ng-container>
  </div>
</div>
