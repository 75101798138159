<table class="table mat-elevation-z8"
       [dataSource]="dataSource"
       mat-table
       matSort>
  <ng-container [matColumnDef]="column.key"
                *ngFor="let column of displayedColumns">
    <th class="table__column"
        [class.table__column--name]="column.type === 'image'"
        [class.table__column--owner]="column.type === 'owner'"
        *matHeaderCellDef
        [disabled]="!column.isSort"
        mat-header-cell
        mat-sort-header>
      {{ column.name }}
      <ng-container *ngIf="column.isFilter">
        <mat-form-field>
          <mat-select [formControl]="checkList"
                      [multiple]="true" #openselect
                      (openedChange)="filterTable($event)">
            <ngx-mat-select-search [formControl]="multiFilterCtrl"
                                   noEntriesFoundLabel="Ничего не найдено"
                                   placeholderLabel="Search">
            </ngx-mat-select-search>
            <mat-option *ngFor="let item of filteredItems$ | async"
                        [value]="item">
              {{ item }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button
                (click)="$event.stopPropagation();
                openFilterMenu(column.key, openselect)">
          <mat-icon>filter_list</mat-icon>
        </button>
      </ng-container>
    </th>
    <td class="table__column"
        [class.table__column--name]="column.type === 'image'"
        [class.table__column--owner]="column.type === 'owner'"
        *matCellDef="let element"
        (click)="openContextMenu($event, element, element.objType)"
        mat-cell>
      <ng-container [ngSwitch]="column.type">
        <ng-container *ngSwitchCase="'image'">
          <ng-container *ngIf="element.type === 'loading'">
            <div class="table__name-cell">
              <div class="table__loading-file">
                <mat-spinner class="table__spinner" [diameter]="20"></mat-spinner>
              </div>
              <span class="table__file-title">{{ element[column.key] }}</span>
            </div>
          </ng-container>
          <ng-container *ngIf="element?.objType === 'file'">
            <div class="table__name-cell">
              <mat-icon class="table__icon"
                        [svgIcon]="'appSvg:' + element?.fileType + '-small'">
              </mat-icon>
              <span class="table__file-title">{{ element[column.key] }}</span>
            </div>
          </ng-container>
          <div *ngIf="element?.objType === 'archive'">
            <div class="table__name-cell">
              <mat-icon class="table__icon" [svgIcon]="'appSvg:archive-small-b'">
              </mat-icon>
              <span class="table__file-title">{{ element[column.key] }}</span>
            </div>
          </div>
          <div *ngIf="element?.objType === 'directory'"
               (click)="openContextMenu($event, element, element.objType)">
            <div class="table__name-cell">
              <mat-icon class="table__icon" id="file" [svgIcon]="'appSvg:folder'">
              </mat-icon>
              <span class="table__file-title">{{ element[column.key] }}</span>
            </div>
          </div>
        </ng-container>
        <div *ngSwitchCase="'edition'">
          <div *ngIf="element[column.key]?.length">
            Ревизия {{
            element[column.key][(element[column.key]?.length - 1)]["number"]
            }}
          </div>
        </div>
        <div *ngSwitchCase="'date'">
          <ng-container *ngIf="element['versions']?.length">
            {{ timeConversion(
            element["versions"][(element['versions']?.length - 1)]["timestamp"]
          ) | date: 'dd.MM.yyyy' }}
          </ng-container>
        </div>
        <div *ngSwitchCase="'owner'">
          {{ element?.type !== 'loading' ? (element[column.key]['firstname'] +
          ' ' + element[column.key]['lastname']) : '' }}
        </div>
        <div *ngSwitchCase="'tags'" class="table__tags">
          <app-tags-tooltip [tags]="element[column.key]">
          </app-tags-tooltip>
          <mat-icon class="table__icon-star"
                    *ngIf="element?.isFavorite"
                    [svgIcon]="'appSvg:star_fill'">
          </mat-icon>
        </div>
      </ng-container>
    </td>
  </ng-container>

  <tr
    class="table__row"
    *matHeaderRowDef="columnsToDisplay; sticky: true"
    mat-header-row>
  </tr>
  <tr class="table__row table__row--body"
      *matRowDef="let row; columns: columnsToDisplay"
      mat-row>
  </tr>
</table>
