import { Pipe, PipeTransform } from '@angular/core';
import { ItemContextArrays } from '../common/interfaces';

@Pipe({
  name: 'sortByDate'
})
export class SortByDatePipe implements PipeTransform {

  transform(dataList: ItemContextArrays[], criteria: boolean): ItemContextArrays[] {
    return dataList.sort((a: ItemContextArrays, b: ItemContextArrays) =>
      criteria ? b[0].timestamp.created - a[0].timestamp.created : a[0].timestamp.created - b[0].timestamp.created);
  }

}
