import {Component, EventEmitter, Output} from '@angular/core';
import { HEADINGS } from '../../../../common/constants';

@Component({
  selector: 'app-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss']
})
export class SidebarMenuComponent {
  routes = HEADINGS;

  @Output() closeSidebar = new EventEmitter();

  constructor() { }

  closeMenu() {
    this.closeSidebar.emit();
  }
}
