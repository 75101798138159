import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ItemContext } from 'src/app/common/interfaces';

@Injectable({ providedIn: 'root' })
export class InfoMenuService {
  private infoMenu = false;
  private area = '';

  private infoItem = new BehaviorSubject<any>({});
  infoItem$ = this.infoItem.asObservable();

  constructor() {}

  openMenu(item: ItemContext, area: string) {
    this.infoItem.next(item);
    this.infoMenu = true;
    this.area = area;
  }

  closeMenu() {
    this.infoMenu = false;
  }

  getInfoMenuValue() {
    return {infoMenu: this.infoMenu, area: this.area};
  }
}
