import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { ContextPanelKeys } from '../context-panel';
import { FileType, ItemContextArrays } from '../../../common/interfaces';
import { ConnectionService } from '../../../services/connection.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-files-container',
  templateUrl: './files-container.component.html',
  styleUrls: ['./files-container.component.scss']
})
export class FilesContainerComponent implements OnInit, OnDestroy {

  destroySubj$: Subject<boolean> = new Subject();

  @Input() files: ItemContextArrays;
  @Input() contextPanelData: ContextPanelKeys[];

  constructor(private connectionService: ConnectionService) {}

  ngOnInit() {
    this.connectionService.favorite$
      .pipe(takeUntil(this.destroySubj$))
      .subscribe((item) =>
        this.changeFavorite(item.data.id, item.favorite));
  }

  ngOnDestroy(): void {
    this.destroySubj$.next();
    this.destroySubj$.complete();
  }

  changeFavorite(id, stat) {
    this.files = this.files.map((item: FileType) => {
      if (item.id === id) {
        item.isFavorite = stat;
      }
      return {...item};
    });
  }
}
