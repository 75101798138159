import { InjectionToken } from '@angular/core';

export const TAGS = [
  { name: 'Объект', color: 'orange' },
  { name: 'Секция', color: 'yellow' },
  { name: 'Подобъект', color: 'light-green' },
  { name: 'Система', color: 'green' },
  { name: 'Структура', color: 'blue' },
];

export const HEADINGS = [
  { title: 'Мои документы', route: 'documents/root' },
  { title: 'Исполнительные документы', route: 'designdoc' },
  { title: 'Редактор', route: 'editor' },
  { title: 'Доступные', route: 'available' },
  { title: 'Недавние', route: 'recent' },
  { title: 'Буфер обмена', route: 'clipboard' },
  { title: 'Архивы', route: 'archives' },
  { title: 'Корзина', route: 'trash' },
];

export const REGEX_FOLDER_ID = /[^\/documents\/](.*)$/g;
export const REGEX_IS_AVAILABLE = /\bavailable\b/;
export const REGEX_GET_ID = /[^/a-zA-Z=|.]([a-z0-9_-]{17,24}[\/]?)$/;
export const REMOVE_FILE_EXTENSION = /\.[^/.]+$/;
export const REMOVE_TEXT_WITH_BRACKETS = /\([^)]*\)/gm;
export const REGEX_START_WITH_NUMBER = /^\d+/;

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

export const ONBOARDING_MYDOC = [
  {
    slideName: 'Общий вид',
    imgURL: '../../../../assets/imgs/onboarding/myDoc/onboarding_myDoc_img1.png',
    slideText: `Так выглядит вкладка "Мои документы", в которой уже лежат файлы, папки, что-то
                  добавлено в "Избранное", созданы две пометки (TAGs), а также добавлен файл в
                  буфер обмена.`,
  },
  {
    slideName: 'Загрузка документа/создание папки',
    imgURL: '../../../../assets/imgs/onboarding/myDoc/onboarding_myDoc_img2.png',
    slideText: `Кнопка "Загрузить" открывает выпадающее меню, где Вы можете выбрать:
                  загрузить файл или создать папку.`,
  },
  {
    slideName: 'Действия с документом',
    imgURL: '../../../../assets/imgs/onboarding/myDoc/onboarding_myDoc_img3.png',
    slideText: `Нажатие левой кнопкой мыши на файл (документ) вызывает меню, с помощью которого Вы можете:

                  - посмотреть документ в браузере;
                  - скачать документ на свое устройство;
                  - внести изменения;
                  - поделиться с помощью e-mail с другими пользователями WB Drive;
                  - присвоить TAG;
                  - добавить в "Буфер обмена", в "Избранное" или в "Исполнительные документы";
                  - посмотреть свойства;
                  - удалить документ.`,
  },
  {
    slideName: 'Способы отображения документов',
    imgURL: '../../../../assets/imgs/onboarding/myDoc/onboarding_myDoc_img4.png',
    slideText: `Мы предусмотрели несколько способов отображения документов. Вы можете выбрать
                  а) табличный или плиточный;
                  б) затем подключить двойной вид, чтобы увидеть больше возможностей приложения.`,
  },
  {
    slideName: 'Работа с TAGs',
    imgURL: '../../../../assets/imgs/onboarding/myDoc/onboarding_myDoc_img5.png',
    slideText: `TAGs - система пометок-стикеров, которые Вы можете присваивать своим документам и
                  папкам для быстрой группировки и наглядности. Гибкость системы пометок позволяет добавлять,
                  изменять и удалять их в процессе работы множество раз.`,
  },
];

export const ONBOARDING_DESIGNDOC = [
  {
    slideName: 'Общий вид',
    imgURL: '../../../../assets/imgs/onboarding/designDoc/onboarding_desDoc_img1.png',
    slideText: `Так выглядят "Исполнительные документы". Вкладка организована по принципу дерева,
                которое строится на основе определенных атрибутов.`,
  },
  {
    slideName: 'Загрузка документа в ИД',
    imgURL: '../../../../assets/imgs/onboarding/designDoc/onboarding_desDoc_img2.png',
    slideText: `Добавить документ в "Исполнительные документы" с Вашего компьютера Вы
                можете с помощью кнопки "Загрузить". Вам останется только заполнить нужные
                поля, выбрать атрибуты для отнесения документа к какой-либо части дерева и
                сохранить результат.`,
  },
  {
    slideName: 'Поиск документа в ИД',
    imgURL: '../../../../assets/imgs/onboarding/designDoc/onboarding_desDoc_img3.png',
    slideText: `Вы можете воспользоваться поиском по "Исполнительным документам" с
                помощью кнопки-лупы. Чтобы искать, введите часть названия документа или
                атрибута, на уровне которого документ может находиться в дереве. Результаты
                быстрого поиска затем можете использовать как фильтр.`,
  },
  {
    slideName: 'Действия с документами внутри ИД',
    imgURL: '../../../../assets/imgs/onboarding/designDoc/onboarding_desDoc_img4.png',
    slideText: `Для быстрого и комфортного старта работы с нашим приложением мы поместили
                в дерево "Исполнительных документов" видеоинструкцию. Скачайте ее на Ваш
                компьютер и посмотрите, как работать с деревом атрибутов, а также, как искать по
                нему и делиться файлами с другими пользователями WB Drive.`,
  },
];
