import { Component, Input } from '@angular/core';
import { ITags } from '../../../../helpers/tagsHelper';

@Component({
  selector: 'app-tags-tooltip',
  templateUrl: './tags-tooltip.component.html',
  styleUrls: ['./tags-tooltip.component.scss']
})
export class TagsTooltipComponent {
  @Input() tags: ITags[] = [];
}
