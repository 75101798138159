import { Component, OnInit, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { REGEX_FOLDER_ID } from '../../../common/constants';
import { ContentData, ItemContext, ItemType } from '../../../common/interfaces';
import { ScrollHelper } from '../../../helpers/scrollHelper';
import { TabMenuService } from '../../../services/tab-menu.service';
import { ContextPanelService } from '../../../services/context-panel.service';
import { WS } from '../../../services/websockets/websocket.events';
import { WindowResizeService } from '../../../services/window-resize.service';
import { WebsocketApiService } from '../../../services/websockets/websocket-api.service';
import { ContextPanelModel, ContextPanelKeys } from '../context-panel/context-panel.model';

@Component({
  selector: 'app-doc-menu',
  templateUrl: './doc-menu.component.html',
  styleUrls: ['./doc-menu.component.scss'],
})
export class DocMenuComponent extends ScrollHelper implements OnInit, OnDestroy {
  tabMenu = true;
  private previousDirId: string;

  favoritesData: any[] = [];

  contextPanelData: ContextPanelKeys[] = ['open', 'download', 'print', 'share', 'addTo', 'info', 'success'];

  mousedownAction: Subject<boolean> = new Subject();

  constructor(
    injector: Injector,
    private tabMenuService: TabMenuService,
    private contextPanelService: ContextPanelService,
    private windowResizeService: WindowResizeService,
    private wsService: WebsocketApiService,
    private router: Router
  ) {
    super(injector);
  }

  ngOnInit() {
    this.tabMenu = this.tabMenuService.getTabMenuValue();

    this.wsService.send(WS.SEND.subscription, {
      action: '+',
      type: 'fvrt',
      area: 'mydoc',
    }, '2');
    this.getAllFavorites();
    this.getNewFavorite();
    this.removeFavorite();

    this.subscribeInOnInit();

    this.unsubscribe();
    this.cdRef.markForCheck();

    this.windowResizeService
      .getWinResize()
      .pipe(takeUntil(this.destroySubj))
      .subscribe((_) => {
        this.resetScroll();
      });
  }

  ngOnDestroy() {
    this.destroySubj.next(false);
  }

  getAllFavorites() {
    const messageId = String(+new Date());
    this.wsService.send(WS.SEND.getAllFavorites, {}, messageId);
    this.wsService
      .on<ContentData>(messageId, null)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (data) => {
          if (data.directories) {
            data.directories.map((x) => (x.objType = 'folder'));
          }
          if (data.files) {
            const regex = /(.zip)$/;
            data.files.map((x) => (x.objType = regex.test(x.name) ? 'archive' : 'file'));
          }
          this.favoritesData =
            data.directories || data.files ? (data.directories || []).concat((data.files as any) || []) : [];
          this.dataSubject.next(data);
        },
        (err) => throwError(err)
      );
  }

  getNewFavorite() {
    this.wsService
      .on<ContentData>(null, WS.ON.addToFavorites)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (data) => {
          data.directories ? data.directories[0].objType = 'folder' : data.files[0].objType = 'file';
          this.favoritesData = [(data.directories || data.files)[0], ...this.favoritesData];
          this.dataSubject.next(data);
        },
        (err) => throwError(err)
      );
  }

  removeFavorite() {
    this.wsService
      .on<any>(null, WS.ON.removeFromFavorites)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (data) => {
          this.favoritesData = this.favoritesData.filter((doc) => doc.id !== data.id);
          this.dataSubject.next(data);
        },
        (err) => throwError(err)
      );
  }

  openFolder() {
    this.unsubscribe();
    // TODO: fix error "ngIf: Expression has changed after it was checked"
  }

  unsubscribe() {
    this.previousDirId = this.router.url.match(REGEX_FOLDER_ID) ? this.router.url.match(REGEX_FOLDER_ID)[0] : '';
    this.wsService.send(WS.SEND.subscription, {
      action: '-',
      type: 'directory',
      id: this.previousDirId,
      area: 'mydoc',
    }, String(+new Date()));
  }

  openContextMenu(event: MouseEvent, item: ItemContext, type: ItemType) {
    event.preventDefault();
    event.stopPropagation();
    const cfg = new ContextPanelModel(event, item, type, this.contextPanelData, 'mydoc');
    this.contextPanelService.openContextMenu(cfg);
  }

  /**
   * Скрыть/показать меню с директориями
   */
  tabMenuShow() {
    this.tabMenu = !this.tabMenu;
    this.tabMenuService.tabMenuToggle();
  }
}
