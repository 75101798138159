import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

import { ITableCfg, ContextPanelKeys } from '../../index';
import { ConnectionService } from '../../../services/connection.service';
import { ItemContext, LoadingDoc } from '../../../common/interfaces';

@Component({
  selector: 'app-doc-content-table',
  templateUrl: './doc-content-table.component.html',
  styleUrls: ['./doc-content-table.component.scss'],
})
export class DocContentTableComponent {
  @Input() dataListForTable: (ItemContext | LoadingDoc)[];

  /**
   * cfg для табличного вида
   */
  displayedColumns: ITableCfg[] = [
    { key: 'name', name: 'Название', type: 'image', isSort: true, isFilter: false },
    { key: 'tags', name: 'TAGs', type: 'tags', isSort: false, isFilter: false },
    { key: 'id', name: 'Статус', type: 'string', isSort: false, isFilter: false },
    { key: 'versions', name: 'Ревизия', type: 'edition', isSort: true, isFilter: false },
    { key: 'timestamp', name: 'Изменен', type: 'date', isSort: true, isFilter: false },
    { key: 'owner', name: 'Создатель', type: 'owner', isSort: true, isFilter: false }
  ];

  @Input() contextPanelData: ContextPanelKeys[];

  constructor(
    private router: Router,
    private connectionService: ConnectionService
  ) {}

  openFolder(folder) {
    this.router.navigate(['documents', folder.id]);
    this.connectionService.setFolder(folder);
  }

}
