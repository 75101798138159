import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TooltipComponent implements AfterViewInit  {
  @Input() background;
  @Input() color;
  @Input() data;
  @Input() boxShadow;

  @Output() tooltipParams = new EventEmitter();

  @ViewChild('tooltip', { static: false }) tooltip;

  ngAfterViewInit() {
    this.tooltipParams.emit({width: '12px', height: '12px'});
  }
}
