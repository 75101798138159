<div class="container">
  <div class="logo">
    <mat-icon id="logo-min" [svgIcon]="'appSvg:logo-min'"></mat-icon>
  </div>
  <div class="text">
    <span>
      Документ с таким названием уже существует. Выберите, что необходимо сделать:
    </span>
  </div>
  <div class="replace_file block" [ngClass]="{ target: true }">
    <mat-radio-button class="radio_btn" value="replace">
      <span class="button_text">
        Заменить на новый
      </span>
    </mat-radio-button>
    <div class="description">
      <div class="label">
        <mat-icon id="icon" [svgIcon]="'appSvg:pdf_tiny'"></mat-icon>
      </div>
      <span class="replace_name text_description">
        Проекция на подвижные оси, как следует из системы уравнений
      </span>
      <span class="new">(Новый)</span>
    </div>
  </div>
  <div class="leave_file block" [ngClass]="{ target: false }">
    <mat-radio-button class="radio_btn" value="leave">
      <span class="button_text">
        Оставить оба файла
      </span>
    </mat-radio-button>
    <div class="description">
      <span class="text_description">
        Новый файл получит новое имя “Проекция на подвижные оси, как следует из системы уравнений (1)”
      </span>
    </div>
  </div>
  <div class="button">
    <app-button (click)="accept()" size="small">Ок</app-button>
  </div>
  <div class="button">
    <app-button (click)="close()" backgroundColor="white">Отменить</app-button>
  </div>
</div>
