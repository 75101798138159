import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { UserDataModel } from 'src/app/components/auth/models/user-data.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private readonly LOG_IN_STATUS = !!localStorage.getItem('isLoggedIn');

  public isLoggedIn$ = new BehaviorSubject<boolean>(this.LOG_IN_STATUS);

  constructor(private http: HttpClient) {}

  public login(userData: string): Observable<UserDataModel> {
    return this.http.post<UserDataModel>(environment.urlUpload + 'login', userData);
  }

}
