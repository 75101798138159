import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SharedFileResolver } from './resolvers/shared-file.resolver';
import { AttributeResolver } from './resolvers/attribute.resolver';
const routes: Routes = [
  { path: '', redirectTo: 'documents', pathMatch: 'full' },
  {
    path: 'documents',
    loadChildren: () => import('./components/documents/my-doc.module')
      .then((module) => module.MyDocModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'available',
    loadChildren: () =>
      import('./components/available-files/available-files.module')
        .then((module) =>
          module.AvailableFilesModule),
    canActivate: [AuthGuard],
    resolve: { file: SharedFileResolver },
  },
  {
    path: 'recent',
    loadChildren: () =>
      import('./components/recent-files/recent-files.module')
        .then((module) =>
          module.RecentFilesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'trash',
    loadChildren: () => import('./components/trash-files/trash-files.module')
      .then((module) =>
        module.TrashFilesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'clipboard',
    loadChildren: () => import('./components/buffer/buffer.module')
      .then((module) => module.BufferModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'archives',
    loadChildren: () => import('./components/archives/archives.module')
      .then((module) =>
        module.ArchivesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'designdoc',
    loadChildren: () =>
      import('./components/design-documentation/design-documentation.module')
        .then((module) =>
          module.DesignDocumentationModule),
    canActivate: [AuthGuard],
    resolve: { attribute: AttributeResolver },
  },
  {
    path: 'auth',
    loadChildren: () => import('./components/auth/auth.module')
      .then((module) => module.AuthModule),
  },
  {
    path: 'editor',
    loadChildren: () => import('./components/wbd-editor/wbd-editor.module')
      .then((module) =>
        module.WbdEditorModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'advanced-search',
    loadChildren: () =>
      import('./components/advanced-search/advanced-search.module')
        .then((module) =>
          module.AdvancedSearchModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'documents' }
];

@NgModule({
  imports: [
    RouterModule
      .forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: [AuthGuard, SharedFileResolver, AttributeResolver],
})
export class RoutingModule {}
