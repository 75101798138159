import { Component, OnInit, OnDestroy, AfterViewInit, Injector } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { HEADINGS } from '../../../common/constants';
import { IUser } from '../../../common/interfaces';
import { ScrollHelper } from '../../../helpers/scrollHelper';
import { WS } from '../../../services/websockets/websocket.events';
import { WindowResizeService } from '../../../services/window-resize.service';
import { WebsocketApiService } from '../../../services/websockets/websocket-api.service';
import { ConnectionService } from '../../../services/connection.service';
import { DataModel } from '../../../models/data.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends ScrollHelper implements OnInit, AfterViewInit, OnDestroy {
  initDocMenu: boolean;
  headings = HEADINGS;
  userData: IUser;
  initials = '';
  destroySubj: Subject<boolean> = new Subject();
  currentOrganization: any;
  allOrganizations: any[];
  isSearchView = false;

  isCheckNipi = false;

  btnName = 'Поиск по файлам';

  constructor(
    private location: Location,
    private windowResizeService: WindowResizeService,
    injector: Injector,
    private wsService: WebsocketApiService,
    private router: Router,
    private connectionService: ConnectionService,
    private matDialog: MatDialog
  ) {
    super(injector);
  }

  ngOnInit() {
    const messageId = String(+new Date());
    const userId = localStorage.getItem('userId');
    this.wsService.send(WS.SEND.user, { id: userId }, messageId);
    this.wsService
      .on(messageId, null)
      .pipe(takeUntil(this.destroySubj))
      .subscribe((data: any) => {
        // this.allOrganizations = data.organizations;
        // this.currentOrganization = data.organizations.find(x => x.id === data.currentOrganization);
        this.userData = new DataModel(data).users[0];
        if (this.userData) {
          this.initials = this.userData.firstname.charAt(0) + this.userData.lastname.charAt(0);
        }
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroySubj)
      )
      .subscribe((res: NavigationEnd) => {
        this.checkRoute();
      });

    this.windowResizeService
      .getWinResize()
      .pipe(takeUntil(this.destroySubj))
      .subscribe((_) => {
        this.resetScroll();
      });
  }

  ngAfterViewInit(): void {
    this.checkRoute();
    this.cdRef.detectChanges();

    // TODO: тут костыль, надо исправить
    setTimeout(() => {
      this.resetScroll();
    }, 300);
  }

  ngOnDestroy(): void {
    this.destroySubj.next(false);
  }

  openTab(link) {
    if (link === 'documents/root') {
      this.connectionService.setFolderIds('');
    }
    this.btnName = 'Поиск по файлам';
    // TODO: добавить отписку от предыдущего tab-а после перехода
  }

  checkRoute() {
    // !this.location.isCurrentPathEqualTo('/archives')
    this.initDocMenu = this.router.url.indexOf('/documents') !== -1 ||
      this.router.url.indexOf('/available') !== -1 || this.router.url.indexOf('/recent') !== -1;

    this.connectionService.isRootFolder(this.router.url === '/documents/root');
  }

  /**
   * Окно с оповещениями
   */
  openNotification() {
    this.matDialog.open(NotificationsComponent, {
      backdropClass: 'custom-dialog-backdrop-class',
      height: '419px',
      width: '185px',
      panelClass: 'notification_menu',
      position: {
        top: '25px',
        right: '100px',
      },
    });
  }

  /**
   * Окно пользователя
   */
  openUserMenu() {
    this.matDialog.open(UserMenuComponent, {
      backdropClass: 'custom-dialog-backdrop-class',
      height: '357px',
      width: '224px',
      panelClass: 'user_menu',
      position: {
        top: '40px',
        right: '10px',
      },
      data: this.userData,
    });
  }

  check(orgId: string) {
    const regex = /\bdesigndoc\b/;
    this.currentOrganization = this.allOrganizations.find((x) => x.id === orgId);
    this.wsService.send(WS.SEND.changeOrganization, { organizationID: orgId }, String(+new Date()));
    if (regex.test(this.router.url)) {
      location.assign(this.router.url);
    }
  }

  openSearch() {
    this.isSearchView = true;
  }

  closeSearch() {
    this.isSearchView = false;
  }

  getSearchString(searchString) {
    this.btnName = !!searchString ? searchString : 'Поиск по файлам';
  }
}
