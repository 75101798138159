import { Pipe, PipeTransform } from '@angular/core';
import { ItemContextArrays } from '../common/interfaces';

@Pipe({
  name: 'sortByTitle',
  pure: false
})
export class SortByTitlePipe implements PipeTransform {

  transform(files: ItemContextArrays, flag: boolean, oldFiles?: ItemContextArrays, whereUse?: string): ItemContextArrays {
    if (!flag && whereUse === 'recent') { return oldFiles; }

    if (!flag) { return files; }

    if (flag) {
      return files.sort( (a, b) => {
        const first = a.name.trim().toLocaleLowerCase();
        const second = b.name.trim().toLocaleLowerCase();

        if (first > second) {
          return 1;
        }

        if (first < second) {
          return -1;
        }

        return 0;
      });
    }
  }

}
