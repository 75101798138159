import {
  ChangeDetectionStrategy,
  EventEmitter,
  Component,
  Input,
  Output,
  OnChanges,
  OnInit,
  OnDestroy,
  ViewChild
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { MatSort } from '@angular/material/sort';

import { ITableCfg } from './table-cfg';
import { ContextPanelKeys, ContextPanelModel } from '../context-panel';
import { ItemContext, ItemType, LoadingDoc } from '../../../common/interfaces';
import { ContextPanelService } from '../../../services/context-panel.service';
import { timeConversion } from '../../../utils/time-conversion';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnChanges, OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  filteredItems$: ReplaySubject<(string | number)[]> =
    new ReplaySubject<(string | number)[]>(1);

  columnsToDisplay: string[] = [];
  selectList: Set<string | number> = new Set([]);
  dataSource: MatTableDataSource<ItemContext | LoadingDoc>;
  toppings = new FormControl();
  checkList: FormControl = new FormControl();
  multiFilterCtrl: FormControl = new FormControl();
  timeConversion = timeConversion;

  @Input() dataList: (ItemContext | LoadingDoc)[];
  @Input() displayedColumns: ITableCfg[];
  @Input() contextPanelData: ContextPanelKeys[];
  @Input() area = '';
  @Input() isOpenFolder = false;

  @Output() openDirectory = new EventEmitter<any>();

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private contextPanelService: ContextPanelService) {}

  ngOnChanges(): void {
    this.columnsToDisplay = this.displayedColumns.map(item => item.key);

    this.dataSource = new MatTableDataSource(this.dataList.length
      ? this.dataList : []);

    this.dataSource.sort = this.sort;

    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'versions':
          if ((item as ItemContext).versions) {
            return (item as ItemContext)
              .versions[(item as ItemContext).versions.length - 1].number;
          }
          break;

        case 'timestamp': return timeConversion(item.timestamp.created);

        default: return item[property];
      }
    };
  }

  ngOnInit(): void {
    this.multiFilterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.filterItems());
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Фильтр
   */
  protected filterItems(): void {
    if (!this.selectList) {
      return;
    }
    // get the search keyword
    let search = this.multiFilterCtrl.value;
    if (!search) {
      this.filteredItems$.next([...this.selectList]);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the item
    this.filteredItems$.next(
      [...this.selectList].filter(item =>
        item
          ? item
            .toString()
            .toLowerCase()
            .indexOf(search) > -1
          : false
      )
    );
  }

  /**
   * Функция, вызываемая при открытии селекта, создание списка для селекта и фильтра
   * @param col - key для колонки
   * @param openSelect - MatSelect
   */
  openFilterMenu(col: string, openSelect: MatSelect): void {
    this.selectList = new Set([]);

    this.selectList = new Set(this.dataList.map(item => item[col]));

    this.filteredItems$.next([...this.selectList]);

    setTimeout(() => {
      openSelect.open();
    });
  }

  /**
   * Функция, вызываемая после закрытия селекта
   * @param isOpen - типа boolean
   */
  filterTable(isOpen: boolean): void {
    if (!isOpen) {
      // console.log(this.checkList.value, 'this.checkList.value');
    }
  }

  openContextMenu(event: MouseEvent, item: ItemContext, type: ItemType): void {
    event.preventDefault();
    event.stopPropagation();

    if (type !== 'loading') {
      const index = this.contextPanelData
        .findIndex(x => x === 'addTo' || x === 'unpackTo');
      this.contextPanelData[index] = type === 'archive' ? 'unpackTo' : 'addTo';
      const cfg = new ContextPanelModel(
        event,
        item,
        type,
        this.contextPanelData,
        'mydoc'
      );
      this.contextPanelService.openContextMenu(cfg);
    }
  }

  // openFolder(element) {
  //   if (this.isOpenFolder) {
  //     this.openDirectory.emit(element);
  //   }
  // }
}
