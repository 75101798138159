<form [formGroup]="form" class="content">
  <div class="form">
    <div class="select-file">Выбор файла</div>
    <div class="add-document">
      <!-- <lib-wbd-input type="text" [(ngModel)]="docName" [disable]='true'> </lib-wbd-input> -->
      <div class="name-file">
        <div class="text-name">{{ docName }}</div>
        <button mat-icon-button class="load_btn" (click)="fileInput.click()" *ngIf="!this.itemName">
          <mat-icon class="load_file_icon icon" [svgIcon]="'appSvg:round_add'"></mat-icon>
          <input #fileInput type="file" (change)="openFile($event)" multiple/>
        </button>
      </div>
    </div>

    <ng-container *ngIf="fileType === 'file'">
      <div class="section-name">Тип документа</div>
      <div class="type-doc">
        <mat-form-field class="type-select">
          <mat-select formControlName="docType" class="not-multiple-menu">
            <mat-option *ngFor="let type of typeList" [value]="type.id">
              <mat-icon class="type_file_icon icon"
                        [svgIcon]="'appSvg:' + type.short + '_rus'">
              </mat-icon>
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </ng-container>

    <div class="section-name">Описание файла</div>
    <div class="set-name">
      <mat-form-field class="form-field">
        <input matInput formControlName="description" />
      </mat-form-field>
      <!-- <input type="text" formControlName="description"> -->
      <!-- <lib-wbd-input type="text" formControlName="description"></lib-wbd-input> -->
    </div>
  </div>
  <div class="button">
    <app-button class="cancel" backgroundColor="white" (click)="closeModal()">Отмена</app-button>
    <app-button class="save" type="submit" (click)="openNextStep()" [disabled]="form.invalid">Далее</app-button>
  </div>
</form>
