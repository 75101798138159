import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class SearchService {
  constructor(private router: Router) {}

  openInNewTab(item) {
    let url: string;
     if (item.area === 'mydoc') {
      const rootId = item.rootDirectoryID ? item.rootDirectoryID : 'root'
      url = this.router.serializeUrl(this.router.createUrlTree(['/documents/' + rootId, { fileId: item.id }]));
    } else if (item.area === 'ISD') {
      if (!item.hasOwnProperty('attributes') && item.type.id) {
        const rootId = item.path.split('/').slice(1)[0];
        url = this.router.serializeUrl(
          this.router.createUrlTree([
            '/designdoc/' + rootId,
            item.path.split('/').slice(1).length !== 1 ? { attrPath: item.path, fileId: item.id } : {},
          ])
        );
      } else {
        const rootId = item.attributes[0].path.split('/').slice(1)[0];
        url = this.router.serializeUrl(
          this.router.createUrlTree(['/designdoc/' + rootId, { attrPath: item.attributes[0].path, fileId: item.id }])
        );
      }
    } else if (item.area === 'trash') {
      url = this.router.serializeUrl(this.router.createUrlTree(['/trash', { fileId: item.id }]));
    }
    else if (item.area === 'archive') {
      url = this.router.serializeUrl(this.router.createUrlTree(['/archives', { fileId: item.id }]));
    }

    window.open(url, '_blank');
  }
}
