import { Injectable } from '@angular/core';

import { ArchiveType, FileType, FolderType } from '../common/interfaces';
import { timeConversion } from '../utils/time-conversion';

type FilterType = 'created' | 'updated' | 'removed' | 'shared';

@Injectable({ providedIn: 'root' })
export class DocContentService {

  /**
   * Отображение файлов по времени создания от ранних к поздним
   * @param files - массив файлов
   * @param directories - массив директорий
   * @param type - type of filter
   */
  public filterDataTime(files: FileType[] | ArchiveType[], directories: FolderType[], type: FilterType): any[] {
    const documents: (FolderType | FileType | ArchiveType)[] = files && directories ? [...files, ...directories] : [];
    return this.creatingDataList(this.timeFilter(documents, type), type);
  }

  public filterDataTimeForTable(files: FileType[], directories: FolderType[], type: FilterType): any[] {
    const documents: (FolderType | FileType | ArchiveType)[] = files && directories ? [...files, ...directories] : [];
    return this.timeFilter(documents, type);
  }

  /**
   * Фильтр данных по дням
   * @param documents - массив файлов и деректорий из корневой директории
   * @param type - type of sort
   */
  private timeFilter(documents: (FolderType | FileType | ArchiveType)[], type?: FilterType): any[] {
    if (documents) {
      documents.sort((a, b) => b.timestamp[type] - a.timestamp[type]);
    }
    return documents;
  }

  private creatingDataList(
    data: (FolderType | FileType | ArchiveType)[],
    type: FilterType
  ): Array<(FolderType | FileType | ArchiveType)[]> {
      const dataList: Array<(FolderType | FileType | ArchiveType)[]> = [];
      let dataBlock: (FolderType | FileType | ArchiveType)[] = [];

      if (data.length) {
        dataBlock.push(data[0]);

        data.reduce((previousValue: (FolderType | FileType | ArchiveType), currentValue: (FolderType | FileType | ArchiveType)) => {
          const previousValueDate = timeConversion(previousValue.timestamp[type]).toDateString();
          const currentValueDate = timeConversion(currentValue.timestamp[type]).toDateString();

          if (previousValueDate === currentValueDate) {
            dataBlock.push(currentValue);
          } else {
            dataList.push(dataBlock);
            dataBlock = [];
            dataBlock.push(currentValue);
          }

          return currentValue;
        });

        dataList.push(dataBlock);
      }

      return dataList;
  }

}
