import {Component, OnInit, OnDestroy, Output, EventEmitter, Input, ViewChild} from '@angular/core';
import { WebsocketApiService } from 'src/app/services/websockets/websocket-api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { WS } from 'src/app/services/websockets/websocket.events';
import { ShareSelect } from 'src/app/models/share.model';
import {IEmail, IUser} from 'src/app/common/interfaces';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from "@angular/material/chips";

export class UsersData {
  users: IUser[];
}

@Component({
  selector: 'app-share-form',
  templateUrl: './share-form.component.html',
  styleUrls: ['./share-form.component.scss'],
})
export class ShareFormComponent implements OnInit, OnDestroy {
  userList: IUser[] = [];
  emails: string[] = [];
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];

  shareForm: FormGroup;

  value: FormControl = new FormControl();

  destroySubj: Subject<boolean> = new Subject();
  @Input() incomingData: ShareSelect;
  @Output() shareList: EventEmitter<ShareSelect> = new EventEmitter<ShareSelect>();

  @ViewChild('chipList') chipList;

  constructor(private wsService: WebsocketApiService, private fb: FormBuilder) {}

  ngOnInit() {
    this.getUserIds();
    this.initShareForm();
  }

  ngOnDestroy(): void {
    this.destroySubj.next(false);
  }

  initShareForm() {
    this.shareForm = this.fb.group({
      shareList: this.fb.group({
        forView: [!!this.incomingData ? this.incomingData.forView : []],
        forCheck: [!!this.incomingData ? this.incomingData.forCheck : []],
        forApproval: [!!this.incomingData ? this.incomingData.forApproval : []],
        emails: [this.emails]
      }),
      language: ['ru'],
    });
  }

  emitData() {
    this.shareList.emit(this.shareForm.value);
  }

  // /**
  //  * Получение списка пользователей
  //  */
  getUserIds() {
    const messageId = String(+new Date());
    this.wsService.send(WS.COMMON.users, {}, messageId);
    this.wsService
      .on<any>(messageId, WS.COMMON.users)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (data: UsersData) => {
          const userId = localStorage.getItem('userId');
          this.userList = [...data.users.filter((user: IUser) => user.id !== userId)];
          this.userList = [...this.userList].map((user: IUser) => {
            return { ...user, fullName: user.firstname + ' ' + user.lastname };
          });
        },
        (err) => throwError(err)
      );
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (!this.validateEmail(value)) {
      return;
    }
    if ((value || '').trim()) {
      this.emails.push(value);
      this.shareForm.get('shareList').get('emails').setValue(this.emails);
      this.chipList.errorState = false;
    }
    if (input) {
      input.value = '';
    }
  }

  remove(email): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  validateEmail(email: string): boolean {
    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.chipList.errorState = true;
    return pattern.test(String(email).toLowerCase());
  }
}
