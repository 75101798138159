import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebsocketApiService } from '../../../../services/websockets/websocket-api.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { ArchiveType } from 'src/app/common/interfaces';
import {WS} from "../../../../services/websockets/websocket.events";

@Component({
  selector: 'app-download-archive',
  templateUrl: './download-archive.component.html',
  styleUrls: ['./download-archive.component.scss'],
})
export class DownloadArchiveComponent implements OnInit, OnDestroy {
  archive: ArchiveType;
  isCreating = true;

  destroySubj: Subject<boolean> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) private archiveData,
    private dialogRef: MatDialogRef<DownloadArchiveComponent>,
    private wsService: WebsocketApiService
  ) {}

  ngOnInit() {
    this.wsService
      .on<any>(this.archiveData, WS.ON.createArchive)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (data) => {
          this.archive = data.archives[0];
          this.isCreating = false;
        },
        (err) => throwError(err)
      );
  }

  ngOnDestroy(): void {
    this.destroySubj.next(false);
  }

  close() {
    this.dialogRef.close();
  }

  download() {
    window.open(this.archive.urlDownload);
    this.close();
  }
}
