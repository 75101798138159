import { Injector } from '@angular/core';
import { WS } from '../services/websockets/websocket.events';
import { takeUntil } from 'rxjs/operators';
import { UpdatingTagsComponent } from '../shared/components/modals/';
import { DocumentsHelper } from './documentsHelper';
import { ContentData } from '../common/interfaces';
import { throwError } from 'rxjs';

export interface ITags {
  id: string;
  name: string;
  color: string;
}

export abstract class TagsHelper extends DocumentsHelper {
  tags: ITags[] = [];

  protected constructor(injector: Injector) {
    super(injector);
  }

  subscribeInOnInit() {
    this.wsService
      .on<ContentData>('24', WS.SEND.getTags)
      .pipe(takeUntil(this.destroySubj$))
      .subscribe(
        (data) => {
          if (data) {
            this.tags = data.tags;
          }
        },
        (err) => throwError(err)
      );

    this.wsService
      .on<ContentData>(null, WS.ON.addTag)
      .pipe(takeUntil(this.destroySubj$))
      .subscribe(
        (data) => {
          if (data) {
            this.tags = [...(this.tags || []), data.tags[0]];
          }
        },
        (err) => throwError(err)
      );

    this.wsService
      .on<ContentData>(null, '!' + WS.COMMON.updateTag)
      .pipe(takeUntil(this.destroySubj$))
      .subscribe(
        (data) => {
          if (data) {
            this.tags = this.tags.map((x) => (x.id === data.tags[0].id ? { ...data.tags[0] } : x));
          }
        },
        (err) => throwError(err)
      );

    this.wsService
      .on<any>(null, '!' + WS.COMMON.removeTag)
      .pipe(takeUntil(this.destroySubj$))
      .subscribe(
        (data) => {
          if (data) {
            this.tags = this.tags.filter((item) => item.id !== data.id);
          }
        },
        (err) => throwError(err)
      );
  }

  getTags() {
    this.wsService.send(WS.SEND.subscription, { action: '+', type: 't' }, String(+new Date()));
    this.wsService.send(WS.SEND.getTags, {}, '24');
  }

  createdTags() {
    const dialog = this.matDialog.open(UpdatingTagsComponent, {
      minHeight: '190px',
      maxHeight: '330px',
      width: '415px',
      panelClass: 'create_tags',
    });

    dialog.afterClosed().subscribe((val) => {
      if (!!val) {
        this.wsService.send(WS.SEND.addTags, { tags: val }, String(+new Date()));
      }
    });
  }

  openChangeTag(tag: ITags) {
    const dialog = this.matDialog.open(UpdatingTagsComponent, {
      minHeight: '190px',
      width: '415px',
      panelClass: 'change_tag',
      data: { title: 'Редактировать атрибут', tagData: { ...tag } },
    });

    dialog.afterClosed().subscribe((val) => {
      if (!!val) {
        this.wsService.send(WS.COMMON.updateTag, { ...val[0] }, String(+new Date()));
      }
    });
  }

  deleteTag(tag: ITags) {
    this.wsService.send(WS.COMMON.removeTag, { id: tag.id }, String(+new Date()));
  }

  setTag(tag: ITags, data: any) {
    const dataTag = {
      objectID: data.id,
      tagID: tag.id,
      type: data.objType,
    };
    data.tags = !!data.tags.find((x) => x.id === tag.id)
      ? this.wsService.send(WS.SEND.unsetTag, dataTag, String(+new Date()))
      : this.wsService.send(WS.SEND.setTag, dataTag, String(+new Date()));
  }

  selectedTags(tag: ITags, item: any) {
    if (item.data.tags && item.data.tags.length) {
      return !!item.data.tags.find((x) => x.id === tag.id);
    }
  }
}
