import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-layout-buttons',
  templateUrl: './layout-buttons.component.html',
  styleUrls: ['./layout-buttons.component.scss']
})
export class LayoutButtonsComponent {
  infoFlag = false;
  myDocFlag = true;
  activeContainer = 'tile';

  @Input() infoButton = true;
  @Input() sidebarButton = true;
  @Input() viewIcon: string[];

  @Output() documentViewStyle: EventEmitter<string> = new EventEmitter<string>();
  @Output() viewSidebar: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  activeContainerStyle(event: string) {
    this.activeContainer = event;
    this.documentViewStyle.emit(event);
  }

  openMenu(event: string) {
    this.myDocFlag = !this.myDocFlag;
    this.viewSidebar.emit(event);
  }
}
