<!--mat-button-->
<button
  [disabled]="disabled"
  [ngClass]="[radius, backgroundColor, size, className]"
  [class.myBorder]="border"
  [type]="type"
  (click)="click($event)"
>
  <ng-content></ng-content>
</button>
<!-- 'color-' + color,  -->
<!-- 'hover-' + hover, -->
