import {
  AfterViewInit,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { MatFormField } from '@angular/material/form-field';
import { takeUntil } from 'rxjs/operators';

export enum FloatLabel {
  auto = 'auto',
  always = 'always',
  never = 'never',
}

export interface INameId {
  name: string;
  id: number;
}

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchSelectComponent),
      multi: true,
    },
  ],
})
export class SearchSelectComponent<T> implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor {
  /** control for the MatSelect filter keyword */
  optionFilterCtrl: FormControl = new FormControl();

  /** list of options filtered by search keyword */
  filteredOptions: ReplaySubject<T | T[] | string[]> = new ReplaySubject<T | T[] | string[]>(1);

  // change icon male-female
  changeIcon: boolean;

  currentSelectOption: T | T[];

  fc: FormControl = new FormControl();

  checkboxChecked = false;

  destroy$ = new Subject<void>();

  @Input() placeholder: string;
  @Input() floatLabel: string = FloatLabel.auto;
  @Input() options: T[] | string[] = [];
  @Input() isGenderLabel = false;
  @Input() selectHeight: 'selectNarrow' | 'selectWide' = 'selectWide';
  @Output() selectChange: EventEmitter<T | T[]> = new EventEmitter();
  @Input() isEmitEvent = true;
  @Input() isMultiple = false;
  @Input() isCheckboxSelectAll = true;
  @Input() fieldToDisplayName = 'name';

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;
  @ViewChild('formField', { static: true }) formField: MatFormField;

  constructor() {}

  ngOnChanges(): void {
    this.filteredOptions.next(this.options);
  }

  ngOnInit() {
    this.fc.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.onChange(val);

      if (this.isCheckboxSelectAll) {
        if (val.length && val.length === this.options.length) {
          if (!this.checkboxChecked) {
            this.checkboxChecked = true;
          }
        } else {
          if (this.checkboxChecked) {
            this.checkboxChecked = false;
          }
        }
      }
    });

    this.optionFilterCtrl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.filterOptions();
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  selectionChange(val: T | T[]) {
    this.currentSelectOption = val;
    this.selectChange.emit(val);
  }

  onChange: (val: T | T[]) => T = () => ({} as T);
  onTouched: () => boolean = () => false;

  // сохранение обратного вызова для изменений
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // сохранение обратного вызова для касания
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // сохранение обратного вызова для изменений
  writeValue(val: T | T[]): void {
    this.fc.setValue(val, {
      emitEvent: this.isEmitEvent,
    });
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.fc.disable() : this.fc.enable();
  }

  onOpen() {
    this.formField._elementRef.nativeElement.querySelector('.mat-select-trigger').click();
  }

  onSelectOption(e: Event, option: INameId | string) {
    // this.selectedVal.emit(option.name);
    const nameOption = !!this.fieldToDisplayName
      ? option[this.fieldToDisplayName].toString().toLowerCase()
      : option.toString().toLowerCase();

    if (nameOption === 'male') {
      this.changeIcon = true;
    } else if (nameOption === 'female') {
      this.changeIcon = false;
    }
  }

  ngOnDestroy() {}

  selectAll(value: boolean) {
    if (value) {
      this.fc.setValue((this.options as any[]).map((item) => item.id));
    } else {
      this.fc.setValue([]);
    }
  }

  protected setInitialValue() {
    this.filteredOptions.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.singleSelect.compareWith = (a: string, b: string) => a && b && a === b;
    });
  }

  protected filterOptions() {
    if (!this.options && this.options.length) {
      return;
    }
    // get the search keyword
    let search = this.optionFilterCtrl.value;
    if (!search) {
      this.filteredOptions.next(this.options.slice());
      return;
    } else {
      search = search.toString().toLowerCase();
    }

    const filteredOptions = !this.fieldToDisplayName
      ? (this.options as string[]).filter((option) => option.toString().toLowerCase().indexOf(search) > -1)
      : (this.options as T[]).filter(
          (option) => option[this.fieldToDisplayName].toString().toLowerCase().indexOf(search) > -1
        );

    // filter the options
    this.filteredOptions.next(filteredOptions);
    setTimeout(() => {
      if (!filteredOptions.length) {
        const divEl = this.singleSelect.panel.nativeElement.querySelector(
          '.mat-select-search-no-entries-found.ng-star-inserted'
        );
        if (divEl) {
          divEl.innerText = 'Значений больше нет';
        }
      }
    }, 0);
  }
}
