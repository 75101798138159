<div class="container">
    <div class="title">
        <mat-icon id="notification" [svgIcon]="'appSvg:notification'"></mat-icon>
        <span class="text">Уведомления (2)</span>
    </div>
    <div class="content">
        <div class="new">
            <div class="category">
                <span class="category_text">Новое</span>
                <div class="category_content">
                    <div class="file">
                        <mat-icon id="file_icon" [svgIcon]="'appSvg:pdf'"></mat-icon>
                        <span class="file_text">Название этого файла длинное</span>
                        <div class="description">
                            <span class="description_title">Изменён</span>
                            <span class="time">в 21.13 вчера</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="viewed">
            <div class="category">
                <span class="category_text">Просмотрено</span>
                <div class="category_content">
                    <div class="file">
                        <mat-icon id="file_icon" [svgIcon]="'appSvg:pdf'"></mat-icon>
                        <span class="file_text">Название этого файла длинное</span>
                        <div class="description">
                            <span class="description_title">Добавлен комментарий</span>
                            <span class="time">в 21.13 вчера</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
