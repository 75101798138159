<div class="container">
  <div role="tabpanel" class="noselect_all">
    <div class="sidebar-btn" id="burger" (click)="openMenu()">
      <mat-icon class="sidebar-icon" [svgIcon]="'appSvg:' + (openMenuBar ? 'close-new' : 'sidebar')"></mat-icon>
    </div>
    <div id="WQC" class="label" [routerLink]="['/documents/root']">
      <mat-icon id="logo" svgIcon="appSvg:logo-white"></mat-icon>
    </div>
    <div class="search">
      <button (click)="openSearch()" class="search-btn-header">
        <mat-icon class="search-icon" svgIcon="appSvg:search"></mat-icon>
        <span>{{ btnName }}</span>
      </button>
      <app-search *ngIf="isSearchView"
                  [searchString]="btnName"
                  (getSearchString)="getSearchString($event)"
                  class="search-area">
      </app-search>
    </div>
    <div></div>
    <div *ngIf="isSearchView" class="close-search-area" (click)="closeSearch()"></div>
    <div class="info-wrapper">
      <div class="notification" (click)="openNotification()">
        <div class="count">12</div>
        <mat-icon svgIcon="appSvg:notification-new"></mat-icon>
      </div>
      <div class="user-menu">
        <div class="initials" (click)="openUserMenu()">
          {{ initials }}
        </div>
      </div>
      <div class="org-block">
        <!--<mat-icon class="org-icon" svgIcon="appSvg:ink"></mat-icon>-->
      </div>
    </div>
    <div class="organization-menu">
      <div class="org">
        <mat-icon class="select-org-icon" svgIcon="appSvg:organization"></mat-icon>
        <span class="selected-org-text">1/12</span>
      </div>
      <div class="obj">
        <mat-icon class="select-obj-icon" svgIcon="appSvg:object"></mat-icon>
        <span class="selected-obj-text">1/100</span>
      </div>
    </div>
  </div>
</div>

<app-sidebar-menu *ngIf="openMenuBar" (closeSidebar)="closeMenu()"></app-sidebar-menu>
