import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { RoutingModule } from './routing.module';
import { WebsocketModule } from './services/websockets/websocket.module';
import { SharedModule } from './shared';
import { WbDriverComponent } from './wb-driver.component';
import { environment } from '../environments/environment';
import { ToastrModule, ToastNoAnimationModule, ToastContainerModule } from './shared/components/lib/public_api';

@NgModule({
  declarations: [WbDriverComponent],
  imports: [
    MatDialogModule,
    HttpClientModule,
    BrowserModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    ToastNoAnimationModule,
    RoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    WebsocketModule.config({
      url: environment.apiUrl,
    }),
  ],
  bootstrap: [WbDriverComponent],
  entryComponents: [],
})
export class WbDriverModule {}
