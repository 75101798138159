<div class="selected-tags" [tooltip]="tags">
  <ng-container *ngFor="let tag of tags?.slice(0, 4)">
    <span class="dot" [ngStyle]="{background: tag.color}"></span>
  </ng-container>
  <ng-container *ngIf="tags?.length >= 4">
    <button class="more-vert-button" [tooltip]="tags">
      <mat-icon class="more-icon" [svgIcon]="'appSvg:triangle_right'"></mat-icon>
    </button>
  </ng-container>
</div>
