import { Injector, ElementRef, ViewChild, ChangeDetectorRef, Type } from '@angular/core';
import { Subject, interval, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export abstract class ScrollHelper {
  slideLeftButton = false;
  slideRightButton = true;
  isScrollViewCheck = false;
  mousedownAction: Subject<boolean> = new Subject();
  @ViewChild('scrollContainer', { static: false }) directoriesContainer: ElementRef;

  cdRef: ChangeDetectorRef;

  dataSubject: BehaviorSubject<any> = new BehaviorSubject<any>({});
  destroySubj: Subject<boolean> = new Subject();

  constructor(injector: Injector) {
    this.cdRef = injector.get<ChangeDetectorRef>(ChangeDetectorRef as Type<ChangeDetectorRef>);
  }

  subscribeInOnInit() {
    this.dataSubject.pipe(takeUntil(this.destroySubj)).subscribe(_ => {
      this.cdRef.detectChanges();

      this.resetScroll();
    });
  }

  isScrollView() {
    return this.directoriesContainer
      ? this.directoriesContainer.nativeElement.scrollWidth > this.directoriesContainer.nativeElement.clientWidth
      : false;
  }

  resetScroll() {
    if (this.isScrollView() !== this.isScrollViewCheck) {
      this.isScrollViewCheck = this.isScrollView();
    }

    this.directoriesContainer.nativeElement.scrollLeft = 0;
    this.slideRightButton = true;
    this.slideLeftButton = false;
  }

  scrollRight(scrollInterval: number) {
    this.slideLeftButton = true;
    const scrollLeftValue = this.directoriesContainer.nativeElement.scrollLeft;
    this.directoriesContainer.nativeElement.scrollLeft += scrollInterval;

    if (this.directoriesContainer.nativeElement.scrollLeft - scrollLeftValue < scrollInterval) {
      this.mouseup();
      this.slideRightButton = false;
    }
  }

  scrollLeft(scrollInterval: number) {
    this.slideRightButton = true;
    this.directoriesContainer.nativeElement.scrollLeft -= scrollInterval;
    if (!this.directoriesContainer.nativeElement.scrollLeft) {
      this.mouseup();
      this.slideLeftButton = false;
    }
  }

  mousedown(type) {
    this.mousedownAction.next(true);
    interval(30)
      .pipe(takeUntil(this.mousedownAction))
      .subscribe(_ => {
        if (type === 'left') {
          this.scrollLeft(20);
        } else {
          this.scrollRight(20);
        }
      });
  }

  mouseup() {
    this.mousedownAction.next(false);
  }
}
