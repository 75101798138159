<div class="container">
  <div class="logo">
    <mat-icon id="logo-min" [svgIcon]="'appSvg:logo-min'"></mat-icon>
  </div>
  <span class="title">
    {{ text }}
  </span>
  <div class="close">
    <app-button (click)="close()" backgroundColor="white">Отменить</app-button>
  </div>
  <div class="accept">
    <app-button (click)="deleteItem()">
      {{ buttonText }}
    </app-button>
  </div>
</div>
