<button class="sort-btn"
        mat-button
        [matMenuTriggerFor]="sorting">
  <mat-icon class="more-icon" [svgIcon]="'appSvg:more'"></mat-icon>
</button>
<mat-menu class="matMenu"
          xPosition="after"
          #sorting="matMenu">
  <button *ngFor="let criteria of sortingCriteria"
          class="item-button"
          mat-menu-item>
    <mat-checkbox (click)="sortBy($event, criteria.name)"
                  [checked]="criteria.checked">{{ criteria.text }}</mat-checkbox>
  </button>
</mat-menu>
