import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ItemData } from 'src/app/common/interfaces';

@Component({
  selector: 'app-add-to-design-doc',
  templateUrl: './add-to-design-doc.component.html',
  styleUrls: ['./add-to-design-doc.component.scss'],
})
export class AddToDesignDocComponent {
  constructor(
    private dialogRef: MatDialogRef<AddToDesignDocComponent>,
    @Inject(MAT_DIALOG_DATA) public documentData: ItemData
  ) {}

  closeModal() {
    this.dialogRef.close();
  }
}
