<div class="tab_menu_background" *ngIf="tabMenu">
  <div class="tab_menu">
    <p class="title">Избранное</p>
    <div class="slideLeft" *ngIf="isScrollViewCheck">
      <button *ngIf="slideLeftButton" class="scroll-button" (mousedown)="mousedown('left')" (mouseup)="mouseup()">
        <mat-icon class="icon" [svgIcon]="'appSvg:scroll'"></mat-icon>
      </button>
    </div>

    <div class="directories_container overflow" #scrollContainer>
      <ul class="menu_items">
        <ng-container *ngFor="let data of favoritesData">
          <li class="item"
              title="{{ data.name }}"
              (click)="openContextMenu($event, data, data.objType === 'folder' ? 'directory' : 'file')"
              [routerLink]="['documents', data.id]"
              routerLinkActive="target_item"
              #url="routerLinkActive">
            <span class="item_btn">
              <mat-icon class="logo"
                        [svgIcon]="'appSvg:' + (data.objType === 'folder'
                        ? (!url.isActive ? 'doc-menu-blue' : 'doc-menu-white')
                        : data.fileType + '-favorite')"></mat-icon>
              <span class="menu_item_name" routerLinkActive="target_item_text">{{ data.name }}</span>
            </span>
          </li>
          <hr class="line"
              *ngIf="data !== favoritesData[favoritesData.length - 1]"/>
        </ng-container>
      </ul>
    </div>
    <div class="slideRight" *ngIf="isScrollViewCheck">
      <button *ngIf="slideRightButton" class="scroll-button" (mousedown)="mousedown('right')" (mouseup)="mouseup()">
        <mat-icon id="file" class="icon" [svgIcon]="'appSvg:scroll'"></mat-icon>
      </button>
    </div>
  </div>
</div>
<div class="show_menu_btn_wrapper">
  <button mat-icon-button class="show_menu_btn" (click)="tabMenuShow()">
    <mat-icon [ngClass]="!tabMenu ? 'rotate' : ''" [svgIcon]="'appSvg:collapse'"></mat-icon>
  </button>
</div>
