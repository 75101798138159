<div class="container">
  <div class="title">
    <div>
      <b class="title-name">{{ formName }} <span>{{ step }}</span></b>
    </div>
    <button class="close-button" (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>

  <div class="content" *ngIf="isStepOne">
    <app-upload-step-one [data]="stepTwoData"
                         (step)="changeStepOne($event)"
                         [itemName]="itemName"
                         [fileType]="fileType">
    </app-upload-step-one>
  </div>

  <div class="content" *ngIf="!isStepOne">
    <app-upload-step-two [data]="stepOneData" (step)="changeStepTwo($event)" [documentData]="document"></app-upload-step-two>
  </div>
</div>
