import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  // className - кастомный класс, реализовывать в родительском компоненте, пример: ':host ::ng-deep app-button .test{}'
  @Input() className = '';
  @Input() radius: 'round' | 'semiRound' | 'square' = 'semiRound';
  @Input() backgroundColor: 'white' | 'light-blue' | 'grey' | 'blue' | 'light-grey' | 'dark-white' | 'blue-gradient' = 'blue';
  @Input() size: 'small' | 'medium' | 'big' | 'bigger' = 'medium';
  @Input() border = false;
  @Input() type = 'button';
  @Input() disabled = false;

  @Output() clickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  click(event: MouseEvent) {
    this.clickEvent.emit(event);
  }
}
