<div class="container">
  <div class="time_container">
    <ng-container *ngIf="dataList.length">
      <ng-container *ngFor="let oneDayData of dataList
      | sortByDate: sortByDateFlag">
        <div class="time">
          {{ timeConversion(oneDayData[0]?.timestamp.created)
          | date: 'dd.MM.yyyy' }}
        </div>
        <app-files-container [files]="oneDayData | sortFilesByTime: sortByDateFlag
                                                 | sortByTitle: sortByTitleFlag"
                             [contextPanelData]="contextPanelData">
        </app-files-container>
      </ng-container>
    </ng-container>
  </div>
</div>
