import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ColorPickerComponent } from '../../color-picker/color-picker.component';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

@Component({
  selector: 'app-updating-tags',
  templateUrl: './updating-tags.component.html',
  styleUrls: ['./updating-tags.component.scss'],
})
export class UpdatingTagsComponent implements OnInit, OnDestroy {
  title = 'Добавить Tags';
  tagData;
  form: FormGroup;

  constructor(
    private matDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) private data,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UpdatingTagsComponent>
  ) {}

  ngOnInit() {
    if (!!this.data) {
      this.title = this.data.title;
      this.tagData = this.data.tagData;
    }

    this.form = this.fb.group({
      formArray: this.fb.array([
        this.fb.group({
          id: [this.tagData ? this.tagData.id : 0],
          color: [this.tagData ? this.tagData.color : '#1851A0'],
          name: [this.tagData ? this.tagData.name : '', Validators.required],
        }),
      ]),
    });
  }

  ngOnDestroy(): void {}

  /**
   * Добавление элемента
   */
  addItem(): void {
    (this.form.get('formArray') as FormArray).push(
      this.fb.group({
        id: [0],
        color: ['#1851A0'],
        name: ['', Validators.required],
      })
    );
  }

  /**
   * Удаление элемента
   * @param i - индекс элемента
   */
  removeItem(i: number) {
    (this.form.get('formArray') as FormArray).removeAt(i);
  }

  /**
   * Получение control-а formArray
   */
  get formArrayList(): FormArray {
    return this.form.get('formArray') as FormArray;
  }

  selectColor(index: number) {
    const dialog = this.matDialog.open(ColorPickerComponent, {
      height: '390px',
      width: '248px',
      panelClass: '',
    });

    dialog.afterClosed().subscribe((val) => {
      if (!!val) {
        this.formArrayList.controls[index].get('color').setValue(val);
      }
    });
  }

  saveData() {
    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.formArrayList.value);
  }

  closeModal() {
    this.dialogRef.close();
  }
}
