import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subject, throwError } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { WebsocketApiService } from '../../../../services/websockets/websocket-api.service';
import { ConnectionService } from '../../../../services/connection.service';
import { DesignDocumentsService } from '../../services/design-documents.service';
import { WS } from '../../../../services/websockets/websocket.events';
import { DynamicDatabase, DynamicDataSource, DynamicFlatNode } from './helpers/treeHepler';
import { Attribute, DataTree } from './helpers/design-doc-interfaces';
import { CopyDocumentToComponent, DownloadArchiveComponent } from '../../../../shared/components';
import { InfoMenuService } from '../../services/info-menu.service';
import { ShareDocumentComponent } from '../../../../shared/components/context-panel/context-modals/share-document/share-document.component';
import { TreeDesignService } from './tree-design.service';
import { FileType } from '../../../../common/interfaces';
import { timeConversion } from '../../../../utils/time-conversion';

export class AttrPath {
  attrPath?: string;
  fileId?: string;
  path?: string;
}

export class ShareDesignDoc {
  id: string;
  name: string;
}

/**
 * @title Tree with dynamic data
 */
@Component({
  selector: 'app-tree-design-doc',
  templateUrl: './tree-design-doc.component.html',
  styleUrls: ['./tree-design-doc.component.scss'],
  providers: [DynamicDatabase],
})
export class TreeDesignDocComponent implements OnChanges, OnInit, OnDestroy {

  private onDestroy$: Subject<void> = new Subject();

  foldersMock = [' папка', ' папки', ' папок'];
  filesMock = [' файл', ' файла', ' файлов'];
  sidebar = true;
  isTree = false;
  pathTree: Attribute[] = [];
  treeControl: FlatTreeControl<DynamicFlatNode>;
  dataSource: DynamicDataSource;
  rootId = '';
  lastCheckedFile = '';
  timeConversion = timeConversion;

  @Input() fileId = '';
  @Input() attributes: string[] = [];
  @Input() checkArray: ShareDesignDoc[] = [];
  @Input() startAttributeId: string;
  @Input() secondAttributeId: string;
  @Output() shareArray: EventEmitter<ShareDesignDoc[]> =
    new EventEmitter<ShareDesignDoc[]>();

  constructor(
    public database: DynamicDatabase,
    public router: Router,
    public connectionService: ConnectionService,
    private wsService: WebsocketApiService,
    private designDocService: DesignDocumentsService,
    private route: ActivatedRoute,
    protected matDialog: MatDialog,
    private infoMenuService: InfoMenuService,
    private treeDesignService: TreeDesignService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.secondAttributeId && changes.secondAttributeId.currentValue !==
      changes.secondAttributeId.previousValue) {
      this.isTree = false;
      this.treeControl = new FlatTreeControl<DynamicFlatNode>(
        this.getLevel,
        this.isExpandable
      );

      this.dataSource = new DynamicDataSource(this.treeControl, this.database);

      this.database
        .setRootData(this.secondAttributeId)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (_) => {
            this.dataSource.data = this.database.initialData();
            this.isTree = true;

            if (!!this.attributes.length && !!this.fileId) {
              setTimeout(() => {
                this.getAttribute(this.fileId, this.attributes);
              });
            }
          },
          (err) => throwError(err)
        );
    }

    this.filterTree();
  }

  ngOnInit(): void {
    this.addToAttribute();
    this.addAttributes();

    this.updateFromAttributes();
    this.updateAttributes();

    this.removeFromAttributes();
    this.removeAttributes();
    this.filterTree();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  delete(node): void {
    this.wsService.send(
      WS.SEND.deleteFilesDirectories,
      node.item.objType === 'file'
        ? {filesIDs: [node.item.id]}
        : {directoriesIDs: [node.item.id]}
    );
  }

  getAttribute(urlData: string, attributes: string[]): void {
    const parentsId: string[] = attributes.slice(3);

    if (!!parentsId.length) {
      const destroy: Subject<void> = new Subject();
      this.dataSource.dataChange
        .pipe(takeUntil(destroy))
        .subscribe(() => {
        if (!!parentsId.length) {
          const attributeNode = this.treeControl.dataNodes
            .find((x) => x.item.id === parentsId[0]);
          this.treeControl.expand(attributeNode);

          parentsId.shift();
        } else {
          setTimeout(() => {
            const foundElement = document.getElementById(urlData);
            if (!!foundElement) {
              foundElement.scrollIntoView();
            }
          });

          destroy.next();
        }
      });
    }
  }

  contains(item, param: string, titles: string[]): string {
    if (!item.hasOwnProperty(param)) {
      return param === 'files' ? '0 файлов' : '0 папок';
    }
    const count = item[param];
    const cases = [2, 0, 1, 1, 1, 2];
    return count + titles[(count % 100 > 4 && count % 100 < 20)
      ? 2
      : cases[(count % 10 < 5) ? count % 10 : 5]];
  }

  getLevel = (node: DynamicFlatNode) => node.level;

  isExpandable = (node: DynamicFlatNode) => node.expandable;

  hasChild = (_: number, nodeData: DynamicFlatNode) => nodeData.expandable;

  addToAttribute(): void {
    let parent: DynamicFlatNode | '';
    this.wsService
      .on(null, WS.ON.addToAttribute)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: DataTree) => {
          const regex = /(.zip)$/;
          if (data.hasOwnProperty('attributes')) {
            parent = this.dataSource.data
              .find((nodeData) =>
                nodeData.item.id === data.attributes[0].id);

            if (data.hasOwnProperty('files')) {
              data.files.forEach((x) =>
                (x.objType = regex.test(x.name) ? 'archive' : 'file'));
              this.dataSource.loadNode(data.files[0], parent);
            }

            if (data.hasOwnProperty('directories')) {
              data.directories.forEach((x) =>
                (x.objType = 'directory'));
              this.dataSource.loadNode(data.directories[0], parent);
            }
          }
        },
        (err) => throwError(err)
      );
  }

  addAttributes(): void {
    this.wsService
      .on(null, WS.ON.addAttribute)
      .subscribe((data: DataTree) => {
        this.pathTree.push(data.attributes[0]);
        this.addAttributesInTree(this.pathTree);
      },
      (err) => throwError(err)
    );
  }

  addAttributesInTree(data: Attribute[]): void {
    let parent: DynamicFlatNode | '';

    data
      .sort((x: any, y: any) => x.id - y.id)
      .forEach((attr) => {
        if (!this.dataSource.data
          .find((nodeData) =>
            nodeData.item.id === attr.id)) {
          parent = this.dataSource.data
            .find((nodeData) =>
              nodeData.item.id === attr.associatedID);

          if (!!parent || !!attr.associatedID) {
            this.dataSource.loadNode(
              attr,
              !!parent ? parent : attr.associatedID,
              'new');
          }
        }
      });
  }

  /** TODO: протестировать и дописать, когда будет весь функционал */
  updateFromAttributes(): void {
    let parent: DynamicFlatNode | '';
    this.wsService
      .on(null, WS.ON.updateFromAttributes)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          parent = this.dataSource.data.find((nodeData) => nodeData.item.id === data.id);

          if (data.hasOwnProperty('files')) {
            this.dataSource.loadNode(data.files[0], parent);
          }

          if (data.hasOwnProperty('directories')) {
            this.dataSource.loadNode(data.directories[0], parent);
          }
        },
        (err) => throwError(err)
      );
  }

  updateAttributes() {
    let parent: DynamicFlatNode | '';
    this.wsService
      .on(null, WS.ON.updateAttributes)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: DataTree) => {
          if (data.hasOwnProperty('attributes')) {
            parent = this.dataSource.data
              .find((nodeData) =>
                nodeData.item.id === data.attributes[0].id);

            this.dataSource.loadNode(data.attributes[0], parent);
          }
        },
        (err) => throwError(err)
      );
  }

  /** TODO: протестировать, когда будет весь функционал */
  removeFromAttributes(): void {
    let parent: DynamicFlatNode | '';
    this.wsService
      .on(null, WS.ON.removeFromAttributes)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          parent = this.dataSource.data
            .find((nodeData) =>
              nodeData.item.id === data.id);
          const node = this.dataSource.data
            .find((nodeData) =>
              nodeData.item.id === data.id);

          this.dataSource.deletedNode(node, parent);
        },
        (err) => throwError(err)
      );
  }

  removeAttributes(): void {
    let parent: DynamicFlatNode | '';
    this.wsService
      .on(null, WS.ON.removeAttributes)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (data: any) => {
          if (data.hasOwnProperty('attributes')) {
            parent = this.dataSource.data
              .find((nodeData) =>
                nodeData.item.id === data.id);
            const node = this.dataSource.data
              .find((nodeData) =>
                nodeData.item.id === data.id);

            this.dataSource.deletedNode(node, parent);
          }
        },
        (err) => throwError(err)
      );
  }

  viewItem(item): void {
    if (item.fileType === 'pdf') {
      this.connectionService.setFileLink(item.urlDownload);
      localStorage.setItem('openFileId', item.id);
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['editor'],
          { queryParams: {
              id: item.versions
                ? item.versions[item.versions.length - 1].id
                : item.id,
              filename: item.name
            }
          })
      );
      window.open(url, '_blank');
    } else {
      window.open(item.urlView, '_blank');
    }
  }

  createArchive(node, archiveType: string): void {
    this.wsService.send(
      WS.SEND.createArchive,
      node.item.objType === 'directory'
        ? {type: archiveType, directoriesIDs: [node.item.id]}
        : {type: archiveType, attributesIDs: [node.item.id]},
      String(+new Date())
    );

    this.downloadArchive(String(+new Date()));
  }

  /**
   * Окно перед скачиванием архива
   */
  downloadArchive(messageId: string): void {
    this.matDialog.open(DownloadArchiveComponent, {
      height: '159px',
      width: '292px',
      panelClass: 'download_archive',
      data: messageId,
    });
  }

  copyToMyDoc(item: FileType): void {
    this.matDialog.open(CopyDocumentToComponent, {
      height: '259px',
      width: '416px',
      panelClass: 'copy_document',
      data: item,
    });
  }

  infoItem(item: FileType): void {
    this.infoMenuService.openMenu(item, 'ISD');
  }

  shareDoc(item: FileType): void {
    this.matDialog.open(ShareDocumentComponent, {
      height: '504px',
      width: '415px',
      panelClass: 'share_for_user',
      data: {
        data: item,
        type: 'file',
      },
    });
  }

  checkedFile(e: MouseEvent, fileId: string, fileName: string): void {
    const isItem = this.checkArray
      .some((item) => item.id === fileId);
    if (e.ctrlKey || e.metaKey) {
      if (!!isItem) {
        this.checkArray = this.checkArray
          .filter((item) => item.id !== fileId);
      } else {
        this.lastCheckedFile = fileId;
        this.checkArray.push({ id: fileId, name: fileName });
      }
    } else if (e.shiftKey && this.checkArray.length) {
      if (fileId !== this.lastCheckedFile) {
        this.checkArray = this.treeDesignService
          .getCheckedInterval(this.dataSource.data, fileId, this.lastCheckedFile);
      } else {
        this.checkArray = [{ id: fileId, name: fileName }];
      }
    } else if (this.checkArray.length === 1 && !!isItem) {
      this.checkArray = [];
    } else {
      this.lastCheckedFile = fileId;
      this.checkArray = [{ id: fileId, name: fileName }];
    }
    this.shareArray.emit(this.checkArray);
  }

  checkActive(fileId?: string): boolean {
    return this.checkArray.some((item) => item.id === fileId);
  }

  private filterTree(): void {
    this.designDocService.searchString$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
      (data: string) => (this.dataSource.data = this.database.filter(data)),
      (err) => throwError(err)
    );
  }
}
