import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShareDesignDoc } from 'src/app/components/design-documentation/components/tree-design-doc/tree-design-doc.component';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit {
  fileName: string | number;
  isOneFile: boolean;
  constructor(
    private dialogRef: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string | ShareDesignDoc[]
  ) {
    if (typeof data === 'string') {
      this.fileName = data;
      this.isOneFile = true;
    } else if ((data as ShareDesignDoc[]).length === 1) {
      this.fileName = (data as ShareDesignDoc[])[0].name;
      this.isOneFile = true;
    } else {
      this.fileName = (data as ShareDesignDoc[]).length;
      this.isOneFile = false;
    }
  }

  ngOnInit() {}
}
