import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { WebsocketApiService } from '../../../../services/websockets/websocket-api.service';
import { WS } from '../../../../services/websockets/websocket.events';
import { LoginService } from '../../../../components/auth/services/login.service';
import { OnboardingComponent } from '../../onboarding/onboarding.component';
import { environment } from '../../../../../environments/environment';
import { IUser } from '../../../../common/interfaces';
import { ONBOARDING_DESIGNDOC, ONBOARDING_MYDOC } from '../../../../common/constants';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  initials = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public userData: IUser,
    private dialogRef: MatDialogRef<UserMenuComponent>,
    private matDialog: MatDialog,
    private router: Router,
    private loginService: LoginService,
    private wsService: WebsocketApiService
  ) {}

  ngOnInit() {
    if (this.userData && this.userData.firstname && this.userData.lastname) {
      this.initials = this.userData.firstname.charAt(0) +
        this.userData.lastname.charAt(0);
    }
  }

  closeUserMenu() {
    this.dialogRef.close();
  }

  logout() {
    this.wsService.send(WS.SEND.exit, {}, String(+new Date()));
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('userId');
    localStorage.removeItem('fileId');
    localStorage.removeItem('user');
    this.loginService.isLoggedIn$.next(false);
    this.router.navigate(['/auth']);
    this.dialogRef.close();
    this.wsService.ngOnDestroy();
    window.location.assign(environment.url + 'auth');
  }

  openOnboarding() {
    const tab = this.router.url.split('/').slice(1)[0];
    // TODO: убрать if, когда будет онбординг на всех вкладках
    if (tab === 'documents' || tab === 'designdoc') {
      this.matDialog.open(OnboardingComponent, {
        height: '692px',
        width: '1000px',
        panelClass: 'onbording-modal',
        data:
          tab === 'documents'
            ? {
                nameOnboarding: 'Инструкция “Мои документы”',
                dataOnboardingSlide: ONBOARDING_MYDOC
              }
            : tab === 'designdoc'
            ? {
                nameOnboarding: 'Инструкция “Исполнительные документы”',
                dataOnboardingSlide: ONBOARDING_DESIGNDOC
              }
            : '',
      });
      this.dialogRef.close();
    }
  }
}
