import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Attribute } from '../components/tree-design-doc/helpers/design-doc-interfaces';

@Injectable({
  providedIn: 'root',
})
export class DesignDocumentsService {
  searchString: BehaviorSubject<string> = new BehaviorSubject<string>('');
  searchString$ = this.searchString.asObservable();

  attribute: BehaviorSubject<Attribute | {}> = new BehaviorSubject<Attribute | {}>({});
  attribute$ = this.attribute.asObservable();

  setSearchString(str: string) {
    this.searchString.next(str);
  }

  setAttribute(attribute: Attribute) {
    this.attribute.next(attribute);
  }
}
