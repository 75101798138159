<div class="blue-line top"></div>
<carousel #myCarousel [cellWidth]="1000" [height]="628" [width]="1000" [dots]="true">
  <div class="carousel-cell" *ngFor="let item of dataOnboarding">
    <div class="slide">
      <h3>{{ nameOnboarding }}</h3>
      <p class="slide-name">{{ item.slideName }}</p>
      <div class="slide-image">
        <img [src]="item.imgURL" alt="" />
      </div>
      <div class="text-block">
        <p class="slide-text">
          {{ item.slideText }}
        </p>
      </div>
    </div>
  </div>
</carousel>
<div class="blue-line bottom"></div>
<button *ngIf="this.myCarousel.isNextArrowDisabled()" class="close-btn" (click)="close()">
  Понятно!
</button>
