import { ContentData, FileType, FolderType, IUser, } from '../common/interfaces';
import { ITags } from '../helpers/tagsHelper';
import { Attribute } from '../components/design-documentation/components/tree-design-doc/helpers/design-doc-interfaces';

export class DataModel implements ContentData {
  files: FileType[] = [];
  directories: FolderType[] = [];
  tags: ITags[] = [];
  attributes: Attribute[] = [];
  users: IUser[] = [];
  organizations: string[] = [];

  constructor(obj?: ContentData) {
    if (obj) {
      Object.keys(this).forEach(key => {
        this[key] = obj[key] ? obj[key] : this[key];

        if (obj.hasOwnProperty('directories') && !!+obj.directories.length) {
          obj.directories.forEach(x => {
            x.objType = 'directory';
            x.tags = this.setTags(obj, x.tags);
          });
          this.directories = [...obj.directories];
        } else {
          this.directories = [];
        }

        if (obj.hasOwnProperty('files') && !!+obj.files.length) {
          const regex = /(.zip)$/;
          obj.files.forEach(x => {
            x.objType = regex.test(x.name) ? 'archive' : 'file';
            x.tags = this.setTags(obj, x.tags);
          });
          this.files = [...obj.files];
        } else {
          this.files = [];
        }

        if (obj.hasOwnProperty('users') && !!+obj.users.length
            && obj.hasOwnProperty('files') && !!+obj.files.length) {
          obj.files.forEach(x => {
            x.owner = obj.users.find(user => user.id === x.ownerId);
          });
        }

        if (obj.hasOwnProperty('users') && !!+obj.users.length
          && obj.hasOwnProperty('directories') && !!+obj.directories.length) {
          obj.directories.forEach(x => {
            x.owner = obj.users.find(user => user.id === x.ownerID);
          });
        }

        if (obj.hasOwnProperty('attributes') && !!+obj.attributes.length
          && obj.hasOwnProperty('files') && !!+obj.files.length) {
          obj.files.forEach(x => {
            x.attributes = this.setAttributes(obj, x.attributes);
            this.setRootAttribute(obj, x.attributes);
          });
        }

        if (obj.hasOwnProperty('attributes') && !!+obj.attributes.length
          && obj.hasOwnProperty('directories') && !!+obj.directories.length) {
          obj.directories.forEach(x => {
            x.attributes = this.setAttributes(obj, x.attributes);
            this.setRootAttribute(obj, x.attributes);
          });
        }

        if (obj.hasOwnProperty('attributes') && !!+obj.attributes.length
          && !obj.hasOwnProperty('directories') &&
          !obj.hasOwnProperty('files')) {
            obj.attributes.map(attribute => {
              if (attribute && attribute.hasOwnProperty('associatedID') &&
                typeof attribute.associatedID === 'string') {
                attribute.rootAttribute = obj.attributes
                  .find(x => x.id === attribute.associatedID);
              }
            });
          }

        if (obj.hasOwnProperty('users')  && !!+obj.users.length &&
          obj.hasOwnProperty('organizations') &&
          !!+obj.organizations.length) {
            obj.users.map(user => {
              user.organizations = this.setOrganizations(obj, user.organizations);
            });
        }
      });
    } else {
      return this;
    }
  }

  setTags(obj, tags) {
    return tags && obj.tags && obj.tags.length
      ? tags.map(item => obj.tags
        .find(_ => _.id === (typeof item !== 'object' ? item : item.id))) : [];
  }

  setAttributes(obj, attributes) {
    return attributes && obj.attributes && obj.attributes.length
      ? attributes.map(item => item ? obj.attributes
        .find(_ => _.id === (typeof item === 'string' ? item : item.id)) : {})
      : [];
  }

  setRootAttribute(obj, attributes) {
    attributes.map(attribute => {
      if (attribute && attribute.hasOwnProperty('associatedID')
        && typeof attribute.associatedID === 'string') {
        attribute.rootAttribute = obj.attributes
          .find(x => x.id === attribute.associatedID);
      }
    });
  }

  setOrganizations(obj, organizations) {
    return organizations && obj.organizations && obj.organizations.length
      ? organizations.map(item => obj.organizations
        .find(_ => _.id === (typeof item !== 'object' ? item : item.id)))
      : [];
  }
}
