<div class="container">
  <div class="title">
    <div>
      <b class="title-name">В проектную документацию</b>
    </div>
    <button class="close-button" (click)="closeModal()"><mat-icon>close</mat-icon></button>
  </div>

  <div class="content">
    <app-upload-step-two [documentData]="documentData"></app-upload-step-two>
  </div>
</div>
