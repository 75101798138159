import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ColorSketchModule } from 'ngx-color/sketch';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import {
  ButtonComponent,
  SortMenuComponent,
  LayoutButtonsComponent,
  InfoDocumentComponent,
  DownloadArchiveComponent,
  TableComponent,
  TreeComponent,
  ColorPickerComponent,
  UpdatingTagsComponent,
  CopyDocumentToComponent,
  TagsTooltipComponent,
  TooltipDirective,
  TooltipComponent,
  HeaderComponent,
  DocMenuComponent,
  DeleteDocumentComponent,
  NameExistDocumentComponent,
  ContextPanelComponent,
  AlertComponent,
  UserMenuComponent,
  NotificationsComponent,
  ShareFormComponent,
  AddToDesignDocComponent,
  SearchSelectComponent,
  FileComponent,
  FilesContainerComponent,
  DocContentComponent,
  DocContentTableComponent,
  HeaderNewComponent,
  SidebarMenuComponent,
} from './components';
import { UploadStepTwoComponent } from '../components/design-documentation/components';
import { UniquePipe } from '../pipes/unique.pipe';
import { UniqueAttributesPipe } from '../pipes/unique-attributes.pipe';
import { NgxMatSelectSearchModule } from './components/mat-select-search/ngx-mat-select-search.module';
import { DocsFromArchiveComponent } from './components/context-panel/context-modals/docs-from-archive/docs-from-archive.component';
import { AttributesTooltipComponent } from './components/tooltips/attributes-tooltip/attributes-tooltip.component';
import { ShareDocumentComponent } from './components/context-panel/context-modals/share-document/share-document.component';
import { MatSliderModule } from '@angular/material/slider';
import { OnboardingComponent } from './components/onboarding/onboarding.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatChipsModule } from '@angular/material/chips';
import { SortByDatePipe } from '../pipes/sort-by-date.pipe';
import { SortByTitlePipe } from '../pipes/sort-by-title.pipe';
import { SearchComponent } from './components/header/search/search.component';
import { SortFilesByTimePipe } from '../pipes/sort-files-by-time.pipe';

@NgModule({
  declarations: [
    DeleteDocumentComponent,
    NameExistDocumentComponent,
    ShareDocumentComponent,
    ContextPanelComponent,
    DocMenuComponent,
    AlertComponent,
    UserMenuComponent,
    NotificationsComponent,
    HeaderComponent,
    ButtonComponent,
    SortMenuComponent,
    LayoutButtonsComponent,
    InfoDocumentComponent,
    DownloadArchiveComponent,
    TableComponent,
    TreeComponent,
    ColorPickerComponent,
    UpdatingTagsComponent,
    CopyDocumentToComponent,
    TagsTooltipComponent,
    TooltipDirective,
    TooltipComponent,
    ShareFormComponent,
    UploadStepTwoComponent,
    UniquePipe,
    UniqueAttributesPipe,
    AddToDesignDocComponent,
    SearchSelectComponent,
    DocsFromArchiveComponent,
    AttributesTooltipComponent,
    OnboardingComponent,
    FileComponent,
    FilesContainerComponent,
    DocContentComponent,
    DocContentTableComponent,
    SortByDatePipe,
    SortByTitlePipe,
    SearchComponent,
    SortFilesByTimePipe,
    HeaderNewComponent,
    SidebarMenuComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatTreeModule,
    CommonModule,
    RouterModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    ColorSketchModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule,
    IvyCarouselModule,
    MatSliderModule,
    MatChipsModule,
  ],
  exports: [
    DeleteDocumentComponent,
    NameExistDocumentComponent,
    ShareDocumentComponent,
    ContextPanelComponent,
    DocMenuComponent,
    AlertComponent,
    UserMenuComponent,
    NotificationsComponent,
    HeaderComponent,
    ButtonComponent,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatTreeModule,
    RouterModule,
    MatMenuModule,
    MatCardModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    SortMenuComponent,
    MatButtonModule,
    LayoutButtonsComponent,
    MatTableModule,
    TableComponent,
    MatSortModule,
    TreeComponent,
    TagsTooltipComponent,
    TooltipDirective,
    TooltipComponent,
    ShareFormComponent,
    UploadStepTwoComponent,
    MatTooltipModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    SearchSelectComponent,
    NgxMatSelectSearchModule,
    AttributesTooltipComponent,
    MatSliderModule,
    MatSortModule,
    InfoDocumentComponent,
    OnboardingComponent,
    FileComponent,
    FilesContainerComponent,
    DocContentComponent,
    DocContentTableComponent,
    SortByTitlePipe,
    SearchComponent,
    SortFilesByTimePipe,
    HeaderNewComponent
  ],
  entryComponents: [
    ContextPanelComponent,
    UserMenuComponent,
    ShareDocumentComponent,
    AlertComponent,
    NotificationsComponent,
    DeleteDocumentComponent,
    NameExistDocumentComponent,
    InfoDocumentComponent,
    DownloadArchiveComponent,
    ColorPickerComponent,
    UpdatingTagsComponent,
    CopyDocumentToComponent,
    TagsTooltipComponent,
    TooltipComponent,
    AddToDesignDocComponent,
    OnboardingComponent,
  ],
})
export class SharedModule {}
