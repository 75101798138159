import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {MatDialogRef} from '@angular/material/dialog';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import * as _ from 'lodash';
import {WebsocketApiService} from '../../../../../services/websockets/websocket-api.service';
import {ShareSelect} from '../../../../../models/share.model';
import {WS} from '../../../../../services/websockets/websocket.events';
import {environment} from '../../../../../../environments/environment';
import {ItemData, ItemType, Version} from '../../../../../common/interfaces';
import {UploadDesignDocComponent} from '../upload-design-doc.component';
import {ToastService} from 'src/app/services/toast.service';

export class UploadStepTwoData {
  shared: ShareSelect;
  attribute: AttributeForm[];
  isShareView: boolean;
}

export class AttributeForm {
  type: string;
  value: string;
}

@Component({
  selector: 'app-upload-step-two',
  templateUrl: './upload-step-two.component.html',
  styleUrls: ['./upload-step-two.component.scss'],
})
export class UploadStepTwoComponent implements OnInit, OnDestroy {
  form: FormGroup;

  startAttributes: any[][] = [];
  currentAttributes: any[] = [];
  uniqueTypesList: any[][] = [];
  revisionList: Version[];

  hasChild = true;
  isShareView = false;
  shareArray: ShareSelect;
  language = 'ru';
  isMatch = true;
  organizationId = '';
  isRevision = false;
  fileType: ItemType;

  destroySubj: Subject<boolean> = new Subject();

  @Input() data: any;
  @Input() documentData: ItemData;

  @Output() step: EventEmitter<UploadStepTwoData> = new EventEmitter<UploadStepTwoData>();

  constructor(
    private wsService: WebsocketApiService,
    private fb: FormBuilder,
    private http: HttpClient,
    private dialogRef: MatDialogRef<UploadDesignDocComponent>,
    private toastService: ToastService,
  ) { }

  /**
   * Получение control-а attributes
   */
  get formAttributesList(): FormArray {
    return this.form.get('attributes') as FormArray;
  }

  ngOnInit() {
    this.fileType = this.documentData ? this.documentData.objType : this.data.fileType;
    this.initForm();
    this.getTreeDataWithSubscription([''], 0);

    // !!this.data && !!this.data.attribute
    //   ? this.patchForm()
    //   : this.addItem();
    //
    // if (!!this.data && !!this.data.shared) {
    //   this.shareArray = this.data.shared;
    //
    // }
    // if (!!this.data && !!this.data.isShareView) {
    //   this.isShareView = this.data.isShareView;
    // }
    // this.getUserData();
  }

  ngOnDestroy(): void {
    this.destroySubj.next(false);
  }

  getAttributeValue(i: number): any {
    return (this.form.get('attributes') as FormArray).value[i];
  }

  initForm(): void {
    this.form = this.fb.group({
      attributes: this.fb.array([]),
      revisionNumber: [{}],
    });
    this.pushItem();
  }

  pushItem(): void {
    this.formAttributesList.push(
      this.fb.group({
        type: ['', Validators.required],
        value: [{value: '', disabled: true}, Validators.required],
        searchString: [''],
      })
    );
  }

  changeCheckbox(checkbox: boolean): void {
    this.isRevision = checkbox;
    let attributes = this.form.get('attributes').value;
    attributes = attributes[attributes.length - 1].value;
    attributes.length > 1 ? this.multipleChoice(attributes) : this.singleChoice(attributes[0]);
  }

  singleChoice(attribute: string): void {
    const streamId = String(+new Date());
    this.wsService.send(WS.SEND.getAttributes, {rootAttributeID: attribute}, streamId);
    this.wsService
      .on<any>(streamId, null)
      .subscribe(val => this.revisionList = val.files);
  }

  multipleChoice(attributes: string[]): void {
    attributes.forEach((id, i) => {
      const streamId = String(+new Date() + i);
      this.wsService.send(WS.SEND.getAttributes, {rootAttributeID: id}, streamId);
      return this.wsService
        .on<any>(streamId, null)
        .subscribe(data => {
          if (!data.hasOwnProperty('files')) { return; }
          this.revisionList = [...data.files];
        });
    });
  }

  getTreeDataWithSubscription(startAttributeId: string[], index: number): void {
    const streamId = String(+new Date());
    this.currentAttributes = [];
    this.wsService.send(WS.SEND.getAttributeTree, {rootAttributeIDs: startAttributeId}, null, 'open', streamId);
    this.wsService.on<any>(null, null, streamId).subscribe((val) => {
      if (val && val.hasOwnProperty('attributes')) {
        if (!this.startAttributes.length) {
          this.startAttributes.push(val.attributes);
        } else {
          if (!this.startAttributes[index].find((x) => x.id === val.attributes[0].id)) {
            this.startAttributes[index].push(val.attributes[0]);
          }
        }

        this.currentAttributes.push(val.attributes[0]);
      } else if (!val) {
        this.uniqueTypesList[index] = this.uniqueTypes(this.startAttributes[index]);

        this.startAttributes[index] = this.startAttributes[index].filter((attr, ind) => {
          let item: any;
          return (
            this.startAttributes[index].findIndex((res) => {
              item = res;
              return res.name === attr.name;
            }) === ind || item.type.id !== attr.type.id
          );
        });

        this.isMatch = false;
      }
    });
  }

  uniqueTypes(attributes: any[]) {
    if (attributes) {
      const types = attributes.map((x) => x.type.name);
      const uniqTypes = _.uniq(types);
      return uniqTypes.map((x) => attributes.find((y) => y.type.name === x));
    }
  }

  /**
   * Заполнение данными формы, при возврате с шага 2
   */
  patchForm() {
    this.data.attribute.forEach((item) => {
      this.formAttributesList.push(
        this.fb.group({
          type: [item.type, Validators.required],
          value: [item.value, Validators.required],
          searchString: [''],
        })
      );
    });
  }

  getShareList(obj: { shareList: ShareSelect; language: string }) {
    this.shareArray = obj.shareList;
    this.language = obj.language;
  }

  selectedType(control: FormGroup): void {
    if (control.get('value').disabled) {
      control.get('value').enable();
    }
  }

  /**
   * Добавление элемента
   */
  addItem(): void {
    this.pushItem();
    const index = this.formAttributesList.length - 2;
    const previousId = this.formAttributesList.length - 2 >= 0 ? this.formAttributesList.at(index).value.value : '';
    if (previousId) {
      const allId = [];

      previousId.forEach((val) => {
        const attr = this.currentAttributes.find((item) => item.id === val);
        allId.push(
          ...this.currentAttributes
            .filter((res) => attr.name === res.name && attr.type.id === res.type.id)
            .map((res) => res.id)
        );
      });

      this.formAttributesList.patchValue(this.formAttributesList.value);
      this.startAttributes.push([]);
      this.getTreeDataWithSubscription(allId, index + 1);
    }
  }

  /**
   * Удаление элемента
   * @param i - индекс элемента
   */
  removeItem(i: number) {
    this.formAttributesList.removeAt(i);
    const previousId = i - 2 >= 0 ? this.formAttributesList.at(i - 2).value.value : [''];
    if (previousId) {
      this.startAttributes = this.startAttributes.filter(
        (attributes) => this.startAttributes.indexOf(attributes) !== i
      );
    }
    if (!this.hasChild) {
      this.hasChild = true;
    }
  }

  getAllAttributesIds() {
    return this.formAttributesList.value
      .map((x) => x.value)
      .slice(-1)
      .flat();
  }

  save() {
    if (typeof this.data.fileUpload !== 'string') {
      this.saveWithUploadFile();
      if (this.isShareView) {
        Array.from(this.data.fileUpload)
          .forEach((file: File) => this.openNotification(file.name));
      }
    } else {
      this.saveWithSecondStep();
    }
    this.closeModal();
  }

  saveWithSecondStep() {
    if (this.isRevision) {
      this.wsService.send(WS.SEND.setVersion, {
        oldDocumentID: this.documentData.data.id,
        documentID: this.form.get('revisionNumber').value
      });
    } else {
      this.wsService.send(
        WS.SEND.setAttributeRequest,
        {
          type: this.documentData.objType,
          objectID: this.documentData.data.id,
          attributeIDs: this.getAllAttributesIds(),
          documentTypeID: this.data.docType
        },
        String(+new Date())
      );
    }

    if (this.isShareView) {
      this.shareDoc();
    }
  }

  getUserData() {
    const messageId = String(+new Date());
    const userId = localStorage.getItem('userId');
    this.wsService.send(WS.SEND.user, {id: userId}, messageId);
    this.wsService
      .on(messageId, null)
      .pipe(takeUntil(this.destroySubj))
      .subscribe(
        (data: any) => (this.organizationId = data.organizations.find((x) => x.id === data.currentOrganization).id)
      );
  }

  saveWithUploadFile() {
    Array.from(this.data.fileUpload).forEach((file: File) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('filename', file.name);
      formData.append('token', localStorage.getItem('userId'));
      formData.append('description', this.data.description);
      formData.append('attributesIDs', JSON.stringify(this.getAllAttributesIds()));
      formData.append('documentTypeID', this.data.docType);
      // formData.append('organization', this.organizationId);
      if (this.isShareView) {
        formData.append('share', JSON.stringify(this.shareArray));
      }
      const id = this.form.get('revisionNumber').value;

      this.http.post(environment.urlUpload + (this.isRevision ? `files/addVersion/${id}` : 'files/uploadToISP'), formData)
        .subscribe();
    });
  }

  shareDoc() {
    this.wsService.send(
      WS.SEND.share,
      {
        documentIDs: [this.documentData.data.id],
        language: this.language,
        ...this.shareArray,
      }, String(+new Date())
    );
    this.openNotification(this.documentData.data.name);
  }

  openNotification(name?: string): void {
      this.toastService.openToast(`Вы открыли доступ к документу ${name}`);
  }

  openBeforeStep() {
    this.step.emit({
      shared: this.shareArray,
      attribute: this.form.get('attributes').value,
      isShareView: this.isShareView,
    });
  }

  checkChild(id: string[], array: any[]) {
    // this.hasChild = attribute.hasOwnProperty('hasChild');
    const a = array.filter((item) => id.some((x) => x === item.id));
    this.hasChild = a.some((item) => item.hasOwnProperty('hasChild'));
  }

  closeModal() {
    this.dialogRef.close();
  }
}
