<!-- Спинер и скилетон при загрузке проекта -->
<div class="loading" *ngIf="!isTree">
  <mat-spinner class="spinner"></mat-spinner>
  <app-skeleton-loading-attribute></app-skeleton-loading-attribute>
</div>
<div [ngClass]="!hideAttributeList ? 'router-container' : 'router-whole-container'">
  <ul class="tab-attr-list overflow" #tabAttrList *ngIf="!hideAttributeList">
    <li class="tab-item tab-item-border"
        dir="ltr"
        *ngFor="let tab of tabs"
        [routerLinkActive]="['active']">
      <button class="root-attribute"
              [id]="tab.id"
              (click)="isCommonTab ? checkedAttribute(tab) : getStartAttribute(tab)"
              [ngClass]="{ active_attribute: checkActive(
                tab?.id,
                isCommonTab ? secondAttributeId : firstAttributeId
              ) }">
        <div class="not-active not-active-border">
          <div class="tab-name">
            <mat-icon class="folder-icon"
                      svgIcon="appSvg:full_folder">
            </mat-icon>
            <span class="tab-text">{{ tab.name }}</span>
          </div>
        </div>
      </button>
    </li>
  </ul>
  <div [ngClass]="!hideAttributeList ? 'contain-tree' : 'whole-contain-tree'"
       *ngIf="isCommonTab || secondColumnTabs">
    <div class="tab-second-attr-list" *ngIf="!hideAttributeList && !isCommonTab">
      <div class="attr-header">
        <div class="secondColumnName">{{ secondColumnName }}</div>
        <mat-icon class="hide-arrow"
                  svgIcon="appSvg:hide-arrow"
                  *ngIf="secondAttributeId"
                  (click)="hideAttributeLists()">
        </mat-icon>
      </div>
      <ul class="tab-attr-list overflow" *ngIf="check()">
        <li class="tab-item tab-item-border"
            dir="ltr"
            *ngFor="let attr of secondColumnTabs"
            [routerLinkActive]="['active']">
          <button class="root-attribute"
                  [id]="attr.id"
                  (click)="checkedAttribute(attr)"
                  [ngClass]="{ active_attribute: checkActive(attr?.id, secondAttributeId) }">
            <div class="not-active not-active-border">
              <div class="tab-name">
                <mat-icon class="folder-icon"
                          svgIcon="appSvg:full_folder">
                </mat-icon>
                <span class="tab-text">{{ attr.name }}</span>
              </div>
            </div>
          </button>
        </li>
      </ul>
    </div>
    <div [ngClass]="isCommonTab ? 'whole-tree-container' : 'tree-container'"
         *ngIf="isCommonTab || (secondAttributeId && check())">
      <div class="container-header" *ngIf="tabName">
        <div class="container">
          <div class="attr-second-name" *ngIf="hideAttributeList">{{ secondColumnName }}</div>
          <mat-icon class="hide-arrow-rotate"
                    svgIcon="appSvg:hide-arrow"
                    *ngIf="hideAttributeList"
                    (click)="hideAttributeLists()">
          </mat-icon>
          <div class="attribute-name">{{ tabName }}</div>
          <app-button className="download-button"
                      backgroundColor="light-grey"
                      [matMenuTriggerFor]="menu">
            <mat-icon class="download" svgIcon="appSvg:download"></mat-icon>
            Скачать
          </app-button>
        </div>
        <button *ngIf="fileArray.length" class="share-btn" (click)="shareDoc()">
          <mat-icon class="share-icon" svgIcon="appSvg:share"></mat-icon>
        </button>
      </div>
      <div class="overflow tree-attribute"
           [ngClass]="{'tree-attribute-with-properties': infoMenu}">
        <app-tree-design-doc *ngIf="secondAttributeId"
                             [checkArray]="checkArray"
                             (shareArray)="getFileList($event)"
                             [attributes]="attributes"
                             [fileId]="fileId"
                             [secondAttributeId]="secondAttributeId">
        </app-tree-design-doc>
      </div>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" class="max-height-window" yPosition="below">
  <div class="load_menu">
    <button mat-menu-item
            class="load_file_btn load_btn"
            (click)="createArchive('zip')">
      <span class="text">Скачать ZIP-архив</span>
    </button>
    <button mat-menu-item
            class="load_btn"
            [disabled]="true"
            (click)="createArchive('rar')">
      <span class="text">Скачать RAR-архив</span>
    </button>
  </div>
</mat-menu>
