import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WindowResizeService {
  resizeSubj: Subject<any> = new Subject();
  constructor() {
    window.onresize = () => {
      this.resizeSubj.next();
    };
  }

  getWinResize() {
    return this.resizeSubj;
  }
}
