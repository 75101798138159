import { Injectable, Injector } from '@angular/core';
import { OverlayRef, PositionStrategy, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subscription, fromEvent } from 'rxjs';
import { ConnectionService } from './connection.service';
import { CONTAINER_DATA } from '../common/constants';
import { ContextPanel, ContextPanelComponent } from '../shared/components/context-panel';

@Injectable({ providedIn: 'root' })
export class ContextPanelService {
  overlayRef: OverlayRef;
  overlayPosition: PositionStrategy;
  formComponentPortal: ComponentPortal<ContextPanelComponent>;
  subscription: Subscription;

  constructor(private overlay: Overlay, private connectionService: ConnectionService, private injector: Injector) {}

  getOverlayPosition({ x, y }): PositionStrategy {
    this.overlayPosition = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        }
      ]);

    return this.overlayPosition;
  }

  openContextMenu(cfg: ContextPanel) {
    cfg.event.preventDefault();

    const x = cfg.event.pageX;
    const y = cfg.event.pageY;

    this.overlayRef = this.overlay.create({
      positionStrategy: this.getOverlayPosition({ x, y }),
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.formComponentPortal = new ComponentPortal(ContextPanelComponent, null, this.createInjector(cfg.componentData));

    if (!this.overlayRef.hasAttached()) {
      this.connectionService.setItemData(cfg.item, cfg.type, cfg.area);
      const ref = this.overlayRef.attach(this.formComponentPortal);
      ref.instance.closePanel.subscribe(() => this.overlayRef.detach());
    } else {
      this.overlayRef.detach();
    }
    this.overlayRef.backdropClick().subscribe(_ => this.overlayRef.dispose());
    this.subscription = fromEvent(document, 'click').subscribe(ev => {
      const el = document.getElementsByClassName('context-menu')[0];
      if (el && el.contains(ev.target as HTMLElement)) {
        this.overlayRef.detach();
        this.subscription.unsubscribe();
      }
    });
  }

  createInjector(dataToPass): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this.injector, injectorTokens);
  }
}
