import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WebsocketApiService } from '../../../../../services/websockets/websocket-api.service';
import { AlertComponent } from '../../../header';
import { ItemData } from '../../../../../common/interfaces';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { WS } from '../../../../../services/websockets/websocket.events';
import { ShareSelect } from 'src/app/models/share.model';
import { ShareDesignDoc } from 'src/app/components/design-documentation/components/tree-design-doc/tree-design-doc.component';
import { ToastrService, GlobalConfig } from '../../../lib/public_api';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-share-document',
  templateUrl: './share-document.component.html',
  styleUrls: ['./share-document.component.scss'],
})
export class ShareDocumentComponent implements OnInit {
  checkedEmail = true;
  checkedViber = false;
  shareArray: ShareSelect;

  options: GlobalConfig;
  private lastInserted: number[] = [];

  item: ItemData | ShareDesignDoc[];
  language = 'ru';
  idArray = [];

  constructor(
    private dialogRef: MatDialogRef<ShareDocumentComponent>,
    private wsService: WebsocketApiService,
    @Inject(MAT_DIALOG_DATA) data,
    public toastr: ToastrService,
    private toastService: ToastService
  ) {
    this.item = data;
    this.options = this.toastr.toastrConfig;
  }

  ngOnInit() {}

  getShareList(obj: { shareList: ShareSelect; language: string }) {
    this.shareArray = obj.shareList;
    this.language = obj.language;
  }

  shareDoc() {
    if (this.checkedEmail) {
      this.item.hasOwnProperty('data')
        ? this.idArray.push((this.item as ItemData).data.id)
        : (this.item as ShareDesignDoc[]).forEach((item) => this.idArray.push(item.id));
      this.wsService.send(
        WS.SEND.share,
        {
          documentIDs: this.idArray,
          language: this.language,
          ...this.shareArray,
          otherEmails: this.shareArray.emails
        },
        String(+new Date())
      );
    }
    this.openNotification();
    this.close('success');
  }

  openNotification(): void {
    if (this.item.hasOwnProperty('data')) {
      let itemName;
      itemName = (this.item as ItemData).data.name;
      this.toastService.openToast(`Вы открыли доступ к документу ${itemName}`);
    } else {
      (this.item as ShareDesignDoc[]).forEach((item) => {
        this.toastService.openToast(`Вы открыли доступ к документу ${item.name}`);
      });
    }
  }

  messenger(event: MatSlideToggleChange, value: string) {
    this.checkedViber = value === 'viber' && event.checked;
  }

  close(msg?: string) {
    this.dialogRef.close(msg);
  }
}
