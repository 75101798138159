import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InfoDocumentComponent } from '..';
import { ChooseFolder } from '../../../../../models/tree.model';
import { WebsocketApiService } from '../../../../../services/websockets/websocket-api.service';
import { WS } from '../../../../../services/websockets/websocket.events';

@Component({
  selector: 'app-copy-document',
  templateUrl: './copy-document.component.html',
  styleUrls: ['./copy-document.component.scss'],
})
export class CopyDocumentToComponent implements OnInit, OnDestroy {
  folderName: string;
  folderId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialogRef: MatDialogRef<InfoDocumentComponent>,
    private wsService: WebsocketApiService
  ) {}

  ngOnInit() {}

  ngOnDestroy(): void {}

  /**
   * Получение пути и id папки назначения
   */
  getFolderName(event: ChooseFolder) {
    this.folderName = event.path;
    this.folderId = event.id;
  }

  saveData() {
    this.dialogRef.close();
    let data = {
      type: this.data.hasOwnProperty('owner') ? this.data.objType : 'version',
      objectID: this.data.id,
      toDirectoryID: this.folderId
    };

    this.wsService.send(
      WS.SEND.copyToMD,
      data,
      String(+new Date())
    );
  }

  closeModal() {
    this.dialogRef.close();
  }
}
