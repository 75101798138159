import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import {FileType, FolderType, ItemContext, ItemData, Version} from '../../../../../common/interfaces';
import { WebsocketApiService } from '../../../../../services/websockets/websocket-api.service';
import { WS } from '../../../../../services/websockets/websocket.events';

@Component({
  selector: 'app-delete-document',
  templateUrl: './delete-document.component.html',
  styleUrls: ['./delete-document.component.scss']
})
export class DeleteDocumentComponent implements OnDestroy {
  item: ItemData;
  text: string;
  buttonText: string;

  destroySubj: Subject<boolean> = new Subject();

  constructor(
    private dialogRef: MatDialogRef<DeleteDocumentComponent>,
    private wsService: WebsocketApiService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.item = data;
    if (this.item.data.hasOwnProperty('number')) {
      this.buttonText = 'Удалить';
      this.text = 'Вы действительно хотите удалить документ?';
    } else {
      this.buttonText = 'Поместить';
      this.text = 'Поместить документ в корзину?';
    }
  }

  ngOnDestroy(): void {
    this.destroySubj.next(false);
  }

  /**
   * Удаление файла или папки и помещение в корзину
   */
  deleteItem() {
    if (this.item.data.hasOwnProperty('number')) {
      const item = (this.item.data as Version);
      this.wsService.send(
        WS.SEND.removeVersion,{versionID: item.id});
    } else {
      const item = (this.item.data as ItemContext);
      this.wsService.send(WS.SEND.remove, {
        id: item.id,
        type: this.item.objType,
        rootDirectoryID: (item as FileType | FolderType).rootDirectoryID || ''
      }, String(+new Date()));
      // this.wsService
      //   .on<any>(null, WS.ON.changeDirectory)
      //   .pipe(takeUntil(this.destroySubj))
      //   .subscribe(rootId => {
      //     if (!rootId) {
      //       this.router.navigate(['/documents']);
      //     } else {
      //       this.router.navigate(['/documents/' + (this.item.data as FolderType).rootDirId]);
      //       // TODO: раскомментить после добавления просмотра вложенных папок
      //       // this.router.navigate(['/documents/' + rootId]);
      //     }
      //   });
      this.wsService.send(WS.SEND.subscription, {
        action: '-',
        type: 'directory',
        id: item.id,
        area: this.item.area
      }, String(+new Date()));

      const dataFav = {
        objectID: item.id,
        type: this.item.objType
      };

      if ((item as ItemContext).isFavorite) {
        this.wsService.send(WS.SEND.unsetFavorite, dataFav, String(+new Date()));
      }
    }
    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
