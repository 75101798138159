<form [formGroup]="shareForm" class="share-form">
  <div formGroupName="shareList">
    <app-search-select placeholder="Для информации:" formControlName="forView" (selectChange)="emitData()" [isMultiple]="true" [options]="userList" [fieldToDisplayName]="'fullName'" ></app-search-select>
    <app-search-select placeholder="Для проверки:" formControlName="forCheck" (selectChange)="emitData()" [isMultiple]="true" [options]="userList" [fieldToDisplayName]="'fullName'"></app-search-select>
    <app-search-select placeholder="Для согласования:" formControlName="forApproval" (selectChange)="emitData()" [isMultiple]="true" [options]="userList" [fieldToDisplayName]="'fullName'"></app-search-select>
    <mat-form-field class="email-field">
      <mat-chip-list #chipList>
        <mat-chip *ngFor="let email of emails" [selectable]="selectable" [removable]="removable"
                  (removed)="remove(email)">
          {{email}}
          <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
        </mat-chip>
        <input placeholder="Введите почту:"
               [matChipInputFor]="chipList"
               [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
               [matChipInputAddOnBlur]="addOnBlur"
               (matChipInputTokenEnd)="add($event)">
      </mat-chip-list>
      <mat-error>Введите правильный Email</mat-error>
    </mat-form-field>
  </div>
  <p class="item-label">Язык письма:</p>
  <mat-radio-group aria-label="Язык письма" formControlName="language" (change)="emitData()">
    <mat-radio-button class="item" value="ru">Русский</mat-radio-button>
    <mat-radio-button class="item" value="en">English</mat-radio-button>
    <mat-radio-button class="item" value="tur">Türk</mat-radio-button>
  </mat-radio-group>
</form>
