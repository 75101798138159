import { AfterViewInit, Component, EventEmitter, Inject, Injector, OnInit, Output, ViewChild } from '@angular/core';
import { WS } from '../../../services/websockets/websocket.events';
import { CONTAINER_DATA } from '../../../common/constants';
import { FileType, FolderType, ItemContext, ItemData } from '../../../common/interfaces';
import { TagsHelper } from '../../../helpers/tagsHelper';
import { ContextPanelData, ContextPanelDataModel, ContextPanelKeys } from './context-panel.model';
import { CopyDocumentToComponent, DeleteDocumentComponent, } from './context-modals';
import { UploadDesignDocComponent } from 'src/app/components/design-documentation/components/upload-design-doc/upload-design-doc.component';
import { ShareDocumentComponent } from './context-modals/share-document/share-document.component';
import { InfoMenuService } from '../../../components/design-documentation/services/info-menu.service';
import { RecentFilesService } from '../../../components/recent-files/services/recent-files.service';

@Component({
  selector: 'app-context-panel',
  templateUrl: './context-panel.component.html',
  styleUrls: ['./context-panel.component.scss'],
})
export class ContextPanelComponent
  extends TagsHelper
  implements OnInit, AfterViewInit {
  item: ItemData;
  isFavorite = false;

  contextPanelData: ContextPanelData;

  @Output() closePanel = new EventEmitter<void>();
  @ViewChild('openContextPanel', {static: true}) openContextPanel: any; // TODO: поменять тип на нужный

  constructor(
    inject: Injector,
    @Inject(CONTAINER_DATA) public componentData: ContextPanelKeys[], // это те данные которые там передали
    private infoMenuService: InfoMenuService,
    private recentFilesService: RecentFilesService
  ) {
    super(inject);
  }

  ngOnInit(): void {
    this.connectionService.itemData$
      .subscribe((data: ItemData) => (this.item = data));

    this.isFavorite = (this.item.data as ItemContext).isFavorite;
    this.contextPanelData = new ContextPanelDataModel(this.componentData);
    this.getTags();
    this.subscribeInOnInit();
  }

  ngAfterViewInit(): void {
    this.openMatMenu();
    this.cdRef.detectChanges();
  }

  openFile(file): void {
    if (file.data.fileType === 'pdf') {
      this.connectionService.setFileLink(file.data.urlDownload);
      localStorage.setItem('openFileId', file.data.id);
      file.data.versions.sort((a,b)=>{return a.number-b.number})
      const url = this.router.serializeUrl(
        this.router.createUrlTree(['editor'],
          { queryParams: {
              id: file.data.versions
                ? file.data.versions[file.data.versions.length - 1].id
                : file.data.id,
              filename: file.data.name
            }
          })
      );
      window.open(url, '_blank');
    } else {
      window.open(file.data.urlView, '_blank');
    }

    this.recentFilesService
      .addToRecentFiles({ type: 'file', id: file.data.id });
  }

  /**
   * Отправка уведомления на почту о том, что файлом поделились
   */
  shareFile(): void {
    this.matDialog.open(ShareDocumentComponent, {
      height: '504px',
      width: '415px',
      panelClass: 'share_for_user',
      data: this.item,
    });
  }

  /**
   * Окно перед удалением файла
   */
  deleteItem(): void {
    this.matDialog.open(DeleteDocumentComponent, {
      height: '159px',
      width: '352px',
      panelClass: 'delete_document',
      data: this.item,
    });
  }

  /**
   * Копировать файл/папку в мои документы
   */
  copyItemTo(): void {
    this.matDialog.open(CopyDocumentToComponent, {
      height: '259px',
      width: '416px',
      panelClass: 'copy_document',
      data: this.item,
    });
  }

  bufferFile(): void {
    this.wsService.send(
      WS.SEND.createClipboard,
      {id: this.item.data.id, type: this.item.objType},
      String(+new Date())
    );
  }

  deleteFromBuffer(): void {
    this.wsService.send(
      WS.SEND.removeClipboard,
      {id: this.item.data.id},
      String(+new Date())
    );
  }

  deleteFromArchive(): void {
    // this.wsService.send(WS.SEND.removeArchive, { id: this.item.data.id }, String(+new Date()));
  }

  // /**
  //  * Сообщение о том, что файл с таким именем уже существует
  //  */
  // changeName() {
  //   this.matDialog.open(NameExistDocumentComponent, {
  //     height: '388px',
  //     width: '476px',
  //     panelClass: 'change_name',
  //     data: this.item
  //   });
  // }

  infoItem(): void {
    this.infoMenuService.openMenu((this.item.data as FileType | FolderType), 'context');
  }

  viewItem(item): void {
    if (this.contextPanelData.open && item.objType !== 'directory') {
      if (item.data.urlView && item.objType === 'file') {
        // window.open(item.data.urlView);
        this.openFile(item);
      } else if (!item.data.urlView && item.area !== 'mydoc') {

      } else if (item.objType === 'archive' && item.area === 'mydoc') {
        this.viewArchive(item);
      }
    } else if (this.contextPanelData.open && item.objType === 'directory') {
      if (!item.data.urlView && item.area === 'mydoc') {
        this.openFolder(item);
      }
    }
  }

  openFolder(folder): void {
    this.router.navigate(['documents', folder.data.id]);
    this.getFoldersAndFiles(
      folder.data.id,
      'mydoc',
      String(+new Date())
    );
    this.connectionService.setFolder(folder.data);

    // this.recentFilesService.addToRecentFiles({ type: 'directory', id: folder.data.id });
  }

  viewArchive(archive): void {

  }

  setFavorite(): void {
    const dataFav = {
      objectID: this.item.data.id,
      type: this.item.objType,
    };

    (this.item.data as ItemContext).isFavorite
      ? this.wsService.send(WS.SEND.unsetFavorite, dataFav, String(+new Date()))
      : this.wsService.send(WS.SEND.setFavorite, dataFav, String(+new Date()));
  }

  addToProjectDocuments(): void {
    // this.matDialog.open(AddToDesignDocComponent, {
    this.matDialog.open(UploadDesignDocComponent, {
      maxHeight: '717px',
      width: '416px',
      data: {item: this.item, formName: 'В исполнительную документацию'}
    });
  }

  unpackToDirectory(): void {

  }

  unpackToProjectDocuments(): void {

  }

  openMatMenu(): void {
    this.openContextPanel._elementRef.nativeElement.click();
  }

  closeMatMenu(): void {
    this.closePanel.emit();
  }
}
