import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docs-from-archive',
  templateUrl: './docs-from-archive.component.html',
  styleUrls: ['./docs-from-archive.component.scss']
})
export class DocsFromArchiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
