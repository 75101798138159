import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import {
  HorizontalConnectionPos,
  Overlay,
  OverlayConfig,
  OverlayRef,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ITags } from '../../../../helpers/tagsHelper';
import { TooltipComponent } from './tooltip.component';
import { Attribute } from '../../../../components/design-documentation/components/tree-design-doc/helpers/design-doc-interfaces';

export const DEFAULT_TOOLTIP_CONFIG = {
  hasBackdrop: false,
  panelClass: 'overlay-panel',
  backdropClass: 'overlay-backdrop',
};

// tslint:disable:directive-selector
@Directive({
  selector: '[tooltip]',
})
export class TooltipDirective implements OnDestroy {
  @Input('tooltip') data: ITags[] | Attribute[] | {name: string}[] = [];
  @Input() tooltipBackground;
  @Input() tooltipColor;
  @Input() tooltipBoxShadowClass;

  top = 0;
  left = 0;

  overlayRef: OverlayRef;
  tooltipRef: ComponentRef<TooltipComponent>;
  param;
  event;
  offsetX;
  offsetY;
  width;
  height;

  constructor(private element: ElementRef, private overlay: Overlay) {}

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  @HostListener('mousemove', ['$event'])
  @HostListener('mouseenter')
  mouseEnter() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
    if (this.data === []) {
      return false;
    }
    const cords = this.offset(this.element.nativeElement);
    this.left = cords.left;
    this.top = cords.top;

    this.param = {
      data: this.data,
      background: this.tooltipBackground,
      color: this.tooltipColor,
      boxShadow: this.tooltipBoxShadowClass,
    };

    this.overlayRef = this.overlay.create(this.getOverlayConfig());
    const portal = new ComponentPortal(TooltipComponent);
    this.tooltipRef = this.overlayRef.attach(portal);

    Object.keys(this.param).map((key) => {
      this.tooltipRef.instance[key] = this.param[key];
    });

    this.tooltipRef.instance.tooltipParams.subscribe((item) => {
      this.height = item.height;
      this.width = item.width;
    });
  }
  getPositionStrategy(event) {
    const scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    const originY: VerticalConnectionPos = `bottom`;
    const originX: HorizontalConnectionPos = `start`;

    this.offsetX = event.offsetX;
    this.offsetY = event.offsetY / 2;

    if (this.offsetX > this.element.nativeElement.offsetWidth) {
      this.offsetX = event.offsetX / 100 + this.element.nativeElement.offsetWidth;
    }
    if (event.pageX + this.width >= document.body.scrollWidth) {
      this.offsetX = event.offsetX * 1.5 - this.width;
    }

    if (this.offsetY > this.element.nativeElement.offsetHeight) {
      this.offsetY = event.offsetY / 100 + this.element.nativeElement.offsetHeight;
    }
    if (event.pageY + this.height >= scrollHeight) {
      this.offsetY = -event.offsetY - this.height * 1.2;
    }

    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX,
          originY,
          overlayX: originX,
          overlayY: originY,
          offsetX: this.offsetX,
          offsetY: this.offsetY,
        },
      ]);
  }

  getOverlayConfig() {
    return new OverlayConfig({
      hasBackdrop: DEFAULT_TOOLTIP_CONFIG.hasBackdrop,
      backdropClass: DEFAULT_TOOLTIP_CONFIG.backdropClass,
      panelClass: DEFAULT_TOOLTIP_CONFIG.panelClass,
      positionStrategy: this.getPositionStrategy(event),
    });
  }

  offset(el: HTMLElement) {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
  }

  @HostListener('click', ['$event'])
  click() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }

  @HostListener('mouseleave', ['$event'])
  mouseLeave() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
