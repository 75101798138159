<div class="container">
  <div *ngIf="isCreating" class="archive-spinner">
    <mat-spinner [diameter]="30"></mat-spinner>
    <span>Архив создается...</span>
  </div>
  <div class="logo">
    <mat-icon id="logo-min" [svgIcon]="'appSvg:logo-min'"></mat-icon>
  </div>
  <span class="title">
    Архив "{{archive?.name}}" создан во вкладке "Архивы"
  </span>
  <div class="close">
    <app-button (click)="close()" backgroundColor="white">Отменить</app-button>
  </div>
  <div class="accept">
    <app-button (click)="download()">Скачать</app-button>
  </div>
</div>
